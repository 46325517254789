import Iconify from 'src/components/Iconify';
import { useDispatch } from 'src/redux/store';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { pinTransaction, selectTransactionOverview, selectTransactionSlice } from 'src/redux/slices/transaction';
import InvitationModal from 'src/pages/transactions/modals/InvitationModal';
import { AskPriceModal } from 'src/pages/transactions/modals/AskPriceModal';
import { selectUserSettings } from 'src/redux/slices/auth';
import { useMemo } from 'react';
import { selectDocumentsField } from 'src/redux/slices/documents';
import { ActionButtonItem, ActionButtons } from '../styles';

const AdminTransactionActionButtons = () => {
	const { id = '' } = useParams();
	const dispatch = useDispatch();
	const settings = useSelector(selectUserSettings);
	const { currentTab } = useSelector(selectTransactionSlice);
	const transaction = useSelector(selectTransactionOverview);
	const { isInitialized: areDocumentsInitialized } = useSelector(selectDocumentsField);

	const isPinned = useMemo(
		() => settings.pinnedTransactions.some((transactionRef) => transactionRef.id === id),
		[settings.pinnedTransactions],
	);

	const isParticipants = currentTab === 'participants';
	const isDocuments = currentTab === 'documents';

	const handleQrCode = () => dispatch(openModal({ name: modals.transactionQrCode }));
	const handleDelist = () => {
		dispatch(openModal({ name: modals.dropProperty, payload: { transactionId: id, delist: true } }));
	};
	const handleDelete = () => {
		dispatch(openModal({ name: modals.dropProperty, payload: { transactionId: id, delete: true } }));
	};
	const handleAddParticipant = () => dispatch(openModal({ name: modals.invitation }));
	const handleAddAskPrice = () => dispatch(openModal({ name: modals.askPrice }));
	const handleEditAskPrice = () =>
		dispatch(openModal({ name: modals.askPrice, payload: { price: transaction.askPrice?.price } }));
	const handlePin = async () => {
		await dispatch(pinTransaction({ id, isPinned }));
	};

	const handleAddFolder = () => dispatch(openModal({ name: modals.addDocumentsFolder }));
	const handleFreeze = () => dispatch(openModal({ name: modals.freezeTransaction }));
	const handleUnfreeze = () => dispatch(openModal({ name: modals.unfreezeTransaction }));
	const handleUnbin = () => dispatch(openModal({ name: modals.unbinTransaction }));
	const handleArchive = () => dispatch(openModal({ name: modals.archiveTransaction }));

	if (transaction.isArchived) return null;

	if (transaction.isDeleted) {
		return (
			<ActionButtons>
				<ActionButtonItem onClick={handleUnbin}>
					<Iconify icon='material-symbols:restore-from-trash-outline' fontSize={20} />
					Unbin
				</ActionButtonItem>
				<ActionButtonItem onClick={handleDelete}>
					<Iconify icon='material-symbols:delete' fontSize={18} />
					Delete permanently
				</ActionButtonItem>
			</ActionButtons>
		);
	}

	if (transaction.isFrozen) {
		return (
			<ActionButtons>
				<ActionButtonItem onClick={handleUnfreeze}>
					<Iconify icon='mingcute:unlock-line' fontSize={20} />
					Unfreeze
				</ActionButtonItem>
				<ActionButtonItem onClick={handleDelist}>
					<Iconify icon='material-symbols:delete' fontSize={18} />
					Bin
				</ActionButtonItem>
			</ActionButtons>
		);
	}

	return (
		<ActionButtons>
			<ActionButtonItem onClick={handlePin}>
				<Iconify icon={isPinned ? 'lucide:pin-off' : 'lucide:pin'} fontSize={25} />
				{isPinned ? 'Unpin' : 'Pin'}
			</ActionButtonItem>
			{isParticipants && (
				<ActionButtonItem onClick={handleAddParticipant}>
					<Iconify icon='zondicons:add-outline' fontSize={20} />
					Add Participant
				</ActionButtonItem>
			)}
			{isParticipants && (
				<ActionButtonItem onClick={handleQrCode}>
					<Iconify icon='bx:qr' fontSize={18} />
					Create QR code
				</ActionButtonItem>
			)}
			{transaction.askPrice ? (
				<ActionButtonItem onClick={handleEditAskPrice}>
					<Iconify icon='mdi:edit' fontSize={20} />
					Edit price
				</ActionButtonItem>
			) : (
				<ActionButtonItem onClick={handleAddAskPrice}>
					<Iconify icon='solar:tag-price-bold' fontSize={20} />
					Set price
				</ActionButtonItem>
			)}
			<ActionButtonItem onClick={handleDelist}>
				<Iconify icon='material-symbols:delete' fontSize={18} />
				Delist
			</ActionButtonItem>
			{isDocuments && areDocumentsInitialized && (
				<ActionButtonItem onClick={handleAddFolder}>
					<Iconify icon='material-symbols:add-circle-outline-rounded' fontSize={18} />
					Add Folder
				</ActionButtonItem>
			)}
			<ActionButtonItem onClick={handleFreeze}>
				<Iconify icon='mingcute:lock-line' fontSize={20} />
				Freeze
			</ActionButtonItem>
			{transaction.trackerCompletionStatus === 'completed' && (
				<ActionButtonItem onClick={handleArchive}>
					<Iconify icon='material-symbols:archive' fontSize={20} />
					Archive
				</ActionButtonItem>
			)}
			<InvitationModal />
			<AskPriceModal />
		</ActionButtons>
	);
};

export default AdminTransactionActionButtons;
