import { TransactionTenure } from 'src/redux/types';
import { IQuestionnaireProperty } from 'src/types';

export const buildingsWork = (
	tenure: TransactionTenure | undefined,
	propertyQuestionnaire: IQuestionnaireProperty | null,
) => {
	const isShareOfFreehold = propertyQuestionnaire?.property_details.dwelling?.share_of_freehold_yn === true;
	const isLeasehold = tenure === 'leasehold';
	const isLeaseholdOrSharedFreehold = isLeasehold || isShareOfFreehold;

	const getBuildingWorkQuestionTitle = (name: string) => {
		if (!isShareOfFreehold) return `Did you get the landlord’s consent for adding the ${name}?`;

		return `Did you get your co-freeholder’s consent for adding the ${name}?`;
	};

	return {
		logoWidth: '100px',
		logoHeight: '100px',
		logoPosition: 'right',
		completedHtml: "<h3>That's it! Thank you for completing the Building Works card! 🎉</h3>",
		completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
		loadingHtml: '<h3>Loading questions...</h3>',
		pages: [
			{
				name: 'alterations_works',
				elements: [
					{
						type: 'panel',
						name: 'alterations_by_owner',
						elements: [
							{
								type: 'checkbox',
								name: 'alterations',
								title: 'Have any of the following been done to the property since it was built? *',
								description:
									"If the changes were made after you purchased the property, we'll ask you to upload some documentation.",
								hideNumber: true,
								isRequired: true,
								choices: [
									{
										value: 'alterations_use',
										text: 'Changed use of part of the property (e.g. from residential to commercial)',
									},
									{
										value: 'alterations_windows',
										text: 'Installed replacement windows, roof windows, roof lights, glazed doors (after 1 April 2002)',
									},
									{
										value: 'alterations_electrical',
										text: 'Rewired the property or carried out any electrical installation work (after 1 January 2005)',
									},
									{
										value: 'alterations_extension',
										text: 'Added an extension',
									},
									{
										value: 'alterations_conservatory',
										text: 'Added a conservatory',
									},
									{
										value: 'alterations_loft',
										text: 'Loft conversion',
									},
									{
										value: 'alterations_garage',
										text: 'Garage conversion',
									},
									{
										value: 'alterations_walls',
										text: 'Removed internal walls',
									},
									{
										value: 'alterations_other',
										text: 'Other building works or changes',
									},
								],
								showNoneItem: true,
							},
							{
								type: 'panel',
								name: 'alterations_use',
								elements: [
									{
										type: 'boolean',
										name: 'alterations_use_owner_yn',
										title: 'Was this change made during your ownership?',
										hideNumber: true,
									},
									{
										type: 'text',
										name: 'alterations_use_desc',
										visibleIf: '{alterations_use_owner_yn} = true',
										title: 'Description of works',
										hideNumber: true,
										requiredIf: "{alterations} anyof ['alterations_use'] and {alterations_use_owner_yn} = true",
									},
									{
										type: 'boolean',
										name: 'alterations_use_completed_yn',
										visibleIf: '{alterations_use_owner_yn} = true',
										title: 'Is this work completed?',
										hideNumber: true,
										requiredIf: "{alterations} anyof ['alterations_use'] and {alterations_use_owner_yn} = true",
									},
									{
										type: 'text',
										name: 'alterations_use_completed_date',
										visibleIf: '{alterations_use_completed_yn} = true and {alterations_use_owner_yn} = true',
										title: 'Completion date',
										titleLocation: 'left',
										hideNumber: true,
										requiredIf: '{alterations_use_completed_yn} = true and {alterations_use_owner_yn} = true',
										inputType: 'month',
									},
									{
										type: 'comment',
										name: 'alterations_use_incomplete_desc',
										visibleIf: '{alterations_use_completed_yn} = false and {alterations_use_owner_yn} = true',
										title: 'Tell us why.',
										hideNumber: true,
										requiredIf: '{alterations_use_completed_yn} = false and {alterations_use_owner_yn} = true',
									},
									{
										type: 'boolean',
										name: 'alterations_use_consent_yn',
										visibleIf: '{alterations_use_owner_yn} = true',
										title: 'Did you have to get planning consent?',
										hideNumber: true,
										requiredIf: "{alterations} anyof ['alterations_use'] and {alterations_use_owner_yn} = true",
									},
									{
										type: 'html',
										name: 'alterations_use_consent_note',
										visibleIf: '{alterations_use_consent_yn} = true and {alterations_use_owner_yn} = true',
										html: "We'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions, Building Regulations approvals, Completion Certificates) in the <b>Documents</b> tab.",
									},
									{
										type: 'comment',
										name: 'alterations_use_consent_desc',
										visibleIf: '{alterations_use_consent_yn} = false and {alterations_use_owner_yn} = true',
										title: 'Tell us why.',
										description:
											'For example, permitted development rights applied, the work was exempt from Building Regulations, etc.',
										hideNumber: true,
										requiredIf: '{alterations_use_consent_yn} = false and {alterations_use_owner_yn} = true',
									},
									...(isLeaseholdOrSharedFreehold
										? [
												{
													type: 'radiogroup',
													name: 'alterations_use_landlords_consent',
													visibleIf: '{alterations_use_owner_yn} = true',
													title: getBuildingWorkQuestionTitle('change in use'),
													hideNumber: true,
													requiredIf: "{alterations} anyof ['alterations_use'] and {alterations_use_owner_yn} = true",
													choices: [
														{
															value: 'yes',
															text: 'Yes',
														},
														{
															value: 'no',
															text: 'No',
														},
														{
															value: 'not_known',
															text: 'Not known',
														},
														{
															value: 'not_required',
															text: 'Not required',
														},
													],
													colCount: 4,
												},
										  ]
										: []),
								],
								visibleIf: "{alterations} anyof ['alterations_use']",
								title: 'Part of the property is not used for residential occupation',
							},
							{
								type: 'panel',
								name: 'alterations_windows',
								elements: [
									{
										type: 'boolean',
										name: 'alterations_windows_owner_yn',
										title: 'Was this change made during your ownership?',
										hideNumber: true,
									},
									{
										type: 'comment',
										name: 'alterations_windows_desc',
										visibleIf: '{alterations_windows_owner_yn} = true',
										title: 'Description of works',
										hideNumber: true,
										requiredIf: "{alterations} anyof ['alterations_windows'] and {alterations_windows_owner_yn} = true",
									},
									{
										type: 'boolean',
										name: 'alterations_windows_completed_yn',
										visibleIf: '{alterations_windows_owner_yn} = true',
										title: 'Is this work completed?',
										hideNumber: true,
										requiredIf: "{alterations} anyof ['alterations_windows'] and {alterations_windows_owner_yn} = true",
									},
									{
										type: 'text',
										name: 'alterations_windows_completed_date',
										visibleIf: '{alterations_windows_completed_yn} = true and {alterations_windows_owner_yn} = true',
										title: 'Completion date',
										titleLocation: 'left',
										hideNumber: true,
										requiredIf: '{alterations_windows_completed_yn} = true and {alterations_windows_owner_yn} = true',
										inputType: 'month',
									},
									{
										type: 'comment',
										name: 'alterations_windows_incomplete_desc',
										visibleIf: '{alterations_windows_completed_yn} = false and {alterations_windows_owner_yn} = true',
										title: 'Tell us why.',
										hideNumber: true,
										requiredIf: '{alterations_windows_completed_yn} = false and {alterations_windows_owner_yn} = true',
									},
									{
										type: 'boolean',
										name: 'alterations_windows_consent_yn',
										visibleIf: '{alterations_windows_owner_yn} = true',
										title: 'Did you have to get planning consent?',
										hideNumber: true,
										requiredIf: "{alterations} anyof ['alterations_windows'] and {alterations_windows_owner_yn} = true",
									},
									{
										type: 'html',
										name: 'alterations_windows_consent_note',
										visibleIf: '{alterations_windows_consent_yn} = true and {alterations_windows_owner_yn} = true',
										html: "We'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions, Building Regulations approvals, Completion Certificates) in the <b>Documents</b> tab.",
									},
									{
										type: 'comment',
										name: 'alterations_windows_consent_desc',
										visibleIf: '{alterations_windows_consent_yn} = false and {alterations_windows_owner_yn} = true',
										title: 'Tell us why.',
										description:
											'For example, permitted development rights applied, the work was exempt from Building Regulations, etc.',
										hideNumber: true,
										requiredIf: '{alterations_windows_consent_yn} = false and {alterations_windows_owner_yn} = true',
									},
									...(isLeaseholdOrSharedFreehold
										? [
												{
													type: 'radiogroup',
													name: 'alterations_windows_landlords_consent',
													visibleIf: '{alterations_windows_owner_yn} = true',
													title: getBuildingWorkQuestionTitle('installation/replacement'),
													hideNumber: true,
													requiredIf:
														"({alterations} anyof ['alterations_windows']) and {alterations_windows_owner_yn} = true",
													choices: [
														{
															value: 'yes',
															text: 'Yes',
														},
														{
															value: 'no',
															text: 'No',
														},
														{
															value: 'not_known',
															text: 'Not known',
														},
														{
															value: 'not_required',
															text: 'Not required',
														},
													],
													colCount: 4,
												},
										  ]
										: []),
								],
								visibleIf: "{alterations} anyof  ['alterations_windows']",
								title: 'Installing replacement windows, roof windows, roof lights, glazed doors since 1 April 2002',
							},
							{
								type: 'panel',
								name: 'alterations_electrical',
								elements: [
									{
										type: 'radiogroup',
										name: 'alterations_electrical_docs',
										title: "You're required to provide one of the following documents. Please select which one.",
										hideNumber: true,
										isRequired: true,
										choices: [
											{
												value: 'electrical_safety_certificate',
												text: 'BS7671 Electrical Safety Certificate',
											},
											{
												value: 'buildings_regulations_compliance_certificate',
												text: 'Building Regulations Compliance Certificate',
											},
											{
												value: 'building_control_completion_certificate',
												text: 'Building Control Completion Certificate',
											},
											{
												value: 'none',
												text: 'None (To be replaced by insurance)',
											},
										],
									},
								],
								visibleIf: "{alterations} anyof  ['alterations_electrical']",
								requiredIf: "{alterations} anyof  ['alterations_electrical']",
								title: 'Rewired the property or carried out any electrical installation work (after 1 January 2005)',
							},
							{
								type: 'html',
								name: 'certificate_yes_note',
								visibleIf:
									"{alterations_electrical_docs} anyof ['electrical_safety_certificate', 'buildings_regulations_compliance_certificate', 'building_control_completion_certificate']",
								html: "We'll ask you to upload a copy of the certificate in the <b>Documents</b> tab.",
							},
							{
								type: 'html',
								name: 'certificate_no_note',
								visibleIf: "{alterations_electrical_docs} = 'none'",
								html: "\nYou're legally required to have insurance to cover the certification gap. The cost typically ranges from £15 to £150. We'll be in touch soon with options for you.",
							},
							{
								type: 'panel',
								name: 'alterations_extension',
								elements: [
									{
										type: 'boolean',
										name: 'alterations_extension_owner_yn',
										title: 'Was this change made during your ownership?',
										hideNumber: true,
									},
									{
										type: 'comment',
										name: 'alterations_extension_desc',
										visibleIf: '{alterations_extension_owner_yn} = true',
										title: 'Description of works',
										hideNumber: true,
										requiredIf:
											"{alterations} anyof  ['alterations_extension'] and {alterations_extension_owner_yn} = true",
									},
									{
										type: 'boolean',
										name: 'alterations_extension_completed_yn',
										visibleIf: '{alterations_extension_owner_yn} = true',
										title: 'Is this work completed?',
										hideNumber: true,
										requiredIf:
											"{alterations} anyof  ['alterations_extension'] and {alterations_extension_owner_yn} = true",
									},
									{
										type: 'text',
										name: 'alterations_extension_completed_date',
										visibleIf:
											'{alterations_extension_completed_yn} = true and {alterations_extension_owner_yn} = true',
										title: 'Completion date',
										titleLocation: 'left',
										hideNumber: true,
										requiredIf:
											'{alterations_extension_completed_yn} = true and {alterations_extension_owner_yn} = true',
										inputType: 'month',
									},
									{
										type: 'comment',
										name: 'alterations_extension_incomplete_desc',
										visibleIf:
											'{alterations_extension_completed_yn} = false and {alterations_extension_owner_yn} = true',
										title: 'Tell us why.',
										hideNumber: true,
										requiredIf:
											'{alterations_extension_completed_yn} = false and {alterations_extension_owner_yn} = true',
									},
									{
										type: 'boolean',
										name: 'alterations_extension_consent_yn',
										visibleIf: '{alterations_extension_owner_yn} = true',
										title: 'Did you have to get planning consent?',
										hideNumber: true,
										requiredIf:
											"{alterations} anyof ['alterations_extension'] \n and {alterations_extension_owner_yn} = true",
									},
									{
										type: 'html',
										name: 'alterations_extension_consent_note',
										visibleIf: '{alterations_extension_consent_yn} = true and {alterations_extension_owner_yn} = true',
										html: "We'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions, Building Regulations approvals, Completion Certificates) in the <b>Documents</b> tab.",
									},
									{
										type: 'comment',
										name: 'alterations_extension_consent_desc',
										visibleIf: '{alterations_extension_consent_yn} = false and {alterations_extension_owner_yn} = true',
										title: 'Tell us why.',
										description:
											'For example, permitted development rights applied, the work was exempt from Building Regulations, etc.',
										hideNumber: true,
										requiredIf: '{alterations_use_consent_yn} = false and {alterations_extension_owner_yn} = true',
									},
									...(isLeaseholdOrSharedFreehold
										? [
												{
													type: 'radiogroup',
													name: 'alterations_extension_landlords_consent',
													visibleIf: '{alterations_extension_owner_yn} = true',
													title: getBuildingWorkQuestionTitle('extension'),
													hideNumber: true,
													requiredIf:
														"({alterations} anyof ['alterations_extension']) and {alterations_extension_owner_yn} = true",
													choices: [
														{
															value: 'yes',
															text: 'Yes',
														},
														{
															value: 'no',
															text: 'No',
														},
														{
															value: 'not_known',
															text: 'Not known',
														},
														{
															value: 'not_required',
															text: 'Not required',
														},
													],
													colCount: 4,
												},
										  ]
										: []),
								],
								visibleIf: "{alterations} anyof  ['alterations_extension']",
								title: 'Adding an extension',
							},
							{
								type: 'panel',
								name: 'alterations_conservatory',
								elements: [
									{
										type: 'boolean',
										name: 'alterations_conservatory_owner_yn',
										title: 'Was this change made during your ownership?',
										hideNumber: true,
									},
									{
										type: 'comment',
										name: 'alterations_conservatory_desc',
										visibleIf: '{alterations_conservatory_owner_yn} = true',
										title: 'Description of works',
										hideNumber: true,
										requiredIf:
											"{alterations} anyof  ['alterations_conservatory'] and {alterations_conservatory_owner_yn} = true",
									},
									{
										type: 'boolean',
										name: 'alterations_conservatory_completed_yn',
										visibleIf: '{alterations_conservatory_owner_yn} = true',
										title: 'Is this work completed?',
										hideNumber: true,
										requiredIf:
											"{alterations} anyof  ['alterations_conservatory'] and {alterations_conservatory_owner_yn} = true",
									},
									{
										type: 'text',
										name: 'alterations_conservatory_completed_date',
										visibleIf:
											'{alterations_conservatory_completed_yn} = true and {alterations_conservatory_owner_yn} = true',
										title: 'Completion date',
										titleLocation: 'left',
										hideNumber: true,
										requiredIf:
											'{alterations_conservatory_completed_yn} = true and {alterations_conservatory_owner_yn} = true',
										inputType: 'month',
									},
									{
										type: 'comment',
										name: 'alterations_conservatory_incomplete_desc',
										visibleIf:
											'{alterations_conservatory_completed_yn} = false and {alterations_conservatory_owner_yn} = true',
										title: 'Tell us why.',
										hideNumber: true,
										requiredIf:
											'{alterations_conservatory_completed_yn} = false and {alterations_conservatory_owner_yn} = true',
									},
									{
										type: 'boolean',
										name: 'alterations_conservatory_consent_yn',
										visibleIf: '{alterations_conservatory_owner_yn} = true',
										title: 'Did you have to get planning consent?',
										hideNumber: true,
										requiredIf:
											"{alterations} anyof  ['alterations_conservatory'] and {alterations_conservatory_owner_yn} = true",
									},
									{
										type: 'html',
										name: 'alterations_conservatory_consent_note',
										visibleIf:
											'{alterations_conservatory_consent_yn} = true and {alterations_conservatory_owner_yn} = true',
										html: "We'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions, Building Regulations approvals, Completion Certificates) in the <b>Documents</b> tab.",
									},
									{
										type: 'comment',
										name: 'alterations_conservatory_consent_desc',
										visibleIf:
											'{alterations_conservatory_consent_yn} = false and {alterations_conservatory_owner_yn} = true',
										title: 'Tell us why.',
										description:
											'For example, permitted development rights applied, the work was exempt from Building Regulations, etc.',
										hideNumber: true,
										requiredIf:
											'{alterations_conservatory_consent_yn} = false and {alterations_conservatory_owner_yn} = true',
									},
									...(isLeaseholdOrSharedFreehold
										? [
												{
													type: 'radiogroup',
													name: 'alterations_conservatory_landlords_consent',
													visibleIf: '{alterations_conservatory_owner_yn} = true',
													title: "Did you get the landlord's consent for adding the conservatory?",
													hideNumber: true,
													requiredIf:
														"({alterations} anyof ['alterations_conservatory']) and {alterations_conservatory_owner_yn} = true",
													choices: [
														{
															value: 'yes',
															text: 'Yes',
														},
														{
															value: 'no',
															text: 'No',
														},
														{
															value: 'not_known',
															text: 'Not known',
														},
														{
															value: 'not_required',
															text: 'Not required',
														},
													],
													colCount: 4,
												},
										  ]
										: []),
								],
								visibleIf: "{alterations} anyof  ['alterations_conservatory']",
								title: 'Adding a conservatory',
							},
							{
								type: 'panel',
								name: 'alterations_loft',
								elements: [
									{
										type: 'boolean',
										name: 'alterations_loft_owner_yn',
										title: 'Was this change made during your ownership?',
										hideNumber: true,
									},
									{
										type: 'comment',
										name: 'alterations_loft_desc',
										visibleIf: '{alterations_loft_owner_yn} = true',
										title: 'Description of works',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_loft'] and {alterations_loft_owner_yn} = true",
									},
									{
										type: 'boolean',
										name: 'alterations_loft_completed_yn',
										visibleIf: '{alterations_loft_owner_yn} = true',
										title: 'Is this work completed?',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_loft'] and {alterations_loft_owner_yn} = true",
									},
									{
										type: 'text',
										name: 'alterations_loft_completed_date',
										visibleIf: '{alterations_loft_completed_yn} = true and {alterations_loft_owner_yn} = true',
										title: 'Completion date',
										titleLocation: 'left',
										hideNumber: true,
										requiredIf: '{alterations_loft_completed_yn} = true and {alterations_loft_owner_yn} = true',
										inputType: 'month',
									},
									{
										type: 'comment',
										name: 'alterations_loft_incomplete_desc',
										visibleIf: '{alterations_loft_completed_yn} = false and {alterations_loft_owner_yn} = true',
										title: 'Tell us why.',
										hideNumber: true,
										requiredIf: '{alterations_loft_completed_yn} = false and {alterations_loft_owner_yn} = true',
									},
									{
										type: 'boolean',
										name: 'alterations_loft_consent_yn',
										visibleIf: '{alterations_loft_owner_yn} = true',
										title: 'Did you have to get planning consent?',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_loft'] and {alterations_loft_owner_yn} = true",
									},
									{
										type: 'html',
										name: 'alterations_loft_consent_note',
										visibleIf: '{alterations_loft_consent_yn} = true and {alterations_loft_owner_yn} = true',
										html: "We'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions, Building Regulations approvals, Completion Certificates) in the <b>Documents</b> tab.",
									},
									{
										type: 'comment',
										name: 'alterations_loft_consent_desc',
										visibleIf: '{alterations_loft_consent_yn} = false and {alterations_loft_owner_yn} = true',
										title: 'Tell us why.',
										description:
											'For example, permitted development rights applied, the work was exempt from Building Regulations, etc.',
										hideNumber: true,
										requiredIf: '{alterations_loft_consent_yn} = false and {alterations_loft_owner_yn} = true',
									},
									...(isLeaseholdOrSharedFreehold
										? [
												{
													type: 'radiogroup',
													name: 'alterations_loft_landlords_consent',
													visibleIf: '{alterations_loft_owner_yn} = true',
													title: getBuildingWorkQuestionTitle('loft conversion'),
													hideNumber: true,
													requiredIf:
														"({alterations} anyof ['alterations_loft']) and {alterations_loft_owner_yn} = true",
													choices: [
														{
															value: 'yes',
															text: 'Yes',
														},
														{
															value: 'no',
															text: 'No',
														},
														{
															value: 'not_known',
															text: 'Not known',
														},
														{
															value: 'not_required',
															text: 'Not required',
														},
													],
													colCount: 4,
												},
										  ]
										: []),
								],
								visibleIf: "{alterations} anyof  ['alterations_loft']",
								title: 'Loft conversion *',
								isRequired: true,
							},
							{
								type: 'panel',
								name: 'alterations_garage',
								elements: [
									{
										type: 'boolean',
										name: 'alterations_garage_owner_yn',
										title: 'Was this change made during your ownership?',
										hideNumber: true,
									},
									{
										type: 'comment',
										name: 'alterations_garage_desc',
										visibleIf: '{alterations_garage_owner_yn} = true',
										title: 'Description of works',
										hideNumber: true,
										requiredIf: "{alterations} anyof ['alterations_garage'] and {alterations_garage_owner_yn} = true",
									},
									{
										type: 'boolean',
										name: 'alterations_garage_completed_yn',
										visibleIf: '{alterations_garage_owner_yn} = true',
										title: 'Is this work completed?',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_garage'] and {alterations_garage_owner_yn} = true",
									},
									{
										type: 'text',
										name: 'alterations_garage_completed_date',
										visibleIf: '{alterations_garage_completed_yn} = true and {alterations_garage_owner_yn} = true',
										title: 'Completion date',
										titleLocation: 'left',
										hideNumber: true,
										requiredIf: '{alterations_garage_completed_yn} = true and {alterations_garage_owner_yn} = true',
										inputType: 'month',
									},
									{
										type: 'comment',
										name: 'alterations_garage_incomplete_desc',
										visibleIf: '{alterations_garage_completed_yn} = false and {alterations_garage_owner_yn} = true',
										title: 'Tell us why.',
										hideNumber: true,
										requiredIf: '{alterations_garage_completed_yn} = false and {alterations_garage_owner_yn} = true',
									},
									{
										type: 'boolean',
										name: 'alterations_garage_consent_yn',
										visibleIf: '{alterations_garage_owner_yn} = true',
										title: 'Did you have to get planning consent?',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_garage'] and {alterations_garage_owner_yn} = true",
									},
									{
										type: 'html',
										name: 'alterations_garage_consent_note',
										visibleIf: '{alterations_garage_consent_yn} = true and {alterations_garage_owner_yn} = true',
										html: "We'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions, Building Regulations approvals, Completion Certificates) in the <b>Documents</b> tab.",
									},
									{
										type: 'comment',
										name: 'alterations_garage_consent_desc',
										visibleIf: '{alterations_garage_consent_yn} = false and {alterations_garage_owner_yn} = true',
										title: 'Tell us why.',
										description:
											'For example, permitted development rights applied, the work was exempt from Building Regulations, etc.',
										hideNumber: true,
										requiredIf: '{alterations_garage_consent_yn} = false and {alterations_garage_owner_yn} = true',
									},
									...(isLeaseholdOrSharedFreehold
										? [
												{
													type: 'radiogroup',
													name: 'alterations_garage_landlords_consent',
													visibleIf: '{alterations_garage_owner_yn} = true',
													title: getBuildingWorkQuestionTitle('garage conversion'),
													hideNumber: true,
													requiredIf:
														"({alterations} anyof ['alterations_garage']) and {alterations_garage_owner_yn} = true",
													choices: [
														{
															value: 'yes',
															text: 'Yes',
														},
														{
															value: 'no',
															text: 'No',
														},
														{
															value: 'not_known',
															text: 'Not known',
														},
														{
															value: 'not_required',
															text: 'Not required',
														},
													],
													colCount: 4,
												},
										  ]
										: []),
								],
								visibleIf: "{alterations} anyof ['alterations_garage']",
								title: 'Garage conversion',
							},
							{
								type: 'panel',
								name: 'alterations_walls',
								elements: [
									{
										type: 'boolean',
										name: 'alterations_walls_owner_yn',
										title: 'Was this change made during your ownership?',
										hideNumber: true,
									},
									{
										type: 'comment',
										name: 'alterations_walls_desc',
										visibleIf: '{alterations_walls_owner_yn} = true',
										title: 'Description of works',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_walls'] and {alterations_walls_owner_yn} = true",
									},
									{
										type: 'boolean',
										name: 'alterations_walls_completed_yn',
										visibleIf: '{alterations_walls_owner_yn} = true',
										title: 'Is this work completed?',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_walls'] and {alterations_walls_owner_yn} = true",
									},
									{
										type: 'text',
										name: 'alterations_walls_date',
										visibleIf: '{alterations_walls_completed_yn} = true and {alterations_walls_owner_yn} = true',
										title: 'Completion date',
										titleLocation: 'left',
										hideNumber: true,
										requiredIf: '{alterations_walls_completed_yn} = true and {alterations_walls_owner_yn} = true',
										inputType: 'month',
									},
									{
										type: 'comment',
										name: 'alterations_walls_incomplete_desc',
										visibleIf: '{alterations_walls_completed_yn} = false and {alterations_walls_owner_yn} = true',
										title: 'Tell us why.',
										hideNumber: true,
										requiredIf: '{alterations_walls_completed_yn} = false and {alterations_walls_owner_yn} = true',
									},
									{
										type: 'boolean',
										name: 'alterations_walls_consent_yn',
										visibleIf: '{alterations_walls_owner_yn} = true',
										title: 'Did you have to get planning consent?',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_walls'] and {alterations_walls_owner_yn} = true",
									},
									{
										type: 'html',
										name: 'alterations_walls_consent_note',
										visibleIf: '{alterations_walls_consent_yn} = true and {alterations_walls_owner_yn} = true',
										html: "We'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions, Building Regulations approvals, Completion Certificates) in the <b>Documents</b> tab.",
									},
									{
										type: 'comment',
										name: 'alterations_other_walls_desc',
										visibleIf: '{alterations_walls_consent_yn} = false and {alterations_walls_owner_yn} = true',
										title: 'Tell us why.',
										description:
											'For example, permitted development rights applied, the work was exempt from Building Regulations, etc.',
										hideNumber: true,
										requiredIf: '{alterations_walls_consent_yn} = false and {alterations_walls_owner_yn} = true',
									},
									...(isLeaseholdOrSharedFreehold
										? [
												{
													type: 'radiogroup',
													name: 'alterations_walls_landlords_consent',
													visibleIf: '{alterations_walls_owner_yn} = true',
													title: getBuildingWorkQuestionTitle('removal of internal walls'),
													hideNumber: true,
													requiredIf:
														"({alterations} anyof ['alterations_walls']) and {alterations_walls_owner_yn} = true",
													choices: [
														{
															value: 'yes',
															text: 'Yes',
														},
														{
															value: 'no',
															text: 'No',
														},
														{
															value: 'not_known',
															text: 'Not known',
														},
														{
															value: 'not_required',
															text: 'Not required',
														},
													],
													colCount: 4,
												},
										  ]
										: []),
								],
								visibleIf: "{alterations} anyof ['alterations_walls']",
								requiredIf: "{alterations} anyof  ['alterations_walls']",
								title: 'Removal of internal walls',
							},
							{
								type: 'panel',
								name: 'alterations_other',
								elements: [
									{
										type: 'boolean',
										name: 'alterations_other_owner_yn',
										title: 'Was this change made during your ownership?',
										hideNumber: true,
									},
									{
										type: 'comment',
										name: 'alterations_other_desc',
										visibleIf: '{alterations_other_owner_yn} = true',
										title: 'Description of works',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_other'] and {alterations_other_owner_yn} = true",
									},
									{
										type: 'boolean',
										name: 'alterations_other_completed_yn',
										visibleIf: '{alterations_other_owner_yn} = true',
										title: 'Is this work completed?',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_other'] and {alterations_other_owner_yn} = true",
									},
									{
										type: 'text',
										name: 'alterations_other_completed_date',
										visibleIf: '{alterations_other_completed_yn} = true and {alterations_other_owner_yn} = true',
										title: 'Completion date',
										titleLocation: 'left',
										hideNumber: true,
										requiredIf: '{alterations_other_completed_yn} = true and {alterations_other_owner_yn} = true',
										inputType: 'month',
									},
									{
										type: 'comment',
										name: 'alterations_other_incomplete_desc',
										visibleIf: '{alterations_other_completed_yn} = false and {alterations_other_owner_yn} = true',
										title: 'Tell us why.',
										hideNumber: true,
										requiredIf: '{alterations_other_completed_yn} = false and {alterations_other_owner_yn} = true',
									},
									{
										type: 'boolean',
										name: 'alterations_other_consent_yn',
										visibleIf: '{alterations_other_owner_yn} = true',
										title: 'Did you have to get planning consent?',
										hideNumber: true,
										requiredIf: "{alterations} anyof  ['alterations_other'] and {alterations_other_owner_yn} = true",
									},
									{
										type: 'html',
										name: 'alterations_other_consent_note',
										visibleIf: '{alterations_other_consent_yn} = true and {alterations_other_owner_yn} = true',
										html: "We'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions, Building Regulations approvals, Completion Certificates) in the <b>Documents</b> tab.",
									},
									{
										type: 'comment',
										name: 'alterations_other_consent_desc',
										visibleIf: '{alterations_other_consent_yn} = false and {alterations_other_owner_yn} = true',
										title: 'Tell us why.',
										description:
											'For example, permitted development rights applied, the work was exempt from Building Regulations, etc.',
										hideNumber: true,
										requiredIf: '{alterations_other_consent_yn} = false and {alterations_other_owner_yn} = true',
									},
									...(isLeaseholdOrSharedFreehold
										? [
												{
													type: 'radiogroup',
													name: 'alterations_other_landlords_consent',
													visibleIf: '{alterations_other_owner_yn} = true',
													title: getBuildingWorkQuestionTitle('building works'),
													hideNumber: true,
													requiredIf:
														"({alterations} anyof ['alterations_other']) and {alterations_other_owner_yn} = true",
													choices: [
														{
															value: 'yes',
															text: 'Yes',
														},
														{
															value: 'no',
															text: 'No',
														},
														{
															value: 'not_known',
															text: 'Not known',
														},
														{
															value: 'not_required',
															text: 'Not required',
														},
													],
													colCount: 4,
												},
										  ]
										: []),
								],
								visibleIf: "{alterations} anyof  ['alterations_other']",
								title: 'Other building works or changes to the property',
							},
						],
					},
				],
				title: 'Property alterations',
			},
			{
				name: 'green_deal_page',
				elements: [
					{
						type: 'boolean',
						name: 'green_deal_yn',
						title: 'Have any installations in the property been financed under the Green Deal scheme?',
						hideNumber: true,
					},
					{
						type: 'tagbox',
						name: 'green_deal_desc',
						visibleIf: '{green_deal_yn} = true',
						title: 'Which installations?',
						description: 'Select all that apply.',
						hideNumber: true,
						requiredIf: '{green_deal_yn} = true',
						choices: [
							'New or replacement boilers (condensing and biomass)',
							'Insulation (solid wall, cavity wall, loft and floor)',
							'Heating, hot water and lighting controls',
							'Heating system insulation',
							'Ground and air-source heat pumps',
							'Fan-assisted storage heaters',
							'Flue gas recovery',
							'Draught proofing',
							'Innovative hot water systems',
						],
						showOtherItem: true,
					},
					{
						type: 'html',
						name: 'green_deal_note',
						visibleIf: '{green_deal_yn} = true',
						html: "We'll ask you to upload a copy of your <b>last electricity bill</b> in the <b>Documents</b> tab.",
					},
				],
				title: 'Green Deal',
				description:
					'Green Deal loans are a way for homeowners to fund energy efficiency measures in their home and pay them back through their energy bills.',
			},
		],
		showCompletedPage: false,
		showProgressBar: 'top',
		completeText: 'Submit',
		widthMode: 'static',
	};
};
