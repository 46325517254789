import { handleCloseModal } from 'src/utils/propertyHelpers';
import { dispatch, useSelector } from 'src/redux/store';
import { confirmAcceptedOffer, selectTransactionOverview } from 'src/redux/slices/transaction';
import { selectModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { ConfirmationModal } from 'src/components/modals';
import { IStateTransactionOffer } from 'src/redux/types';
import { noop } from 'lodash';
import useLoading from 'src/hooks/useLoading';

export const OfferAcceptedModal = () => {
	const { payload } = useSelector(selectModal<{ offer?: IStateTransactionOffer }>);
	const transaction = useSelector(selectTransactionOverview);
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		if (payload.offer) await dispatch(confirmAcceptedOffer({ offerId: payload.offer.id }));

		setIsLoading(false);
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			handleClose={noop}
			sx={{ minWidth: '600px' }}
			modalName={modals.offerAccepted}
			title='OFFER ACCEPTED'
			description={
				<>
					Congratulations! You are now the confirmed buyer of <b>{transaction.name}</b>. You're one step closer to
					owning this property. Head over to the <b>Participants tab</b> to assemble your team. Invite other buyers,
					giftors, mortgage brokers, and anyone else you need on your side. A solicitor will be assigned to you shortly.
				</>
			}
			withCancelButton={false}
			confirmText='Continue'
			handleConfirm={handleConfirm}
			isLoading={isLoading}
		/>
	);
};
