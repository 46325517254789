import { TransactionTenure } from 'src/redux/types';
import { IQuestionnaireProperty } from 'src/types';

export const liabilities = (
	tenure: TransactionTenure | undefined,
	propertyQuestionnaire: IQuestionnaireProperty | null,
	titlePlanImageUrl?: string,
) => {
	const isLeasehold = tenure === 'leasehold';
	const isShareOfFreehold = propertyQuestionnaire?.property_details.dwelling?.share_of_freehold_yn === true;
	const isNotShareOfFreehold = propertyQuestionnaire?.property_details.dwelling?.share_of_freehold_yn === false;
	const isLeaseholdOrSharedFreehold = isLeasehold || isShareOfFreehold;
	const isLeaseholdAndNotSharedFreehold = isLeasehold && isNotShareOfFreehold;
	const hasMaintenanceRegularCharge =
		propertyQuestionnaire?.ownership?.charge?.building_maintenance_arrangements === 'regular_charge';

	return {
		logoWidth: '100px',
		logoHeight: '100px',
		logoPosition: 'right',
		completedHtml: "<h3>That's it! Thank you for completing the Liabilites card! 🎉</h3>",
		completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
		loadingHtml: '<h3>Loading questions...</h3>',
		pages: [
			{
				name: 'potential_liabilities',
				elements: [
					{
						type: 'matrix',
						name: 'liabilities',
						title: 'Are you aware of any of the following? *',
						hideNumber: true,
						isRequired: true,
						columns: [
							{
								value: 'yes',
								text: 'Yes',
							},
							{
								value: 'no',
								text: 'No',
							},
						],
						rows: [
							{
								value: 'planning_breaches',
								text: 'Breaches of planning permission conditions',
							},
							{
								value: 'consent_breaches',
								text: 'Breaches of building regulations consent conditions',
							},
							{
								value: 'unfinished_work',
								text: 'Unfinished work',
							},
							{
								value: 'no_consent_work',
								text: 'Work that does not have all necessary consents',
							},
							{
								value: 'planning_issues',
								text: 'Planning or building control issues to resolve',
							},
						],
					},
					{
						type: 'panel',
						name: 'planning_breaches',
						elements: [
							{
								type: 'comment',
								name: 'planning_breaches_desc',
								title: 'Brief description',
								hideNumber: true,
								requiredIf: "{liabilities.planning_breaches} = 'yes'",
							},
							{
								type: 'text',
								name: 'planning_breaches_date',
								title: 'Date',
								titleLocation: 'left',
								hideNumber: true,
								requiredIf: "{liabilities.planning_breaches} = 'yes'",
								inputType: 'month',
							},
						],
						visibleIf: "{liabilities.planning_breaches} = 'yes'",
						requiredIf: "{liabilities.planning_breaches} = 'Yes'",
						title: 'Breaches of planning permission conditions',
					},
					{
						type: 'panel',
						name: 'consent_breaches',
						elements: [
							{
								type: 'comment',
								name: 'consent_breaches_desc',
								title: 'Brief description',
								hideNumber: true,
								requiredIf: "{liabilities.consent_breaches} = 'Yes'",
							},
							{
								type: 'text',
								name: 'consent_breaches_date',
								title: 'Date',
								titleLocation: 'left',
								hideNumber: true,
								requiredIf: "{liabilities.consent_breaches} = 'Yes'",
								inputType: 'month',
							},
						],
						visibleIf: "{liabilities.consent_breaches} = 'yes'",
						requiredIf: "{liabilities.consent_breaches} = 'Yes'",
						title: 'Breaches of building regulations consent conditions',
					},
					{
						type: 'panel',
						name: 'unfinished_work',
						elements: [
							{
								type: 'comment',
								name: 'unfinished_work_desc',
								title: 'Brief description',
								hideNumber: true,
								requiredIf: "{liabilities.unfinished_work} = 'Yes'",
							},
							{
								type: 'text',
								name: 'unfinished_work_date',
								title: 'Date',
								titleLocation: 'left',
								hideNumber: true,
								requiredIf: "{liabilities.unfinished_work} = 'Yes'",
								inputType: 'month',
							},
						],
						visibleIf: "{liabilities.unfinished_work} = 'yes'",
						requiredIf: "{liabilities.unfinished_work} = 'Yes'",
						title: 'Unfinished work',
					},
					{
						type: 'panel',
						name: 'no_consent_work',
						elements: [
							{
								type: 'comment',
								name: 'no_consent_work_desc',
								title: 'Brief description',
								hideNumber: true,
								requiredIf: "{liabilities.no_consent_work} = 'yes'",
							},
							{
								type: 'text',
								name: 'no_consent_work_date',
								title: 'Date',
								titleLocation: 'left',
								hideNumber: true,
								requiredIf: "{liabilities.no_consent_work} = 'yes'",
								inputType: 'month',
							},
						],
						visibleIf: "{liabilities.no_consent_work} = 'yes'",
						requiredIf: "{liabilities.no_consent_work} = 'Yes'",
						title: 'Work that does not have all necessary consents',
					},
					{
						type: 'panel',
						name: 'planning_issues',
						elements: [
							{
								type: 'comment',
								name: 'planning_issues_desc',
								title: 'Brief description',
								hideNumber: true,
								requiredIf: "{liabilities.planning_issues} = 'Yes'",
							},
							{
								type: 'text',
								name: 'planning_issues_date',
								title: 'Date',
								titleLocation: 'left',
								hideNumber: true,
								requiredIf: "{liabilities.planning_issues} = 'Yes'",
								inputType: 'month',
							},
						],
						visibleIf: "{liabilities.planning_issues} = 'yes'",
						requiredIf: "{liabilities.planning_issues} = 'Yes'",
						title: 'Planning or building control issues to resolve',
					},
				],
				title: 'Potential liabilities',
			},
			{
				name: 'disputes_complaints',
				elements: [
					{
						type: 'panel',
						name: 'disputes_complaints_current',
						elements: [
							{
								type: 'boolean',
								name: 'disputes_complaints_yn',
								title: 'Have there been any disputes or complaints about your property or a property nearby? *',
								hideNumber: true,
								isRequired: true,
							},
							{
								type: 'comment',
								name: 'disputes_complaints_desc',
								visibleIf: '{disputes_complaints_yn} = true',
								title: 'Tell us more.',
								hideNumber: true,
								requiredIf: '{disputes_complaints_yn} = true',
							},
						],
					},
					{
						type: 'panel',
						name: 'disputes_complaints_future',
						elements: [
							{
								type: 'boolean',
								name: 'disputes_complaints_future_yn',
								title:
									'Are you aware of anything that might lead to a dispute about your property or a property nearby? *',
								hideNumber: true,
								isRequired: true,
							},
							{
								type: 'comment',
								name: 'disputes_complaints_future_desc',
								visibleIf: '{disputes_complaints_future_yn} = true',
								title: 'Tell us more.',
								hideNumber: true,
								requiredIf: '{disputes_complaints_future_yn} = true',
							},
						],
					},
				],
				title: 'Disputes and complaints',
			},
			{
				name: 'notices_proposals',
				elements: [
					{
						type: 'panel',
						name: 'notices',
						elements: [
							{
								type: 'boolean',
								name: 'notices_proposals_yn',
								title:
									'Are you aware of any correspondence, negotiations or discussions which affect your property or a property nearby? *',
								hideNumber: true,
								isRequired: true,
							},
							{
								type: 'comment',
								name: 'notices_proposals_desc',
								visibleIf: '{notices_proposals_yn} = true',
								title: 'Tell us more.',
								hideNumber: true,
								requiredIf: '{notices_proposals_yn} = true',
							},
						],
					},
					{
						type: 'panel',
						name: 'proposals',
						elements: [
							{
								type: 'boolean',
								name: 'planned_development_yn',
								title:
									'Are you aware of any proposals to develop property or land nearby, or of any proposals to make alterations to buildings nearby? *',
								hideNumber: true,
								isRequired: true,
							},
							{
								type: 'comment',
								name: 'planned_development_desc',
								visibleIf: '{planned_development_yn} = true',
								title: 'Tell us more.',
								hideNumber: true,
								requiredIf: '{planned_development_yn} = true',
							},
						],
					},
					{
						type: 'panel',
						name: 'party_wall_notices',
						elements: [
							{
								type: 'boolean',
								name: 'party_wall_notice_yn',
								title: 'Have you received any Party Wall Notice? *',
								hideNumber: true,
								isRequired: true,
							},
							{
								type: 'comment',
								name: 'party_wall_notice_desc',
								visibleIf: '{party_wall_notice_yn} = true',
								title: 'What works were carried out or agreed? Tell us more.',
								hideNumber: true,
								requiredIf: '{party_wall_notice_yn} = true',
							},
							{
								type: 'html',
								name: 'alterations_use_consent_note',
								visibleIf: '{party_wall_notice_yn} = true',
								html: "We'll ask you to upload copies of any <b>Party Wall Notices</b> in the <b>Documents</b> tab.",
							},
						],
					},
				],
				title: 'Notices and proposals',
			},
			...(hasMaintenanceRegularCharge
				? [
						{
							name: 'service_charge',
							elements: [
								{
									type: 'boolean',
									name: 'service_charge_extra',
									title:
										'Do you know of any extraordinary expense likely to appear in the service charges account in the next 3 years?',
									description:
										'e.g. the cost of redecorating of the outside or communal areas not usually incurred annually',
									hideNumber: true,
								},
								{
									type: 'comment',
									name: 'service_charge_extra_desc',
									visibleIf: '{service_charge_extra} = true',
									title: 'Please give details',
									hideNumber: true,
									requiredIf: '{service_charge_extra} = true',
								},
								{
									type: 'boolean',
									name: 'service_charge_issues',
									title:
										'Do you know of any problems in the last 3 years about the level of service charges or with the management?',
									hideNumber: true,
									requiredIf: '{building_maintenance_seller} = true',
								},
								{
									type: 'comment',
									name: 'service_charge_issues_desc',
									visibleIf: '{service_charge_issues} = true',
									title: 'Tell us more',
									hideNumber: true,
									requiredIf: '{service_charge_issues} = true',
								},
								{
									type: 'boolean',
									name: 'service_charge_challenge',
									title: 'Have you challenged the service charge or any expense in the last 3 years?',
									hideNumber: true,
									requiredIf: '{building_maintenance_seller} = true',
								},
								{
									type: 'comment',
									name: 'service_charge_challenge_desc',
									visibleIf: '{service_charge_challenge} = true',
									title: 'Tell us more',
									hideNumber: true,
									requiredIf: '{service_charge_challenge} = true',
								},
								{
									type: 'boolean',
									name: 'service_charge_collectionissues',
									title:
										'Are you aware of any difficulties encountered in collecting the service charges from other owners?',
									hideNumber: true,
									requiredIf: '{building_maintenance_seller} = true',
								},
								{
									type: 'comment',
									name: 'service_charge_collectionissues_desc',
									visibleIf: '{service_charge_collectionissues} = true',
									title: 'Tell us more',
									hideNumber: true,
									requiredIf: '{service_charge_collectionissues} = true',
								},
								{
									type: 'boolean',
									name: 'service_charge_owed',
									title:
										'Do you owe any outstanding service charges, rent, insurance premium or other financial contribution?',
									hideNumber: true,
									requiredIf: '{building_maintenance_seller} = true',
								},
								{
									type: 'comment',
									name: 'service_charge_owed_desc',
									visibleIf: '{service_charge_owed} = true',
									title: 'Please give details',
									hideNumber: true,
									requiredIf: '{service_charge_owed} = true',
								},
							],
							title: 'Service charge',
						},
				  ]
				: []),
			...(isLeaseholdOrSharedFreehold
				? [
						{
							name: 'management',
							elements: [
								...(isLeaseholdAndNotSharedFreehold
									? [
											{
												type: 'boolean',
												name: 'notice_sale_yn',
												title: 'Have you received a notice that the landlord wants to sell the building?',
												hideNumber: true,
												requiredIf: '{building_maintenance_seller} = true',
											},
									  ]
									: []),
								{
									type: 'html',
									name: 'notice_sale_note',
									visibleIf: '{notice_sale_yn} = true',
									html: "We'll ask you to upload a copy of the <b>Notice of Sale</b> in the <b>Documents</b> tab.",
								},
								...(isLeaseholdOrSharedFreehold
									? [
											{
												type: 'boolean',
												name: 'notice_building_yn',
												title:
													'Have you received any notice about the building, its use, its condition or its repair and maintenance?',
												hideNumber: true,
												requiredIf: '{building_maintenance_seller} = true',
											},
									  ]
									: []),
								{
									type: 'html',
									name: 'notice_building_note',
									visibleIf: '{notice_building_yn} = true',
									html: "We'll ask you to upload a copy of the <b>Notice about the Building</b> in the <b>Documents</b> tab.",
								},
								{
									type: 'boolean',
									name: 'consent_lease_yn',
									title: `Are you aware of any changes in the terms of the lease or of ${
										isShareOfFreehold ? 'your co-freeholders' : 'the landlord'
									} giving any consents under the lease?`,
									hideNumber: true,
									requiredIf: '{building_maintenance_seller} = true',
								},
								{
									type: 'boolean',
									name: 'consent_written_yn',
									visibleIf: '{consent_lease_yn} = true',
									title: 'Were the terms changes or the consents given in writing?',
									description: 'A consent may be written or oral.',
									hideNumber: true,
									requiredIf: '{consent_lease_yn} = true',
								},
								{
									type: 'comment',
									name: 'consent_lease_desc',
									visibleIf: '{consent_written_yn} = false',
									title: 'Please give details',
									hideNumber: true,
									requiredIf: '{consent_written_yn} = false',
								},
								{
									type: 'html',
									name: 'consent_lease_note',
									visibleIf: '{consent_written_yn} = true',
									html: "We'll ask you to upload a copy of the <b>Lease Term Changes</b> or of the <b>Landlord Consents</b> in the <b>Documents</b> tab.",
								},
								...(isLeaseholdOrSharedFreehold
									? [
											{
												type: 'boolean',
												name: 'complaint_received_yn',
												title: `Have you received any complaint from ${
													isShareOfFreehold ? 'your co-freeholders' : 'the landlord'
												}, the management company or any neighbour about anything you have done or not done?`,
												hideNumber: true,
												requiredIf: '{building_maintenance_seller} = true',
											},
									  ]
									: []),
								{
									type: 'comment',
									name: 'complaint_received_desc',
									visibleIf: '{complaint_received_yn} = true',
									title: 'Please give details',
									hideNumber: true,
									requiredIf: '{complaint_received_yn} = true',
								},
								...(isLeaseholdOrSharedFreehold
									? [
											{
												type: 'boolean',
												name: 'complaint_made_yn',
												title: `Have you complained or had any cause to complain to or about ${
													isShareOfFreehold ? 'your co-freeholders' : 'the landlord'
												}, the management company, or any neighbour?`,
												hideNumber: true,
												requiredIf: '{building_maintenance_seller} = true',
											},
									  ]
									: []),
								{
									type: 'comment',
									name: 'complaint_made_desc',
									visibleIf: '{complaint_made_yn} = true',
									title: 'Please give details',
									hideNumber: true,
									requiredIf: '{complaint_made_yn} = true',
								},
							],
							title: 'Management',
						},
				  ]
				: []),
			...(isLeasehold
				? [
						{
							name: 'enfranchisement',
							elements: [
								{
									type: 'boolean',
									name: 'enfranchisement_two_years_yn',
									title: 'Have you owned the property for at least 2 years?',
									hideNumber: true,
									labelTrue: 'Yes',
									labelFalse: 'No',
								},
								{
									type: 'boolean',
									name: 'enfranchisement_landlord_notice_yn',
									title:
										'Have you served on the landlord a formal notice stating your wish to buy the freehold or be granted an extended lease?',
									hideNumber: true,
									requiredIf: '{building_maintenance_seller} = true',
								},
								{
									type: 'html',
									name: 'enfranchisement_landlord_notice_note',
									visibleIf: '{enfranchisement_landlord_notice_yn} = true',
									html: "We'll ask you to upload a copy of your <b>Notice to the Landlord</b> in the <b>Documents</b> tab.",
								},
								{
									type: 'boolean',
									name: 'enfranchisement_tenants_notice_yn',
									title:
										'Are you aware of the service of any notice relating to the possible collective purchase of the freehold of the building (or part of it) by a group of tenants?',
									hideNumber: true,
									requiredIf: '{building_maintenance_seller} = true',
								},
								{
									type: 'html',
									name: 'enfranchisement_tenants_notice_note',
									visibleIf: '{enfranchisement_tenants_notice_yn} = true',
									html: "We'll ask you to upload a copy of the <b>Collective Purchase Notice</b> in the <b>Documents</b> tab.",
								},
								{
									type: 'boolean',
									name: 'enfranchisement_response_yn',
									visible: false,
									visibleIf:
										'{enfranchisement_tenants_notice_yn} = true or {enfranchisement_landlord_notice_yn} = true',
									title: 'Are you aware of any response to a notice disclosed above?',
									hideNumber: true,
								},
								{
									type: 'html',
									name: 'enfranchisement_response_note',
									visibleIf: '{enfranchisement_response_yn} = true',
									html: "We'll ask you to upload a copy of the response in the <b>Documents</b> tab.",
								},
							],
							title: 'Enfranchisement',
							description:
								"'Enfranchisement' is the right of a tenant to purchase the freehold from their landlord and the right of the tenant to extend the term of the lease.",
						},
				  ]
				: []),
			{
				name: 'title',
				elements: [
					{
						type: 'boolean',
						name: 'no_covenants_breach_yn',
						title:
							'Subject to your knowledge, please confirm that there has been no breach of the covenants referred to in the Register. *',
						description: 'You can find a copy of the Register in Documents tab >> Property >> Title',
						hideNumber: true,
						isRequired: true,
						labelTrue: 'Confirmed',
						labelFalse: 'Not confirmed',
					},
					{
						type: 'comment',
						name: 'no_covenants_breach_desc',
						visibleIf: '{no_covenants_breach_desc} = false',
						title: 'Tell us more',
						hideNumber: true,
						requiredIf: '{no_covenants_breach_desc} = false',
					},
					{
						type: 'boolean',
						name: 'no_additional_plot_yn',
						title:
							'Please confirm that there is no additional plot held under a separate title (whether registered  or unregistered) that should be included in the sale but which has not been  produced to us.  *',
						hideNumber: true,
						isRequired: true,
						labelTrue: 'Confirmed',
						labelFalse: 'Not confirmed',
					},
					{
						type: 'comment',
						name: 'no_additional_plot_desc',
						visibleIf: '{no_additional_plot_yn} = false',
						title: 'Please give details',
						hideNumber: true,
						requiredIf: '{no_additional_plot_yn} = false',
					},
					{
						type: 'boolean',
						name: 'no_extra_parking_yn',
						title:
							'Please confirm that the property does not have the benefit of an exclusive parking space that is not reflected in the boundaries of the Title Plan.  *',
						description: 'You can find a copy of the Title Plan in Documents tab >> Property >> Title',
						hideNumber: true,
						isRequired: true,
						labelTrue: 'Confirmed',
						labelFalse: 'Not confirmed',
					},
					{
						type: 'comment',
						name: 'no_extra_parking_desc',
						visibleIf: '{no_extra_parking_yn} = false',
						title: 'Tell us more',
						hideNumber: true,
						requiredIf: '{no_extra_parking_yn} = false',
					},
					{
						type: 'boolean',
						name: 'correct_boundary_yn',
						title:
							'Please confirm that the Title Plan (shown below) accurately reflects the boundaries on the ground/flat layout and position.  *',
						hideNumber: true,
						isRequired: true,
						labelTrue: 'Confirmed',
						labelFalse: 'Not confirmed',
					},
					...(titlePlanImageUrl
						? [
								{
									type: 'image',
									name: 'title_plan_image',
									imageLink: titlePlanImageUrl,
									imageFit: 'cover',
									imageHeight: 'auto',
									imageWidth: '100%',
								},
						  ]
						: []),
					{
						type: 'comment',
						name: 'correct_boundary_desc',
						visibleIf: '{correct_boundary_yn} = false',
						title: 'Tell what is wrong',
						hideNumber: true,
						requiredIf: '{correct_boundary_yn} = false',
					},
					{
						type: 'boolean',
						name: 'no_boundary_change_yn',
						title:
							'Subject to your knowledge, please confirm that no boundary features have been moved in the last 10 years (or during your ownership if longer).',
						hideNumber: true,
						isRequired: true,
						labelTrue: 'Confirmed',
						labelFalse: 'Not confirmed',
					},
					{
						type: 'comment',
						name: 'no_boundary_change_desc',
						visibleIf: '{no_boundary_change_yn} = false',
						title: 'Please give details',
						hideNumber: true,
						requiredIf: '{no_boundary_change_yn} = false',
					},
				],
				title: 'Title',
			},
			{
				name: 'defects',
				elements: [
					{
						type: 'boolean',
						name: 'defects_damage_yn',
						title:
							'To your knowledge, has the property suffered any physical damage as a result of (for example) being physically struck or from a fire? If so, please specify, as far as possible, the type of damage and when this occurred. ',
						hideNumber: true,
						defaultValue: 'false',
						labelFalse: 'Not to my knowledge',
					},
					{
						type: 'comment',
						name: 'defects_damage_desc',
						visibleIf: '{defects_damage_yn} = true',
						title: 'Please give details',
						hideNumber: true,
						requiredIf: '{defects_damage_yn} = true',
					},
					{
						type: 'boolean',
						name: 'defects_conceal_yn',
						title:
							'Please confirm that no active steps have been taken to conceal any defect in the condition of the property either by you or by someone acting on behalf of you. *',
						hideNumber: true,
						defaultValue: 'true',
						isRequired: true,
						labelTrue: 'Confirmed',
						labelFalse: 'Not confirmed',
					},
				],
				title: 'Defects',
			},
		],
		showCompletedPage: false,
		showProgressBar: 'top',
		completeText: 'Submit',
		widthMode: 'static',
	};
};
