import { FC } from 'react';
import Iconify from '../Iconify';
import { FieldWrapper, GeneralBoxWrapper, IconWrapper, ValueWrapper } from './styles';
import type { IInviteCardTooltip } from './types';

export const InviteCardTooltip: FC<IInviteCardTooltip> = ({ fields }) => (
	<GeneralBoxWrapper>
		{fields.map(({ icon, value, onCopy }) => (
			<FieldWrapper key={value} isCopy={!!onCopy} {...(onCopy && { onClick: onCopy })}>
				<IconWrapper>
					<Iconify icon={icon} fontSize={15} color='#FFFFFF' />
				</IconWrapper>
				<ValueWrapper>{value}</ValueWrapper>
			</FieldWrapper>
		))}
	</GeneralBoxWrapper>
);
