import { FC } from 'react';
import type { IRoleBasedGuard } from './types';

const RoleBasedGuard: FC<IRoleBasedGuard> = ({ children }) => (
	// if (typeof roles !== 'undefined' && !(user?.roles || []).some((role) => roles.includes(role.name))) {
	// 	return hasContent ? (
	// 		<Container component={MotionContainer} sx={{ textAlign: 'center' }}>
	// 			<m.div variants={varBounce().in}>
	// 				<Typography variant='h3' paragraph>
	// 					Permission Denied
	// 				</Typography>
	// 			</m.div>

	// 			<m.div variants={varBounce().in}>
	// 				<Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
	// 			</m.div>

	// 			<m.div variants={varBounce().in}>
	// 				<ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
	// 			</m.div>
	// 		</Container>
	// 	) : null;
	// }

	<>{children}</>
);
export default RoleBasedGuard;
