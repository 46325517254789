import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import { FONT_ROBOTO } from 'src/theme/typography';

export const ScrollBarStyle = {
	height: 1,
	'& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
};

export const ScrollbarStackStyle = ({ isCollapse }: { isCollapse: boolean }) => ({
	pt: 3,
	pb: 2,
	px: 2.5,
	flexShrink: 0,
	...(isCollapse && { alignItems: 'center' }),
});

export const SupportStyled = styled(Typography)({
	position: 'sticky',
	background: 'white',
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	marginTop: 'auto',
	marginBottom: '20px',
	padding: '30px',
	fontFamily: FONT_ROBOTO,
	color: 'custom.bidyText',
	cursor: 'pointer',
});

export const IconifyStyled = styled(Iconify)`
	margin-right: 11.13px;
	height: 18px;
	width: 18px;
	color: ${palette.light.custom.bidyText};
`;

export const SupportModalTitleStyled = styled(Typography)({
	marginBottom: '30px',
	textTransform: 'uppercase',
	fontWeight: 700,
	fontSize: '18px',
	lineHeight: '20px',
});

export const SupportModalSubtitleStyled = styled(Typography)(() => ({
	display: 'flex',
	alignItems: 'center',
	marginTop: '15px',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '19px',
	color: palette.light.custom.bidyText,
	a: {
		color: palette.light.primary.dark,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
			cursor: 'pointer',
		},
	},
	span: {
		margin: '0px 10px',
	},
	svg: {
		height: '24px',
		width: '24px',
		marginRight: '40px',
	},
}));
