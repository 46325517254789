import { TransactionTenure } from 'src/redux/types';
import { IQuestionnaireProperty } from 'src/types';

export const guarantees = (
	tenure: TransactionTenure | undefined,
	propertyQuestionnaire: IQuestionnaireProperty | null,
) => {
	const isFreehold = tenure === 'freehold';
	const isNotShareOfFreehold = propertyQuestionnaire?.property_details.dwelling?.share_of_freehold_yn === false;
	const isShareOfFreehold = propertyQuestionnaire?.property_details.dwelling?.share_of_freehold_yn === true;

	return {
		logoWidth: '100px',
		logoHeight: '100px',
		logoPosition: 'right',
		completedHtml: "<h3>That's it! Thank you for completing the Guarantees, Warranties and Insurance card! 🎉</h3>",
		completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
		loadingHtml: '<h3>Loading questions...</h3>',
		pages: [
			{
				name: 'guarantees',
				elements: [
					{
						type: 'checkbox',
						name: 'guarantees_warranties_property',
						title: 'Does the property benefit from the following guarantees or warranties? *',
						hideNumber: true,
						isRequired: true,
						choices: [
							{
								value: 'new_home_warranty',
								text: 'New home warranty (e.g. NHBC or similar)',
							},
							{
								value: 'damp_proofing',
								text: 'Damp proofing',
							},
							{
								value: 'timber_treatment',
								text: 'Timber treatment',
							},
							{
								value: 'windows',
								text: 'Windows, roof lights, roof windows or glazed doors',
							},
							{
								value: 'electrical_work',
								text: 'Electrical work',
							},
							{
								value: 'roofing',
								text: 'Roofing',
							},
							{
								value: 'central_heating',
								text: 'Central heating',
							},
							{
								value: 'underpinning',
								text: 'Underpinning',
							},
						],
						showOtherItem: true,
						showNoneItem: true,
					},
					{
						type: 'html',
						name: 'guarantees_warranties_note',
						visible: false,
						visibleIf: "{guarantees_warranties_property} <> ['none']",
						html: "We'll ask you to upload a copy of the guarantees or warranties selected above in the <b>Documents</b> tab.",
					},
					{
						type: 'boolean',
						name: 'guarantees_warranties_claims',
						visible: false,
						visibleIf: "{guarantees_warranties_property} <> ['none']",
						title: 'Have any claims been made under any of these guarantees or warranties?',
						hideNumber: true,
						requiredIf: "{guarantees_warranties_property} <> ['none']",
					},
					{
						type: 'comment',
						name: 'guarantees_warranties_claims_desc',
						visible: false,
						visibleIf: '{guarantees_warranties_claims} = true',
						title: 'Please give us any details.',
						hideNumber: true,
						requiredIf: '{guarantees_warranties_claims} = true',
					},
				],
				title: 'Guarantees and warranties',
				description:
					"We'd like to know if any of the items mentioned below are still covered by warranty or some form of guarantee.",
			},
			{
				name: 'insurance_page',
				elements: [
					{
						type: 'html',
						name: 'insurance_types_note',
						html: 'Residential property insurance generally comes in two main kinds: <b>contents insurance</b> and <b>buildings insurance</b>.<br></br>\n\n<ul>\n<li><b>Buildings insurance</b>: This covers the permanent structure of your property, including things like walls, roof, windows, and built-in fixtures like kitchens. It protects against damage caused by events like fire, storms, floods, and subsidence.</li><br />\n\n<li><b>Contents insurance</b>: This protects your personal belongings inside your home, such as furniture, clothes, electronics, and valuables. It covers loss or damage due to theft, fire, accidental damage, and more.</li>\n</ul>',
					},
					{
						type: 'panel',
						name: 'insurance',
						elements: [
							{
								type: 'radiogroup',
								name: 'buildings',
								title: 'Buildings insurance',
								hideNumber: true,
								isRequired: true,
								choices: [
									{ value: 'leaseholders', text: 'You' },
									...(isFreehold && isNotShareOfFreehold
										? []
										: [{ value: 'freeholders', text: isShareOfFreehold ? 'Co-freeholders' : 'Landlord' }]),
									...(propertyQuestionnaire?.ownership?.management?.management_company_yn
										? [{ value: 'management_company', text: 'Management company' }]
										: []),
								],
								showNoneItem: true,
								noneText: 'None',
								colCount: 3,
							},
							{
								type: 'radiogroup',
								name: 'contents',
								title: 'Contents insurance',
								hideNumber: true,
								isRequired: true,
								choices: [
									{
										value: 'leaseholders',
										text: 'You',
									},
								],
								showNoneItem: true,
								noneText: 'None',
								colCount: 3,
							},
						],
						title: 'What type of insurance do you have, and who is it taken out by? *',
						isRequired: true,
					},
					{
						type: 'html',
						name: 'insurance_note',
						visible: false,
						visibleIf: "{contents} = 'leaseholders'",
						html: "We'll ask you to upload a copy of your <b> contents insurance policy</b> in the <b>Documents</b> tab.",
					},
					{
						type: 'comment',
						name: 'insurance_desc',
						visible: false,
						visibleIf: "{contents} = 'none'",
						title: 'Why not?',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'html',
						name: 'buildings_insurance_note',
						visible: false,
						visibleIf: "{buildings} = 'leaseholders' or {buildings} = 'freeholders'",
						html: "We'll ask you to upload a copy of the <b>buildings insurance policy</b> in the <b>Documents</b> tab.",
					},
					{
						type: 'matrix',
						name: 'buildings_insurance_issues',
						visibleIf: "{buildings} = 'leaseholders' or {buildings} = 'freeholders'",
						title: 'To your knowledge, during your tenancy, has buildings insurance ever been: *',
						hideNumber: true,
						isRequired: true,
						columns: [
							{
								value: 'yes',
								text: 'Yes',
							},
							{
								value: 'no',
								text: 'No',
							},
						],
						rows: [
							{
								value: 'abnormal_premium_rise',
								text: 'Subject to an abnormal rise in premiums?',
							},
							{
								value: 'high_excesses',
								text: 'Subject to high excesses?',
							},
							{
								value: 'unusual_conditions',
								text: 'Subject to unusual conditions?',
							},
							{
								value: 'insurance_refused',
								text: 'Refused?',
							},
						],
					},
					{
						type: 'comment',
						name: 'buildings_insurance_issues_desc',
						visibleIf:
							"((({buildings_insurance_issues.abnormal_premium_rise} = 'yes') or ({buildings_insurance_issues.high_excesses} = 'yes')) or ({buildings_insurance_issues.unusual_conditions} = 'yes')) or ({buildings_insurance_issues.insurance_refused} = 'yes')",
						title: 'Please share any details *',
						hideNumber: true,
						requiredIf:
							"((({buildings_insurance_issues.abnormal_premium_rise} = 'yes') or ({buildings_insurance_issues.high_excesses} = 'yes')) or ({buildings_insurance_issues.unusual_conditions} = 'yes')) or ({buildings_insurance_issues.insurance_refused} = 'yes')",
					},
					{
						type: 'boolean',
						name: 'insurance_declined_yn',
						title:
							'Has any insurer declined to issue normal comprehensive household insurance cover for the property or offered cover other than on normal terms? *',
						hideNumber: true,
						isRequired: true,
						labelFalse: 'Not to my knowledge',
					},
					{
						type: 'boolean',
						name: 'buildings_insurance_claims',
						title: 'Have you made any buildings insurance claims? *',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'comment',
						name: 'buildings_insurance_claims_desc',
						visibleIf: '{buildings_insurance_claims} = true',
						title: 'Please share any details.',
						hideNumber: true,
					},
				],
				title: 'Insurance',
			},
		],
		showCompletedPage: false,
		showProgressBar: 'top',
		completeText: 'Submit',
		widthMode: 'static',
	};
};
