import { useCallback, useEffect } from 'react';

import InviteCard from 'src/components/InviteCard/InviteCard';
import { useDispatch, useSelector } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import { IInviteCardMenuOption, LocalUser } from 'src/components/InviteCard/types';
import {
	getTransactionParticipantsThunk,
	removeTransactionPartie,
	selectTransactionOverview,
	selectTransactionParticipantsField,
} from 'src/redux/slices/transaction';
import { emptyTransactionBuyer, emptyTransactionSeller } from 'src/constants/mock';
import { selectUser } from 'src/redux/slices/auth';
import modals from 'src/constants/modals';
import { useSnackbar } from 'notistack';
import Progress from 'src/components/Progress';
import { Box } from '@mui/material';
import { selectTransactionQuestionnaire } from 'src/redux/slices/transactionQuestionnaire';
import { IExtendedQuestionnaireTransaction } from 'src/types';
import { ParticipantsCategories } from './style';
import { TransactionSide } from '../types';
import PromoteBuyerModal from '../modals/PromoteBuyerModal';
import ConfirmPriceModal from '../modals/ConfirmPriceModal';
import SaleConfirmationModal from '../modals/SaleConfirmationModal';
import CounterOfferModal from '../modals/CounterOfferModal';
import Category from './components/Category';
import {
	getQuestionnaireMenuOptions,
	getPlaceholderMenuOptions,
	getResendInvitationMenuOption,
	getVerificationIdMenuOptions,
} from './utils';
import WithdrawOfferModal from '../modals/WithdrawOfferModal';
import AcceptAskPriceConfirmationModal from '../modals/AcceptAskPriceConfirmationModal';
import { AcceptOfferModal } from '../modals/AcceptOfferModal';

const MyParticipants = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const currentUser = useSelector(selectUser);
	const transaction = useSelector(selectTransactionOverview);
	const questionnaire = useSelector(selectTransactionQuestionnaire) as IExtendedQuestionnaireTransaction;
	const participants = useSelector(selectTransactionParticipantsField);

	const handleInvite = (side?: TransactionSide) => () =>
		dispatch(openModal({ name: modals.invitation, payload: { side } }));

	const getViewSideActions = useCallback(
		(user: LocalUser): IInviteCardMenuOption[] => {
			const isMe = user.uid === currentUser.uid;
			const isAddedByMe = user.addedBy?.uid === currentUser.uid;
			const actions: IInviteCardMenuOption[] = [
				...getPlaceholderMenuOptions(transaction.id, currentUser, user),
				...getVerificationIdMenuOptions(currentUser.isAdmin, user, enqueueSnackbar),
				...getQuestionnaireMenuOptions(currentUser, user, questionnaire),
			];

			// if (transaction.isBuyerViewer && isMe && user.counterOffer) {
			// 	actions.push({
			// 		label: 'View a counter-offer',
			// 		onClick: () =>
			// 			dispatch(openModal({ name: modals.viewCounterOffer, payload: { offerId: user.counterOffer?.id } })),
			// 	});
			// }

			if (transaction.isBuyerViewer && isMe && user.offer) {
				actions.push({
					label: 'Withdraw offer',
					onClick: () => dispatch(openModal({ name: modals.withdrawOffer, payload: { offerId: user.offer?.id } })),
				});
			}

			// if (user.isActive && transaction.isSeller) {
			// 	actions.push({
			// 		label: 'Confirm Buyer',
			// 		onClick: () => dispatch(openModal({ name: modals.promoteBuyer, payload: { userId: user.uid } })),
			// 	});
			// }

			// if (transaction.isSeller && user.latestOffer) {
			// 	actions.push({
			// 		label: 'Make a counter-offer',
			// 		onClick: () =>
			// 			dispatch(
			// 				openModal({
			// 					name: modals.counterOffer,
			// 					payload: {
			// 						userId: user.uid,
			// 						price: user.counterOffer?.price ?? user.latestOffer?.price,
			// 						offerId: user.latestOffer?.id,
			// 					},
			// 				}),
			// 			),
			// 	});
			// }

			if (!user.isApproved && (isAddedByMe || currentUser.isAdmin) && !user.isPlaceholder) {
				actions.push(
					getResendInvitationMenuOption(
						{ role: user.role, side: user.side, userId: user.uid, transactionId: transaction.id },
						enqueueSnackbar,
					),
				);
			}

			if ((transaction.isBuyer || transaction.isSeller || currentUser.isAdmin) && !user.isPlaceholder) {
				actions.push({
					label: 'Remove',
					onClick: () => {
						dispatch(removeTransactionPartie({ id: transaction.id, role: 'buyer', side: 'view', userId: user.uid }));
					},
				});
			}

			return actions;
		},
		[transaction.isBuyerViewer, currentUser.uid, transaction.id],
	);

	const getBuySideActions = useCallback(
		(user: LocalUser): IInviteCardMenuOption[] => {
			const isMe = user.uid === currentUser.uid;
			const isAddedByMe = user.addedBy?.uid === currentUser.uid;
			const actions: IInviteCardMenuOption[] = [
				...getPlaceholderMenuOptions(transaction.id, currentUser, user),
				...getVerificationIdMenuOptions(currentUser.isAdmin, user, enqueueSnackbar),
				...getQuestionnaireMenuOptions(currentUser, user, questionnaire),
			];

			if (!user.isApproved && (isAddedByMe || currentUser.isAdmin) && !user.isPlaceholder) {
				actions.push(
					getResendInvitationMenuOption(
						{ role: user.role, side: user.side, userId: user.uid, transactionId: transaction.id },
						enqueueSnackbar,
					),
				);
			}

			if ((transaction.isSeller || transaction.isBuyer || currentUser.isAdmin) && !isMe && !user.isPlaceholder) {
				actions.push({
					label: 'Remove',
					onClick: () => {
						dispatch(removeTransactionPartie({ id: transaction.id, role: user.role, side: 'buy', userId: user.uid }));
					},
				});
			}

			return actions;
		},
		[transaction.isSeller],
	);

	const getSellSideActions = useCallback(
		(user: LocalUser): IInviteCardMenuOption[] => {
			const isAddedByMe = user.addedBy?.uid === currentUser.uid;
			const isMe = user.uid === currentUser.uid;
			const actions: IInviteCardMenuOption[] = [
				...getPlaceholderMenuOptions(transaction.id, currentUser, user),
				...getVerificationIdMenuOptions(currentUser.isAdmin, user, enqueueSnackbar),
				...getQuestionnaireMenuOptions(currentUser, user, questionnaire),
			];

			if (!user.isApproved && (isAddedByMe || currentUser.isAdmin) && !user.isPlaceholder) {
				actions.push(
					getResendInvitationMenuOption(
						{ role: user.role, side: user.side, userId: user.uid, transactionId: transaction.id },
						enqueueSnackbar,
					),
				);
			}

			if ((transaction.isSeller || currentUser.isAdmin) && !isMe && !user.isPlaceholder) {
				actions.push({
					label: 'Remove',
					onClick: () => {
						dispatch(removeTransactionPartie({ id: transaction.id, role: user.role, side: 'sell', userId: user.uid }));
					},
				});
			}

			return actions;
		},
		[transaction.isSeller, currentUser.uid, transaction.id],
	);

	useEffect(() => {
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: participants.isLoading }));
	}, [transaction.id]);

	return (
		<ParticipantsCategories height='100%'>
			{participants.isLoading && (
				<Box
					position='absolute'
					zIndex={999}
					width='100%'
					height='100%'
					display='flex'
					alignItems='center'
					justifyContent='center'
					bgcolor='white'
					sx={{ opacity: 0.6 }}
				>
					<Progress />
				</Box>
			)}
			{!!participants.data && (
				<>
					<Category title='Sell-Side' withHeader={!transaction.isInstrument}>
						{participants.data.participants.sides.sell.map((s) => (
							<InviteCard key={s.uid} user={s} menuOptions={getSellSideActions(s)} />
						))}
						{!participants.data.participants.sides.sell.length && transaction.isBuyer && (
							<InviteCard user={emptyTransactionSeller} onClick={handleInvite('sell')} />
						)}
					</Category>
					{!transaction.isInstrument && (
						<>
							<Category
								title='Buy-Side'
								isVisible={!!participants.data.participants.sides.buy.length || !transaction.hasBuyerViewer}
							>
								{participants.data.participants.sides.buy.map((b) => (
									<InviteCard key={b.uid} user={b} menuOptions={getBuySideActions(b)} />
								))}
								{!participants.data.participants.sides.buy.length &&
									transaction.isSeller &&
									!transaction.hasBuyerViewer && (
										<InviteCard user={emptyTransactionBuyer} onClick={handleInvite('buy')} />
									)}
							</Category>
							<Category title='Viewers' isVisible={!!participants.data.participants.sides.view.length}>
								{participants.data.participants.sides.view.map((v) => (
									<InviteCard key={v.uid} user={v} menuOptions={getViewSideActions(v)} isViewer />
								))}
							</Category>
						</>
					)}
				</>
			)}
			<PromoteBuyerModal />
			<ConfirmPriceModal />
			<SaleConfirmationModal />
			<CounterOfferModal />
			<WithdrawOfferModal />
			<AcceptAskPriceConfirmationModal />
			<AcceptOfferModal />
		</ParticipantsCategories>
	);
};

export default MyParticipants;
