import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Timestamp } from 'firebase/firestore';

import {
	getTransactionSummaryThunk,
	selectTransactionOverview,
	selectTransactionSummary,
} from 'src/redux/slices/transaction';
import { TransactionSummary, TransactionSummaryChecklistFieldName } from 'src/types';
import { getTransactionSummary } from 'src/utils/firebase';
import { dispatch } from 'src/redux/store';
import { buySideChecklist, emptyTransactionSummary, sellSideChecklist } from './constants';
import ChecklistTable from './components/table';
import { updateTransactionSummary } from './utils';

const Checklists = () => {
	const summary = useSelector(selectTransactionSummary);
	const transaction = useSelector(selectTransactionOverview);
	const [isInitialized, setIsInitialized] = useState(false);
	const form = useForm<TransactionSummary>({ defaultValues: emptyTransactionSummary });

	useEffect(() => {
		const setup = async () => {
			if (!summary?.data?.general.summaryId) {
				setIsInitialized(true);

				return;
			}

			const { data } = await getTransactionSummary(summary.data?.general.summaryId);

			form.reset(data);
			setIsInitialized(true);
		};

		setup();
	}, [summary.data?.general.summaryId]);

	if (!isInitialized) return null;

	const handleChange = async (side: 'sell' | 'buy', key: TransactionSummaryChecklistFieldName, checked: boolean) => {
		const sideKey = side === 'buy' ? 'buySide' : 'sellSide';

		form.setValue(`${sideKey}.${key}` as never, { checked, timestamp: checked ? Timestamp.now() : null } as never);

		await updateTransactionSummary({
			data: form.getValues(),
			transactionId: transaction.id,
			summaryId: summary.data?.general.summaryId,
		});

		dispatch(getTransactionSummaryThunk({ id: transaction.id }));
	};

	return (
		<FormProvider {...form}>
			<Box display='flex' flexDirection='row' gap='30px' flexWrap='wrap'>
				<ChecklistTable
					checklist={sellSideChecklist}
					handleChange={handleChange}
					label='Sell side'
					data={form.watch('sellSide')}
					side='sell'
				/>
				<ChecklistTable
					checklist={buySideChecklist}
					handleChange={handleChange}
					label='Buy side'
					data={form.watch('buySide')}
					side='buy'
				/>
			</Box>
		</FormProvider>
	);
};

export default Checklists;
