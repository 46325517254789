import { PaletteColor } from '@mui/material/styles';
import { User } from 'src/types';
import { defaultPreset } from 'src/utils/getColorPresets';
import noop from 'src/utils/noop';
import { AuthRoleContextProps } from './types';

export const initialDataAuthRole: AuthRoleContextProps = {
	isAncillaryRole: false,
	isInternalProfession: false,
	hasRole: false,
	profession: null,
	role: null,
	clear: noop,
	handleRole: () => null,
	handleProfession: noop,
};

export const initialDataCollapseDrawer = {
	isCollapse: false,
	collapseClick: false,
	collapseHover: false,
	hideNavbar: false,
	onToggleCollapse: noop<[]>,
	onHoverEnter: noop<[]>,
	onHoverLeave: noop<[]>,
	onToggleHideNavar: noop<[]>,
};

export const initialDataFirebase = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
	method: 'firebase',
	login: () => Promise.resolve(),
	register: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	changeUserPassword: () => Promise.resolve(),
	updateUser: () => Promise.resolve(),
};

export const initialDataSettings = {
	themeMode: 'light',
	themeDirection: 'ltr',
	themeContrast: 'default',
	themeLayout: 'horizontal',
	themeColorPresets: 'default',
	themeStretch: false,
	onToggleMode: noop<[]>,
	onChangeMode: noop<[]>,
	onToggleDirection: noop<[]>,
	onChangeDirection: noop<[]>,
	onChangeDirectionByLang: noop<[]>,
	onToggleLayout: noop<[]>,
	onChangeLayout: noop<[]>,
	onToggleContrast: noop<[]>,
	onChangeContrast: noop<[]>,
	onChangeColor: noop<[]>,
	setColor: defaultPreset as PaletteColor,
	colorOption: [],
	onToggleStretch: noop<[]>,
	onResetSetting: noop<[]>,
};

export const initialDataUserData = {
	userData: {},
	authUser: noop<[_: User]>,
	updateUser: noop<[_: User, __: (success: boolean) => void]>,
	logoutUser: noop<[]>,
};
