import {
	Table as MuiTable,
	TableCell as MuiTableCell,
	TableRow as MuiTableRow,
	TableHead as MuiTableHead,
} from '@mui/material';
import { styled } from '@mui/system';

export const Table = styled(MuiTable)({ border: '1px solid black' });

export const TableCell = styled(MuiTableCell)<{ withoutBorder?: boolean }>(({ withoutBorder }) => ({
	...(!withoutBorder && { borderRight: '1px solid black' }),
}));

export const TableRow = styled(MuiTableRow)<{ isActive?: boolean }>(({ isActive }) => ({
	borderBottom: '1px solid black',
	...(isActive && { background: '#e1e1e1' }),
}));

export const TableHead = styled(MuiTableHead)({ borderBottom: '1px solid black' });
