import { PROPERTY } from './properties';
import { TRANSACTION, TRANSACTION_PARTIE } from './transactions';
import { QUESTIONNAIRE_PROPERTY } from './questionnaireProperty';
import { QUESTIONNAIRE_TRANSACTION } from './questionnaireTransaction';

export const DOC_TEMPLATE = {
	properties: PROPERTY,
	transactions: TRANSACTION,
	transactionPartie: TRANSACTION_PARTIE,
	questionnaireProperty: QUESTIONNAIRE_PROPERTY,
	questionnaireTransaction: QUESTIONNAIRE_TRANSACTION,
};
