import { dispatch } from 'src/redux/store';
import useLoading from 'src/hooks/useLoading';
import { selectTransactionOverview, unbinTransactionThunk } from 'src/redux/slices/transaction';
import modals from 'src/constants/modals';
import { useSelector } from 'react-redux';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { ConfirmationModal } from '../../common';

const UnbinTransactionModal = () => {
	const { isLoading, setIsLoading } = useLoading();
	const transaction = useSelector(selectTransactionOverview);

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(unbinTransactionThunk());

		handleCloseModal();
		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '550px' }}
			modalName={modals.unbinTransaction}
			isLoading={isLoading}
			title='UNBIN TRANSACTION'
			confirmText='Unfreeze'
			cancelText='Cancel'
			handleConfirm={handleConfirm}
			description={
				<>
					Are you sure you want to <b>unbin</b> transaction of <b>{transaction.name}</b>?
				</>
			}
		/>
	);
};

export default UnbinTransactionModal;
