import Iconify from 'src/components/Iconify';
import { useSelector } from 'react-redux';
import modals from 'src/constants/modals';
import { handleOpenModal } from 'src/utils/propertyHelpers';
import { PropertyCreateModal } from 'src/sections/property/components';
import { selectUser } from 'src/redux/slices/auth';
import AddTransactionModal from 'src/pages/transactions/modals/AddTransactionModal';
import FailedPatchPropertiesModal from 'src/sections/property/components/FailedPatchProperties';
import AddRemortgageModal from 'src/pages/transactions/modals/AddRemortgageModal';
import { ActionButtonItem, ActionButtons } from '../styles';

const DashboardActionButtons = () => {
	const user = useSelector(selectUser);

	const handleAddProperty = () => handleOpenModal(modals.addProperty);
	const handleAddTransaction = () => handleOpenModal(modals.addTransaction);
	const handleAddRemortgage = () => handleOpenModal(modals.addRemortgage);

	return (
		<ActionButtons>
			{(user.isPrincipal || user.isAdmin) && (
				<ActionButtonItem onClick={handleAddProperty}>
					<Iconify icon='material-symbols:add-circle-outline-rounded' fontSize={20} />
					Add Property
				</ActionButtonItem>
			)}
			{(user.isAncillary || user.isAdmin) && (
				<>
					<ActionButtonItem onClick={handleAddTransaction}>
						<Iconify icon='material-symbols:add-circle-outline-rounded' fontSize={20} />
						Add transaction
					</ActionButtonItem>
					<ActionButtonItem onClick={handleAddRemortgage}>
						<Iconify icon='material-symbols:wifi-home-outline' fontSize={20} />
						Add remortgage
					</ActionButtonItem>
				</>
			)}
			<PropertyCreateModal />
			<AddTransactionModal />
			<AddRemortgageModal />
			<FailedPatchPropertiesModal />
		</ActionButtons>
	);
};

export default DashboardActionButtons;
