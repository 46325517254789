/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { DocumentReference, updateDoc } from 'firebase/firestore';
import { Tracker } from 'src/types';
import { getTrackerRef, getTrackerSnapshot, getTracker as getTrackerUtil } from 'src/utils/firebase';
import { RootState } from '../store';
import { TrackerState } from '../types/tracker';
import { selectTransactionOverview } from './transaction';

const initialState: TrackerState = {
	tracker: { isInitialized: false, data: null },
};

export const getTracker = createAsyncThunk<
	(Tracker & { id: string }) | null,
	void,
	{ rejectValue: ValidationErrors | string }
>('tracker/get', async (_, { getState }) => {
	const state = getState() as RootState;

	const transaction = selectTransactionOverview(state);

	if (!transaction?.trackerId) throw new Error('Tracker not found');

	const tracker = await getTrackerUtil(transaction.trackerId);

	return { id: tracker.id, ...tracker.data };
});

export const updateTrackerByRef = createAsyncThunk<
	void,
	{ trackerRef: DocumentReference<Tracker>; data: Partial<Tracker> },
	{ rejectValue: ValidationErrors | string }
	// eslint-disable-next-line consistent-return
>('tracker/update', async ({ trackerRef, data }, { rejectWithValue }) => {
	try {
		const tracker = await getTrackerSnapshot(trackerRef.id);
		if (tracker.exists()) await updateDoc(trackerRef, data);
	} catch (error) {
		console.error(error);
		const e = error as AxiosError<ValidationErrors>;
		// eslint-disable-next-line
		return rejectWithValue(e?.response?.data || '');
	}
});

export const updateTracker = createAsyncThunk<void, Partial<Tracker>, { rejectValue: ValidationErrors | string }>(
	'tracker-user/update',
	async (data, { getState, rejectWithValue }) => {
		const {
			tracker: { tracker },
		} = getState() as RootState;
		try {
			if (!tracker.data) return;

			await updateDoc<Tracker>(getTrackerRef(tracker.data.id), data);
		} catch (error) {
			console.error(error);
			const e = error as AxiosError<ValidationErrors>;
			// eslint-disable-next-line
			return rejectWithValue(e?.response?.data || '');
		}
	},
);

const slice = createSlice({
	name: 'tracker',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getTracker.pending, (state) => {
			state.tracker.data = null;
			state.tracker.error = null;
			state.tracker.isInitialized = false;
		});

		builder.addCase(getTracker.fulfilled, (state, { payload }) => {
			state.tracker.isInitialized = true;
			state.tracker.data = payload;
		});

		builder.addCase(getTracker.rejected, (state, { error }) => {
			state.tracker.isInitialized = true;
			state.tracker.error = error;
		});
	},
});

export const selectTracker = (state: RootState) => state.tracker.tracker as Required<TrackerState['tracker']>;

export default slice.reducer;
