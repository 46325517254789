import { SxProps } from '@mui/material';

export const modalSx: SxProps = {
	'.sd-paneldynamic__panel-wrapper.sd-paneldynamic__panel-wrapper--list.sd-paneldynamic__panel-wrapper--in-row': {
		position: 'relative',
	},

	'div[data-key="living0"], div[data-key="bedroom0"], div[data-key="bathroom0"], div[data-key="utility0"], div[data-key="specialty0"], div[data-key="storage_and_service0"], div[data-key="circulation0"], div[data-key="outdoors0"]':
		{
			'.sd-action.sd-paneldynamic__btn.sd-action--negative.sd-paneldynamic__remove-btn': {
				position: 'absolute',
				top: '5px',
				right: '-30px',
				padding: '0',
				paddingLeft: '1px',
				gap: '0',
				width: '30px',
				height: '30px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				':after': {
					content: "'X'",
				},
				'.sv-string-viewer': {
					display: 'none',
				},
			},
		},
};
