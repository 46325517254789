import { FC } from 'react';
import { Checkbox, TableBody, Typography } from '@mui/material';
import moment from 'moment';
import { Box } from '@mui/system';

import { TableProps } from './types';
import { Table, TableCell, TableRow, TableHead } from './styled';

const ChecklistTable: FC<TableProps> = ({ checklist, handleChange, side, label, data }) => (
	<div>
		<Typography variant='h4'>{label}</Typography>
		<Table sx={{ height: 'fit-content', width: '600px' }}>
			<TableHead>
				<TableCell>
					<b>Step</b>
				</TableCell>
				<TableCell>
					<b>Timestamp</b>
				</TableCell>
			</TableHead>
			<TableBody>
				{checklist.sections.map((s) => (
					<>
						<TableRow isActive>
							<TableCell withoutBorder>
								<b>{s.label}</b>
							</TableCell>
							<TableCell />
						</TableRow>
						{s.fields.map((f) => {
							const fieldData = data[f.key];

							if (!fieldData) return null;

							return (
								<TableRow key={f.key}>
									<TableCell>
										<Box display='flex' alignItems='center'>
											<Checkbox
												checked={fieldData.checked}
												value={fieldData.checked}
												onChange={(e) => handleChange(side, f.key, e.target.checked)}
											/>
											{f.label}
										</Box>
									</TableCell>
									<TableCell>
										{fieldData.checked &&
											fieldData.timestamp &&
											moment(fieldData.timestamp.toDate()).format('DD/MM/YYYY hh:mm A')}
									</TableCell>
								</TableRow>
							);
						})}
					</>
				))}
			</TableBody>
		</Table>
	</div>
);

export default ChecklistTable;
