import { TransactionSummaryColumn, TransactionSummaryColumnStructure } from '../types/summary';

export const transactionSummaryColumns = {
	name: {
		key: 'name',
		name: 'Name',
		width: 250,
	},
	postcode: {
		key: 'postcode',
		name: 'Postcode',
		width: 150,
	},
	uprn: {
		key: 'uprn',
		name: 'UPRN',
		width: 150,
	},
	trackerStatus: {
		key: 'trackerStatus',
		name: 'Status',
		width: 200,
	},
	latestActionDate: {
		key: 'latestActionDate',
		name: 'Last Updated On',
		width: 200,
	},
	timeFromInstructionToSaleAgreed: {
		key: 'timeFromInstructionToSaleAgreed',
		name: 'Time from Instruction to Sale Agreed	',
		width: 225,
	},
	timeFromSaleAgreedToExchange: {
		key: 'timeFromSaleAgreedToExchange',
		name: 'Time from Sale Agreed to Exchange',
		width: 225,
	},
	duration: {
		key: 'duration',
		name: 'Duration',
		width: 175,
	},
	instructionDate: {
		key: 'instructionDate',
		name: 'Instruction Date',
		width: 175,
	},
	saleAgreedDate: {
		key: 'saleAgreedDate',
		name: 'Sale Agreed Date',
		width: 175,
	},
	buyerDueDiligenceDate: {
		key: 'buyerDueDiligenceDate',
		name: 'Buyer Due Diligence Date',
		width: 200,
	},
	exchangeDate: {
		key: 'exchangeDate',
		name: 'Exchange Date',
		width: 150,
	},
	completionDate: {
		key: 'completionDate',
		name: 'Completion Date',
		width: 175,
	},
	askPrice: {
		key: 'askPrice',
		name: 'Ask Price',
		width: 175,
	},
	ourMargin: {
		key: 'ourMargin',
		name: 'Our Margin',
		width: 175,
	},
	sellers: {
		key: 'sellers',
		name: 'Seller(s)',
		width: 250,
	},
	sellerQuote: {
		key: 'sellerQuote',
		name: 'Seller Quote (incl VAT)',
		width: 225,
	},
	sellSideAgents: {
		key: 'sellSideAgents',
		name: 'Sell Side Agent(s)',
		width: 200,
	},
	sellSideAgencyRate: {
		key: 'sellSideAgencyRate',
		name: 'Sell Side Agency Rate',
		width: 200,
	},
	sellSideAgencyFee: {
		key: 'sellSideAgencyFee',
		name: 'Sell Side Agency Fee',
		width: 200,
	},
	sellSideReferralFee: {
		key: 'sellSideReferralFee',
		name: 'Sell Side Referral Fee',
		width: 200,
	},
	sellSideSolicitors: {
		key: 'sellSideSolicitors',
		name: 'Sell Side Solicitor(s)',
		width: 200,
	},
	sellSideLegalFee: {
		key: 'sellSideLegalFee',
		name: 'Sell Side Legal Budget (incl VAT)',
		width: 200,
	},
	buyers: {
		key: 'buyers',
		name: 'Buyer(s)',
		width: 200,
	},
	buyerQuote: {
		key: 'buyerQuote',
		name: 'Buyer Quote (incl VAT)',
		width: 200,
	},
	buySideAgents: {
		key: 'buySideAgents',
		name: 'Buy Side Agent(s)',
		width: 200,
	},
	buySideAgencyRate: {
		key: 'buySideAgencyRate',
		name: 'Buy Side Agency Rate',
		width: 200,
	},
	buySideAgencyFee: {
		key: 'buySideAgencyFee',
		name: 'Buy Side Agency Fee',
		width: 200,
	},
	buySideReferralFee: {
		key: 'buySideReferralFee',
		name: 'Buy Side Referral Fee',
		width: 200,
	},
	buySideSolicitors: {
		key: 'buySideSolicitors',
		name: 'Buy Side Solicitor(s)',
		width: 200,
	},
	buySideLegalFee: {
		key: 'buySideLegalFee',
		name: 'Buy Side Legal Budget (incl VAT)',
		width: 200,
	},
} as const;

export const transactionSummaryColumnsByKeys = Object.values(transactionSummaryColumns).reduce((acc, column) => {
	acc[column.key] = column;

	return acc;
}, {} as Record<TransactionSummaryColumn, TransactionSummaryColumnStructure>);

export const transactionSummaryColumnsKeys = Object.values(transactionSummaryColumns).map(({ key }) => key);
