import { transactionDocuments } from 'src/pages/transactions/constants';
import { TrackerStepName, LogEvent, LogStatusEnum, LogTypeEnum, PropertyTitleCategory } from 'src/types';

export const trackerSteps: TrackerStepName[] = [
	'sellerDueDiligence',
	'listing',
	'saleAgreed',
	'buyerDueDiligence',
	'exchange',
	'completion',
];

export const propertyTitleDocumentsCategories = {
	register: 'register',
	plan: 'plan',
	conveyance: 'conveyance',
	lease: 'lease',
	transfer: 'transfer',
	subCharge: 'subCharge',
	deed: 'deed',
	charge: 'charge',
	other: 'other',
	abstract: 'abstract',
	agreement: 'agreement',
	assent: 'assent',
	assignment: 'assignment',
	indenture: 'indenture',
	licence: 'licence',
	commonholdCommunityStatement: 'commonholdCommunityStatement',
	memorandumAndArticlesOfAssociation: 'memorandumAndArticlesOfAssociation',
	surrenderOfDevelopmentRight: 'surrenderOfDevelopmentRight',
	terminationDocument: 'terminationDocument',
} as const;

export const labelByPropertyTitleDocumentsCategories: Record<PropertyTitleCategory, string> = {
	register: 'Registers',
	plan: 'Plans',
	conveyance: 'Conveyances',
	lease: 'Leases',
	transfer: 'Transfers',
	subCharge: 'Sub-Charges',
	deed: 'Deeds',
	charge: 'Charges',
	other: 'Other',
	abstract: 'Abstracts',
	agreement: 'Agreements',
	assent: 'Assents',
	assignment: 'Assignments',
	indenture: 'Indentures',
	licence: 'Licences',
	commonholdCommunityStatement: 'Commonhold Community Statements',
	memorandumAndArticlesOfAssociation: 'Memorandum and Articles of Associations',
	surrenderOfDevelopmentRight: 'Surrender of Development Rights',
	terminationDocument: 'Termination Documents',
} as const;

export const propertyDocumentAvailabilityCodes = {
	unavailable: 'UNAVAILABLE',
	immediate: 'IMMEDIATE',
	manual: 'MANUAL',
} as const;

export const propertyTitleDocumentsCategoriesKeys = Object.keys(propertyTitleDocumentsCategories);

export const labelByLogEvent: Record<LogEvent, string> = {
	CHANGE_EMAIL_REQUEST: 'Change Email Request',
	INITIAL_INVITATION: 'Initial Transaction Invitation',
	INVITATION: 'Invitation',
	NEW_PROPERTY: 'New Property',
	NEW_TRANSACTION: 'New Transaction',
	NEW_USER: 'New User',
	PENDING_INVITATION: 'Pending Invitation',
	PRINCIPAL_PATH: 'Principal Path',
	REGISTRATION: 'Registration',
	RESEND_INVITATION: 'Resend Invitation',
	RESET_PASSWORD: 'Reset Password',
	TITLE_EXTRACTION: 'Title Extraction',
	TITLE_SELECTION: 'Title Selection',
	VERIFICATION: 'Verification',
	PENDING_VERIFICATION: 'Pending Verification',
	SELL_SIDE_PARTICIPANTS_ONBOARDED: 'All sell side parties onboarded',
	SELL_PACK_HANDOVER: 'Sell Pack Handover',
	KICK_OFF: 'Kick off',
	SELL_SIDE_COMPLETE: 'Sell Side Complete',
	BUY_SIDE_COMPLETE: 'Buy Side Complete',
	PENDING_SELLER_QUESTIONS: 'Pending Seller Questions',
	PENDING_BUYER_QUESTIONS: 'Pending Buyer Questions',
	PENDING_GIFTOR_QUESTIONS: 'Pending Giftor Questions',
	AUTOMATION: 'Automation',
	MANUAL_TRIGGER: 'Manual Trigger',
	BUY_SIDE_PARTICIPANTS_ONBOARDED: 'All buy side parties onboarded',
	// BUYER: transactionDocuments.buyer.name,
	CONTRACT_PACK_SENT: transactionDocuments.contractPackSent.name,
	CYBERCRIME_NOTICE: transactionDocuments.cyberCrimeNotice.name,
	DEEDS_REQUEST: transactionDocuments.deedsRequest.name,
	ADVANCE_ENQUIRIES: transactionDocuments.advanceEnquiries.name,
	FILE_CLOSING_NOTICE: transactionDocuments.fileClosingNotice.name,
	GIFT_DEED: transactionDocuments.giftDeed.name,
	MEMORANDUM_OF_SALE: transactionDocuments.memorandumOfSale.name,
	REPLIES_TO_COMPLETION_INFO: transactionDocuments.repliesToCompletionInfo.name,
	REPLIES_TO_ENQUIRIES: transactionDocuments.repliesToEnquiries.name,
	CIF_BUYERS: transactionDocuments.CIFBuyers.name,
	CIF_SELLERS: transactionDocuments.CIFSellers.name,
	SDLT: transactionDocuments.SDLT.name,
	STANDARD_CONDITIONS_OF_SALE: transactionDocuments.standardConditionsOfSale.name,
	TA10: transactionDocuments.ta10.name,
	TA6: transactionDocuments.ta6.name,
	TA7: transactionDocuments.ta7.name,
	TR1: transactionDocuments.tr1.name,
	TR13: transactionDocuments.tr13.name,
	GET_VERIFIED: 'Get verified',
	CONFIRM_VERIFICATION_RESULTS: 'Confirm verification results',
	ENTER_PERSONAL_DETAILS: 'Enter personal details',
	COMPLETE_SELL_QUESTIONS: 'Complete sell questions',
	COMPLETE_BUY_QUESTIONS: 'Complete buy questions',
	UPLOAD_FLOOR_PLAN: 'Upload floor plan',
	UPLOAD_PROTECTED_BUILDING: 'Upload protected building',
	UPLOAD_PROTECTED_TREES: 'Upload protected trees',
	UPLOAD_ALTERATIONS_USE: 'Upload alterations use',
	UPLOAD_ALTERATIONS_WINDOWS: 'Upload alterations windows',
	UPLOAD_ALTERATIONS_EXTENSION: 'Upload alterations extension',
	UPLOAD_ALTERATIONS_CONSERVATORY: 'Upload alterations conservatory',
	UPLOAD_LOFT_CONVERSION: 'Upload loft conversion',
	UPLOAD_GARAGE_CONVERSION: 'Upload garage conversion',
	UPLOAD_INTERNAL_WALLS: 'Upload internal walls',
	UPLOAD_OTHER_WORKS: 'Upload other works',
	UPLOAD_HOME_WARRANTY: 'Upload home warranty',
	UPLOAD_UNDERPINNING_WARRANTY: 'Upload underpinning warranty',
	UPLOAD_CENTRAL_HEATING_WARRANTY: 'Upload central heating warranty',
	UPLOAD_ROOFING_WARRANTY: 'Upload roofing warranty',
	UPLOAD_ELECTRICAL_WORK_WARRANTY: 'Upload electrical work warranty',
	UPLOAD_WINDOWS_WARRANTY: 'Upload windows warranty',
	UPLOAD_TIMBER_TREATMENT_WARRANTY: 'Upload timber treatment warranty',
	UPLOAD_DAMP_ROOFING_WARRANTY: 'Upload damp roofing warranty',
	UPLOAD_OTHER_WARRANTIES: 'Upload other warranties',
	UPLOAD_BUILDING_INSURANCE: 'Upload building insurance',
	UPLOAD_HOME_INSURANCE: 'Upload home insurance',
	UPLOAD_JAPANESE_KNOTWEED: 'Upload japanese knotweed',
	UPLOAD_SELL_BANK_STATEMENTS: 'Upload sell bank statements',
	UPLOAD_SELL_MORTGAGE_STATEMENT: 'Upload sell mortgage statement',
	ORDER_MANAGEMENT_PACK: 'Order management pack',
	UPLOAD_GROUND_RENT: 'Upload charge rent',
	UPLOAD_SERVICE_CHARGE: 'Upload service charge',
	UPLOAD_FREEHOLD_MANAGEMENT_COMPANY: 'Upload freehold management company',
	UPLOAD_SEWERAGE: 'Upload sewerage',
	UPLOAD_PARTY_WALL: 'Upload party wall',
	UPLOAD_EICR: 'Upload EICR',
	UPLOAD_GAS_SAFE_REGISTER: 'Upload gas safety register',
	UPLOAD_NOTICE_OF_SALE: 'Upload notice of sale',
	UPLOAD_NOTICE_ABOUT_BUILDING: 'Upload notice about building',
	UPLOAD_LEASE_TERM_CHANGES: 'Upload lease term changes',
	UPLOAD_ENFRANCHISEMENT_NOTICE: 'Upload enfranchisement notice',
	UPLOAD_COLLECTIVE_PURCHASE_NOTICE: 'Upload collective purchase notice',
	UPLOAD_LANDLORD_RESPONSE_TO_NOTICE: 'Upload landlord response to notice',
	UPLOAD_BUY_MORTGAGE: 'Upload buy mortgage',
	UPLOAD_BUY_REMORTGAGE: 'Upload buy remortgage',
	UPLOAD_BUY_EQUITY_RELEASE: 'Upload buy equity release',
	UPLOAD_BUY_SAVINGS: 'Upload buy savings',
	UPLOAD_BUY_GIFT: 'Upload buy gift',
	UPLOAD_BUY_INHERITANCE: 'Upload buy inheritance',
	UPLOAD_BUY_PROPERTY_SALE: 'Upload buy property sale',
	UPLOAD_BUY_PENSION: 'Upload buy pension',
	UPLOAD_BUY_GAMBLING: 'Upload buy gambling',
	UPLOAD_BUY_COMPENSATION: 'Upload buy compensation',
	UPLOAD_BUY_BANK_STATEMENTS: 'Upload buy bank statements',
	SELLER_CLIENT_CARE: 'Seller client care',
	BUYER_CLIENT_CARE: 'Buyer client care',
};

export const labelByLogStatus: Record<LogStatusEnum, string> = {
	FAILED: 'Failed',
	PENDING: 'Pending',
	SUCCEED: 'Succeed',
	SENT: 'Sent',
	COMPLETE: 'Complete',
};

export const labelByLogType: Record<LogTypeEnum, string> = { EMAIL: 'Email', JOB: 'Job', TASK: 'Task' };
