import { dispatch } from 'src/redux/store';
import useLoading from 'src/hooks/useLoading';
import { archiveTransactionThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import modals from 'src/constants/modals';
import { useSelector } from 'react-redux';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { ConfirmationModal } from '../../common';

const ArchiveTransactionModal = () => {
	const { isLoading, setIsLoading } = useLoading();
	const transaction = useSelector(selectTransactionOverview);

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(archiveTransactionThunk());

		handleCloseModal();
		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '550px' }}
			modalName={modals.archiveTransaction}
			isLoading={isLoading}
			title='ARCHIVE TRANSACTION'
			confirmText='Archive'
			cancelText='Cancel'
			handleConfirm={handleConfirm}
			description={
				<>
					Are you sure you want to <b>archive</b> transaction of <b>{transaction.name}</b>?
				</>
			}
		/>
	);
};

export default ArchiveTransactionModal;
