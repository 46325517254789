import { FC, useState } from 'react';
import { Typography } from '@mui/material';

import Iconify from 'src/components/Iconify';
import { LinkButtonProps } from './types';

const LinkButton: FC<LinkButtonProps> = ({ handleClick, children, disabled = false }) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleClickWithLoading = async () => {
		setIsLoading(true);

		await handleClick?.();

		setIsLoading(false);
	};

	return (
		<Typography
			variant='link'
			onClick={handleClickWithLoading}
			sx={{
				pointerEvents: disabled || isLoading ? 'none' : 'auto',
				display: 'inline-flex',
				alignItems: 'center',
				gap: '3px',
			}}
			{...((disabled || isLoading) && { color: 'gray' })}
		>
			{children}
			{isLoading && <Iconify icon='line-md:loading-loop' />}
		</Typography>
	);
};

export default LinkButton;
