import { ITransactionParticipants, ITransactionPlaceholder } from 'src/redux/types';
import { IQuestionnaireTransaction } from 'src/types';
import { getTransactionSellersHolders } from 'src/utils/transaction';

export const sellerFinance = (
	participants: ITransactionParticipants,
	placeholders: ITransactionPlaceholder[],
	transactionQuestionnaire: IQuestionnaireTransaction,
) => {
	const proprietorNames = getTransactionSellersHolders(participants, placeholders, transactionQuestionnaire);

	return {
		logoPosition: 'right',
		pages: [
			{
				title: 'Ownership',
				name: 'ownership',
				elements: [
					{
						type: 'radiogroup',
						name: 'ownership_type',
						title: 'How do you own your property? *',
						hideNumber: true,
						isRequired: true,
						choices: ['Owned outright', 'Owned with a mortgage', 'Help-to-buy', 'Shared ownership'],
						showOtherItem: true,
					},
					{
						type: 'html',
						name: 'notice_sale_note',
						visibleIf: "{ownership_type} = 'Owned with a mortgage'",
						html: "Thank you. We'll ask you to upload a copy of your latest <b>Annual Mortgage Statement</b> in the <b>Documents</b> tab.",
					},
				],
			},
			{
				name: 'banking',
				elements: [
					{
						type: 'paneldynamic',
						name: 'bank_accounts',
						title: 'Bank accounts *',
						description: 'What bank accounts are you planning to use for this sale? Add as many as needed.',
						hideNumber: true,
						isRequired: true,
						templateElements: [
							{
								type: 'panel',
								name: 'account',
								elements: [
									{
										type: 'tagbox',
										name: 'account_holders',
										title: 'Account holder(s) *',
										isRequired: true,
										choices: proprietorNames,
									},
									{
										type: 'text',
										name: 'bank',
										title: 'Bank *',
										titleLocation: 'left',
										isRequired: true,
									},
									{
										type: 'text',
										name: 'sort_code',
										title: 'Sort code *',
										titleLocation: 'left',
										isRequired: true,
										maskType: 'pattern',
										maskSettings: {
											pattern: '99-99-99',
										},
										placeholder: '11-22-33',
									},
									{
										type: 'text',
										name: 'account_number',
										title: 'Account number *',
										titleLocation: 'left',
										hideNumber: true,
										isRequired: true,
										maskType: 'pattern',
										maskSettings: {
											pattern: '99999999',
										},
										placeholder: '12345678',
									},
								],
								title: 'Account details *',
								isRequired: true,
							},
						],
						noEntriesText: ' ',
						panelAddText: 'Add bank account',
					},
					{
						type: 'html',
						name: 'bank_accounts_note',
						html: "To meet compliance requirements, we must ensure that bank accounts belong to the sellers, have been active for at least one year, and that their activity aligns with expected financial norms for these individuals. <br></br>In the <b>Documents</b> tab, you'll be required to provide <b>bank statements</b> for each of the accounts above: one statement for each of the <b>last 3 months</b> and one statement <b>going back at least 12 months</b>.",
					},
				],
				title: 'Bank accounts',
			},
			{
				name: 'charges',
				elements: [
					{
						type: 'paneldynamic',
						name: 'mortgages',
						title: 'Mortgages *',
						description: 'Which mortgages are currently associated with this property?',
						hideNumber: true,
						isRequired: true,
						templateElements: [
							{
								type: 'panel',
								name: 'mortgage',
								elements: [
									{
										type: 'tagbox',
										name: 'holders',
										title: 'Mortgage holder(s) *',
										isRequired: true,
										choices: proprietorNames,
									},
									{
										type: 'text',
										name: 'lender',
										title: 'Lender *',
										titleLocation: 'left',
										isRequired: true,
									},
									{
										type: 'text',
										name: 'mortgage_account_number',
										title: 'Mortgage account number *',
										titleLocation: 'left',
										hideNumber: true,
										isRequired: true,
									},
									{
										type: 'text',
										name: 'amount_borrowed',
										title: 'Amount borrowed *',
										titleLocation: 'left',
										description: 'In Pounds',
										hideNumber: true,
										isRequired: true,
										maskType: 'numeric',
										maskSettings: { allowNegativeValues: false },
									},
									{
										type: 'text',
										name: 'balance',
										title: 'Current mortgage balance *',
										titleLocation: 'left',
										description: 'In Pounds',
										hideNumber: true,
										isRequired: true,
										maskType: 'numeric',
										maskSettings: { allowNegativeValues: false },
									},
								],
								title: 'Mortgage details',
								isRequired: true,
							},
						],
						noEntriesText: '',
						panelAddText: 'Add mortgage',
					},
				],
				title: 'Charges',
				visibleIf: "{ownership_type} = 'Owned with a mortgage'",
			},
		],
		completeText: 'Submit',
	};
};
