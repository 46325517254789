import { FC } from 'react';
import { RHFSelect } from 'src/components/hook-form';
import { TrackerStepStatusFieldProps } from './types';

const TrackerStepStatusField: FC<TrackerStepStatusFieldProps> = ({ name }) => (
	<RHFSelect name={name} loading={false} noNeedEmptyOption>
		<option value='notStarted'>Not Started</option>
		<option value='pending'>Pending</option>
		<option value='inProgress'>In Progress</option>
		<option value='completed'>Completed</option>
		<option value='error'>Error</option>
	</RHFSelect>
);

export default TrackerStepStatusField;
