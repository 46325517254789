import Iconify from 'src/components/Iconify';
import { useDispatch } from 'src/redux/store';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { OfferModal } from 'src/pages/transactions/modals/OfferModal';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import InvitationModal from 'src/pages/transactions/modals/InvitationModal';
import { selectTransactionOverview, selectTransactionSlice } from 'src/redux/slices/transaction';
import { AskPriceModal } from 'src/pages/transactions/modals/AskPriceModal';
import { Typography } from '@mui/material';
import { ActionButtonItem, ActionButtons } from '../styles';

const SaleActionButtons = () => {
	const { id = '' } = useParams();
	const dispatch = useDispatch();
	const { currentTab } = useSelector(selectTransactionSlice);
	const transaction = useSelector(selectTransactionOverview);

	if (!transaction || transaction.isArchived) {
		return (
			<Typography color='grey.600' fontWeight={500}>
				This transaction has been archived. No actions available.
			</Typography>
		);
	}

	const isParticipants = currentTab === 'participants';

	const handleQrCode = () => dispatch(openModal({ name: modals.transactionQrCode }));
	const handleDelete = () =>
		dispatch(openModal({ name: modals.dropProperty, payload: { transactionId: id, delist: true } }));
	const handleExit = () =>
		dispatch(openModal({ name: modals.dropProperty, payload: { transactionId: id, abandon: true } }));
	const handleAddParticipant = () => dispatch(openModal({ name: modals.invitation }));
	const handleAddAskPrice = () => dispatch(openModal({ name: modals.askPrice }));
	const handleEditAskPrice = () =>
		dispatch(openModal({ name: modals.askPrice, payload: { price: transaction.askPrice?.price } }));

	return (
		<ActionButtons>
			{isParticipants && (
				<ActionButtonItem onClick={handleAddParticipant}>
					<Iconify icon='zondicons:add-outline' fontSize={20} />
					Add Participant
				</ActionButtonItem>
			)}
			{(transaction.isSellSideAgent || transaction.isBuySideAgent) &&
				isParticipants &&
				!transaction.isPendingParticipant && (
					<ActionButtonItem onClick={handleQrCode}>
						<Iconify icon='bx:qr' fontSize={18} />
						Create QR code
					</ActionButtonItem>
				)}
			{(transaction.isSeller || transaction.isSellSideAgent) &&
				(transaction.askPrice ? (
					<ActionButtonItem onClick={handleEditAskPrice}>
						<Iconify icon='mdi:edit' fontSize={21} />
						Edit price
					</ActionButtonItem>
				) : (
					<ActionButtonItem onClick={handleAddAskPrice}>
						<Iconify icon='solar:tag-price-bold' fontSize={20} />
						Set price
					</ActionButtonItem>
				))}
			{transaction.isPropertyOwner ? (
				<ActionButtonItem onClick={handleDelete}>
					<Iconify icon='gravity-ui:trash-bin' fontSize={20} />
					Delist
				</ActionButtonItem>
			) : (
				<ActionButtonItem onClick={handleExit}>
					<Iconify icon='mingcute:exit-line' fontSize={20} />
					Abandon
				</ActionButtonItem>
			)}
			<OfferModal />
			<InvitationModal />
			<AskPriceModal />
		</ActionButtons>
	);
};

export default SaleActionButtons;
