export const PROPERTY = {
	owner: null,
	onSale: false,
	questionnaireProperty: null,
	name: null,
	title: {
		address: null,
		legal_desc: null,
		is_registered: null,
	},
	transaction: null,
};
