import { FC } from 'react';

import { TaskProps } from './types';
import { useTask } from './hooks/useTask';

const Task: FC<TaskProps> = (task) => {
	const { description } = useTask(task, task.user);

	return <>{description}</>;
};

export default Task;
