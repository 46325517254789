import { Box } from '@mui/material';
import { selectProperty } from 'src/redux/slices/property';
import { useSelector } from 'react-redux';
import { wrapOptionalValue } from 'src/utils/common';
import { convertMetersToFeetAndInches, convertSquareMetersToSquareFeet } from 'src/utils/numbers';
import { PropertyServicesParkingEnum } from 'src/components/cards/QuestionnaireServicesCard/types';
import { capitalize } from 'lodash';
import { convertConstructionAgeToYears } from 'src/utils/strings';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';

const BuiltFormSection = () => {
	const property = useSelector(selectProperty);

	const { latestListing, epc, propertyQuestionnaire } = property.overview;

	const isFlat = epc?.property_type === 'Flat';

	const parts = [
		// {
		// 	label: 'Type:',
		// 	value: latestListing?.type,
		// },
		// {
		// 	label: 'Subtype:',
		// 	value: latestListing?.subtype,
		// },
		{
			label: 'Bathrooms:',
			value: latestListing?.bathrooms,
		},
		{
			label: 'Bedrooms:',
			value: latestListing?.bedrooms,
		},
		// {
		// 	label: 'Features:',
		// 	value: latestListing?.keyFeatures?.join(', '),
		// },
		{
			label: 'Property type:',
			value: epc?.property_type,
		},
		{
			label: 'Built form:',
			value: epc?.built_form,
		},
		{
			label: 'Year built:',
			value: wrapOptionalValue(epc?.construction_age_band, convertConstructionAgeToYears),
		},
		...(isFlat ? [{ label: 'Floor:', value: epc?.floor_level }] : []),
		{
			label: 'Floor area:',
			value: wrapOptionalValue(epc?.total_floor_area, (meters) => `${convertSquareMetersToSquareFeet(meters)} sqft`),
		},
		{
			label: 'Ceiling height:',
			value: wrapOptionalValue(epc?.floor_height, (meters) => `${convertMetersToFeetAndInches(meters)}`),
		},
		{
			label: 'Rooms:',
			value: epc?.number_habitable_rooms,
		},
		{
			label: 'Extensions:',
			value: epc?.extension_count,
		},
		...(isFlat ? [{ label: 'Top storey?', value: epc?.flat_top_storey }] : []),
		{
			label: 'Levels:',
			value: epc?.flat_storey_count,
		},
		{
			label: 'Fireplaces:',
			value: epc?.number_open_fireplaces,
		},
		{
			label: 'Parking:',
			value: wrapOptionalValue(propertyQuestionnaire?.services.parking?.parking_arrangements, (arrangements) =>
				arrangements.map((a) => PropertyServicesParkingEnum[a] ?? capitalize(a)).join(', '),
			),
		},
		{
			label: 'Terrace/Balcony:',
			value: '',
		},
		{
			label: 'Garden:',
			value: '',
		},
	];

	return (
		<PropertyGeneralAddressCard sx={{ maxWidth: '650px' }}>
			<TypographyAddressTitle mb='20px'>BUILT FORM</TypographyAddressTitle>
			<Box display='grid' gridTemplateColumns='1fr 1fr' columnGap='40px' rowGap='10px'>
				{parts.map(({ label, value }) => (
					<Box key={label}>
						<b>{label}</b> {value}
					</Box>
				))}
			</Box>
		</PropertyGeneralAddressCard>
	);
};

export default BuiltFormSection;
