import { ReactNode } from 'react';
import { dynamicTaskType, taskType } from 'src/constants/tasks';
import { Task, TaskType, TaskUser } from 'src/redux/types';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
import {
	acceptTransactionInvitation,
	getTransactionOverviewThunk,
	getTransactionParticipantsThunk,
	processTransactionOnboarding,
	rejectTransactionInvitation,
	selectTransactionOverview,
	setCurrentTransactionTab,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { openModal } from 'src/redux/slices/modal';
import { findTransactionOnboarding } from 'src/utils/firebase/transaction-onboarding';
import syncTransactionOnboarding from 'src/api/transaction-onboarding/sync';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import LinkButton from '../../LinkButton';
import UploadButton from '../../UploadButton';

export const useTask = (task: Task, user: TaskUser): { description: ReactNode } => {
	const navigate = useNavigate();
	const currentUser = useSelector(selectUser);
	const transaction = useSelector(selectTransactionOverview);

	const isCurrentUserTask = user.id === currentUser.uid;
	const disabled = !isCurrentUserTask || !user.isApproved;
	const uploadButtonDisabled = disabled || !user.isVerified;

	const handleGoToQuestionnaires = () => dispatch(setCurrentTransactionTab('questions'));
	const handleGoToParticipants = () => dispatch(setCurrentTransactionTab('participants'));
	const handleEnterPersonalDetails = () => {
		dispatch(openModal({ name: QuestionnaireTransactionEnum.personal_details, payload: { userId: currentUser.uid } }));
	};
	const handleGetVerified = async () => {
		const onboardingSnapshot = await findTransactionOnboarding(currentUser.uid, transaction.id);

		if (!onboardingSnapshot) await syncTransactionOnboarding(transaction.id, 'verification');

		dispatch(processTransactionOnboarding({ transactionId: transaction.id }));
	};

	const handleAccept = async () => {
		await dispatch(
			acceptTransactionInvitation({
				role: task.metadata.role,
				side: task.metadata.side,
				transactionId: transaction.id,
				userId: currentUser.uid,
			}),
		);
		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	};
	const handleReject = async () => {
		await dispatch(
			rejectTransactionInvitation({
				role: task.metadata.role,
				side: task.metadata.side,
				transactionId: transaction.id,
				userId: currentUser.uid,
			}),
		);

		navigate(PATH_DASHBOARD.root);
	};

	if (task.type.startsWith(dynamicTaskType.uploadSellBankStatements)) {
		return {
			description: (
				<>
					Please upload bank statements for the past 3 months and one from at least 12 months ago for the account ending
					in {task.metadata.accountNumber} <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
		};
	}

	if (task.type.startsWith(dynamicTaskType.uploadSellMortgageStatement)) {
		return {
			description: (
				<>
					Please upload the latest annual statement for the mortgage account ending in {task.metadata.accountNumber}{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
		};
	}

	if (task.type.startsWith(dynamicTaskType.uploadBuyBankStatements)) {
		return {
			description: (
				<>
					Please upload bank statements for the past 3 months and one from at least 12 months ago for the account ending
					in {task.metadata.accountNumber} <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
		};
	}

	if (task.type.startsWith(dynamicTaskType.uploadBuyGift)) {
		return {
			description: (
				<>
					Please upload evidence showing the source of funds for the gift from {task.metadata.giftor} (e.g. savings,
					insurance, pension, etc.) <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
		};
	}

	const descriptionByType: Record<TaskType, ReactNode> = {
		[taskType.acceptInvite]: (
			<>
				Please{' '}
				<LinkButton handleClick={handleAccept} disabled={!isCurrentUserTask}>
					[Accept]
				</LinkButton>{' '}
				or{' '}
				<LinkButton handleClick={handleReject} disabled={!isCurrentUserTask}>
					[Reject]
				</LinkButton>{' '}
				your invitation
			</>
		),
		[taskType.getVerified]: (
			<>
				Please complete your ID checks{' '}
				<LinkButton handleClick={handleGetVerified} disabled={disabled}>
					[Go]
				</LinkButton>
			</>
		),
		[taskType.confirmVerificationResults]: (
			<>
				Please confirm your ID check results{' '}
				<LinkButton handleClick={handleGoToParticipants} disabled={disabled}>
					[Go]
				</LinkButton>
			</>
		),
		[taskType.enterPersonalDetails]: (
			<>
				Please enter your personal details{' '}
				<LinkButton handleClick={handleEnterPersonalDetails} disabled={disabled}>
					[Go]
				</LinkButton>
			</>
		),
		[taskType.completeSellQuestions]: (
			<>
				Please fill out the seller questions{' '}
				<LinkButton handleClick={handleGoToQuestionnaires} disabled={disabled}>
					[Go]
				</LinkButton>
			</>
		),
		[taskType.completeBuyQuestions]: (
			<>
				Please fill out the buyer questions{' '}
				<LinkButton handleClick={handleGoToQuestionnaires} disabled={disabled}>
					[Go]
				</LinkButton>
			</>
		),
		[taskType.uploadFloorPlan]: (
			<>
				Please upload the property's floor plan <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadProtectedBuilding]: (
			<>
				Please upload the Building Preservation Notice <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadProtectedTrees]: (
			<>
				Please upload the Tree Preservation Order <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadAlterationsUse]: (
			<>
				Please upload the documents for the property's change of use{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadAlterationsWindows]: (
			<>
				Please upload the documents for installing replacement windows, roof windows, roof lights, or glazed doors{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadAlterationsExtension]: (
			<>
				Please upload the documents for the extension addition{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadAlterationsConservatory]: (
			<>
				Please upload the documents for the conservatory addition{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadLoftConversion]: (
			<>
				Please upload the documents for the loft conversion <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadGarageConversion]: (
			<>
				Please upload the documents for the garage conversion{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadInternalWalls]: (
			<>
				Please upload the documents for the removal of internal walls{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadOtherWorks]: (
			<>
				Please upload the documents related to other building works{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadHomeWarranty]: (
			<>
				Please upload your new home warranty <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadUnderpinningWarranty]: (
			<>
				Please upload your underpinning warranty <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadCentralHeatingWarranty]: (
			<>
				Please upload your central heating warranty <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadRoofingWarranty]: (
			<>
				Please upload your roofing warranty <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadElectricalWorkWarranty]: (
			<>
				Please upload your electrical work warranty <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadWindowsWarranty]: (
			<>
				Please upload your windows warranty <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadTimberTreatmentWarranty]: (
			<>
				Please upload your timber treatment warranty <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadDampRoofingWarranty]: (
			<>
				Please upload your damp roofing warranty <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadOtherWarranties]: (
			<>
				Please upload any other guarantees and warranties <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuildingInsurance]: (
			<>
				Please upload your buildings insurance policy <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadHomeInsurance]: (
			<>
				Please upload your home insurance policy <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadJapaneseKnotweed]: (
			<>
				Please upload your Japanese knotweed management plan{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.orderManagementPack]: (
			<>
				Please contact your management company to obtain a management pack{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadGroundRent]: (
			<>
				Please upload ground rent invoices for the last 3 years{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadServiceCharge]: (
			<>
				Please upload service charge invoices for the last 3 years{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadFreeholdManagementCompany]: (
			<>
				Please upload the Freehold Management Company's memorandum and articles, share certificate, and latest accounts{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadSewerage]: (
			<>
				Please upload plans showing sewerage location and access{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadPartyWall]: (
			<>
				Please upload party wall notices <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadEICR]: (
			<>
				Please upload the Electrical Installation Condition Report (EICR){' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadGasSafeRegister]: (
			<>
				Please upload the Gas Safe Register <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadNoticeOfSale]: (
			<>
				Please upload any notice from the landlord regarding the sale of the freehold{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadNoticeAboutBuilding]: (
			<>
				Please upload any notices related to the building, its use, condition, or repair{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadLeaseTermChanges]: (
			<>
				Please upload a copy of the lease term changes or any evidence of landlord consent under the lease terms{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadEnfranchisementNotice]: (
			<>
				Please upload the lease extension or freehold purchase notice to the landlord{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadCollectivePurchaseNotice]: (
			<>
				Please upload the collective freehold purchase notice to the landlord{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadLandlordResponseToNotice]: (
			<>
				Please upload the landlord's response to the notice <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuyMortgage]: (
			<>
				Please upload your mortgage offer / offer in principle{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuyRemortgage]: (
			<>
				Please upload your remortgage offer / offer in principle{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuyEquityRelease]: (
			<>
				Please upload your home reversion plan offer or lifetime mortgage offer{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuySavings]: (
			<>
				Please upload bank statements for the last 3 months <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuyInheritance]: (
			<>
				Please upload evidence from the executors of the estate{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuyPropertySale]: (
			<>
				Please upload a copy of the completion statement (if sold) or client care letter (if on sale){' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuyPension]: (
			<>
				Please upload your latest pension statement <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuyGambling]: (
			<>
				Please upload a receipt proving your winnings <UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.uploadBuyCompensation]: (
			<>
				Please upload an official letter confirming your compensation settlement{' '}
				<UploadButton task={task} disabled={uploadButtonDisabled} />
			</>
		),
		[taskType.titlePendingApplications]: (
			<>Request a new copy of the title once all pending applications are completed.</>
		),
	};

	return { description: descriptionByType[task.type] };
};
