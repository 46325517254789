import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { buyerQuestions } from 'src/constants/surveyJson/buyersQuestions';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { selectTransactionParticipants } from 'src/redux/slices/transaction';
import { useMemo } from 'react';
import { QuestionnaireSubmittingError } from 'src/components/QuestionnarieModal/types';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { useQuestionnaire } from '../useQuestionnaire';
import { FinancingAttributesEnum, FinancingSourcesEnum } from './types';
import { DescriptionBlock } from '../styles';

const QuestionnaireBuyerCard = () => {
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const participants = useSelector(selectTransactionParticipants);
	const { onSubmit, onPageChanged, onComplete, isHidden } = useQuestionnaire({
		type: QuestionnaireTransactionEnum.buyer_questions,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	const buyersQuestionnaire = fullQuestionnaire?.buyer_questions;

	const submittingError: QuestionnaireSubmittingError | null = useMemo(() => {
		const hasGiftSource = buyersQuestionnaire.funds?.sources?.includes('gift');

		if (!hasGiftSource) return null;

		const giftors = participants.participants.buyers.filter((b) => b.relationship === 'giftor');
		const areGiftsAdded = giftors.every((g) =>
			buyersQuestionnaire.funds?.gift?.some((gift) => gift.giftors === `${g.givenNames} ${g.lastNames}`),
		);

		if (!giftors.length || !areGiftsAdded) {
			return {
				title: 'MISSING GIFTORS',
				children:
					"You can't submit this questionnaire until all giftors are added. You can save your answers by clicking Continue and return to complete it later. Remember to navigate back to the first page to add any giftors.",
			};
		}

		return null;
	}, [participants.participants, buyersQuestionnaire]);

	if (!fullQuestionnaire || isHidden) return null;

	const completedBuyers = fillQuestionnaireJsonWithCompletedData(
		buyerQuestions(participants.participants, participants.placeholders, fullQuestionnaire),
		buyersQuestionnaire,
	);

	return (
		<>
			<QuestionnarieModal
				initialCurrentPage={buyersQuestionnaire.currentPage}
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				questionnaireKey={QuestionnaireTransactionEnum.buyer_questions}
				surveyJson={completedBuyers}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				status={buyersQuestionnaire.status}
				title='BUYER FINANCE'
				submittingError={submittingError}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title='Buyer Finance'
				type={QuestionnaireTransactionEnum.buyer_questions}
			>
				{({ details }) => {
					const bankAccountsAmount = details?.finance?.bank_accounts?.length;
					const validFinancingAttributesEntries = Object.values(details?.funds?.attributes || {}).filter(
						(attribute) => attribute === 'Yes',
					);

					return (
						<>
							{!!validFinancingAttributesEntries.length && (
								<DescriptionBlock>
									Financing:{' '}
									<b>
										{Object.entries(details?.funds?.attributes || {}).reduce(
											(acc, curr) =>
												curr[1] === 'Yes' ? `${acc}${acc ? ', ' : ''}${FinancingAttributesEnum[curr[0]]}` : acc,
											'',
										)}
									</b>
								</DescriptionBlock>
							)}
							{!!details?.funds?.sources?.length && (
								<DescriptionBlock>
									Source of funds: <b>{details.funds?.sources.map((s) => FinancingSourcesEnum[s]).join(', ')}</b>
								</DescriptionBlock>
							)}
							{details?.funds?.giftors &&
								(details?.funds?.giftors || []).some(({ giftor: { given_names: fn } }) => !!fn) && (
									<DescriptionBlock>
										Giftors:{' '}
										<b>
											{details.funds?.giftors.reduce(
												(acc, { giftor: { given_names: gn, last_names: ln } }) =>
													gn ? `${acc}${acc ? ', ' : ''}${gn} ${ln}` : acc,
												'',
											)}
										</b>
									</DescriptionBlock>
								)}
							{!!bankAccountsAmount && (
								<DescriptionBlock pt='4px'>
									{bankAccountsAmount} bank account{bankAccountsAmount >= 2 && 's'} provided
								</DescriptionBlock>
							)}
						</>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnaireBuyerCard;
