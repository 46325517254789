import { VerificationSession, VerificationSessionDocument } from 'src/types';

export const getNameVerificationDocument = (
	session: VerificationSession,
): VerificationSessionDocument | null | undefined => {
	if (!session.documents) return null;

	const documents = [
		...(session.documents.passports ?? []),
		...(session.documents.drivingLicences ?? []),
		...(session.documents.other ?? []),
	];

	return documents.find((d) => !!d.full_name);
};

export const getAddressVerificationDocument = (
	session: VerificationSession,
): VerificationSessionDocument | null | undefined => {
	if (!session.documents) return null;

	const documents = [...(session.documents.passports ?? []), ...(session.documents.drivingLicences ?? [])];

	const matchingDocument = documents.find((d) => !!d.structured_postal_address);
	let extendedDocument = { ...matchingDocument };

	const otherDocument = session.documents.other?.find((d) => !!d.structured_postal_address);

	if (otherDocument && !matchingDocument) {
		const address = otherDocument.structured_postal_address;
		let street = '';

		if (address && !address?.street && address.building_number) {
			if (address.address_line1 && address.address_line1.includes(address.building_number)) {
				street = address.address_line1.replace(address.building_number, '');
			} else if (address.address_line2 && address.address_line2.includes(address.building_number)) {
				street = address.address_line2.replace(address.building_number, '');
			}
		}

		extendedDocument = {
			...otherDocument,
			structured_postal_address: {
				...address,
				street: address?.street ?? street,
			},
		};
	}

	return extendedDocument as VerificationSessionDocument;
};
