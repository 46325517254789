export const TRANSACTION = {
	name: null,
	askPrice: null,
	generatedForms: [],
	parties: {},
	offers: [],
	askOffers: [],
	property: null,
	questionnaireTransactionRef: null,
	fees: {
		sell: {
			agencyRate: {
				withVat: null,
				withoutVat: null,
			},
			agencyFee: {
				withVat: null,
				withoutVat: null,
			},
			quote: {
				withVat: null,
				withoutVat: null,
			},
			referralFee: {
				withVat: null,
				withoutVat: null,
			},
			legalFee: {
				withVat: null,
				withoutVat: null,
			},
		},
		buy: {
			agencyRate: {
				withVat: null,
				withoutVat: null,
			},
			agencyFee: {
				withVat: null,
				withoutVat: null,
			},
			quote: {
				withVat: null,
				withoutVat: null,
			},
			referralFee: {
				withVat: null,
				withoutVat: null,
			},
			legalFee: {
				withVat: null,
				withoutVat: null,
			},
		},
	},
};

export const TRANSACTION_PARTIE = {
	sell: {},
	buy: {},
	view: {},
};
