import { Box, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { TitleStyled } from 'src/pages/transactions/modals/style';
import { selectModal } from 'src/redux/slices/modal';
import { handleCloseModal } from 'src/utils/propertyHelpers';

const Content = () => {
	const { payload } = useSelector(selectModal<{ failedProperties: string[] }>);

	return (
		<>
			<TitleStyled>Failed properties ids</TitleStyled>
			<Box display='flex' flexDirection='column' gap='7px'>
				{payload.failedProperties.map((p) => (
					<Typography key={p}>{p}</Typography>
				))}
			</Box>
			<Button onClick={handleCloseModal} sx={{ mt: '20px' }} variant='contained'>
				OK
			</Button>
		</>
	);
};

const FailedPatchPropertiesModal = (props) => (
	<Modal name={modals.failedPatchProperties} cardSx={{ maxWidth: '630px', overflow: 'visible' }}>
		<Content {...props} />
	</Modal>
);

export default FailedPatchPropertiesModal;
