import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { selectTransactionParticipants } from 'src/redux/slices/transaction';
import { FC } from 'react';
import { giftorQuestions } from 'src/constants/surveyJson/giftorQuestions';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { useQuestionnaire } from '../useQuestionnaire';
import { GiftorFinanceCardProps } from './types';

export const GiftorFinanceCard: FC<GiftorFinanceCardProps> = ({ participant }) => {
	const key = `giftor_finance_${participant.uid}` as const;

	const participants = useSelector(selectTransactionParticipants);
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);

	const { onPageChanged, onSubmit, onComplete, isHidden } = useQuestionnaire({
		type: key,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	if (!fullQuestionnaire || isHidden) return null;

	const questionnaire = fullQuestionnaire[key];
	const completedQuestionnaire = fillQuestionnaireJsonWithCompletedData(
		giftorQuestions(participants.participants),
		fullQuestionnaire[key],
	);

	return (
		<>
			<QuestionnarieModal
				initialCurrentPage={questionnaire?.currentPage}
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				questionnaireKey={key}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				surveyJson={completedQuestionnaire}
				status={questionnaire?.status ?? QuestionnaireStatus.NOT_STARTED}
				title={`GIFTOR FINANCE (${participant.givenNames} ${participant.lastNames})`}
				isGiftorFinance
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title={`Giftor Finance (${participant.givenNames} ${participant.lastNames})`}
				type={key}
				isGiftorFinance
			/>
		</>
	);
};
