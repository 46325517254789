import { matchIsValidTel } from 'mui-tel-input';
import * as Yup from 'yup';

export const requiredString = Yup.string().required('This field is required');
export const requiredNumber = Yup.number().required('This field is required');
export const emailString = requiredString.email('Email must be a valid email address');
export const phoneNumberString = requiredString.test(
	'isPhoneNumberValid',
	'Number must be valid phone number',
	(value) => matchIsValidTel(value ?? ''),
);
export const passwordString = requiredString.matches(
	/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})^[^ ]+$/,
	'At least 8 characters, 1 uppercase, 1 lowercase & 1 number.',
);
export const nonEmptyString = requiredString.trim().min(1, 'This field must not be empty');
