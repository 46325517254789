import { FC } from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { getFileNameWithoutExtension } from 'src/utils/strings';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import { dispatch } from 'src/redux/store';
import { goInsideDocument } from 'src/redux/slices/documents';
import modals from 'src/constants/modals';
import { openModal } from 'src/redux/slices/modal';
import { DocumentProps } from './types';
import { Actions, EmptyIndicator, File, Folder, Section, Title } from './styled';
import DownloadButton from '../DownloadButton';
import UploadButton from '../UploadButton';
import DocumentMenu from '../DocumentMenu';

const Document: FC<DocumentProps> = (props) => {
	const { type, name, children, isPending, settings, filesCount, id, location, expandSection = true, parent } = props;

	if (type === 'section' && expandSection) {
		return (
			<Section>
				<Box display='flex' gap='5px' alignItems='center'>
					<Title mb='5px'>{name}</Title>
					{settings.upload && <UploadButton folderId={id} location={location} />}
					{settings.download && <DownloadButton {...props} />}
					<DocumentMenu {...props} />
				</Box>
				<Divider />
				{children?.length ? (
					<Box mt='10px' display='flex' gap='10px' flexWrap='wrap'>
						{children.map((child) => (
							<Document key={child.id} {...child} parent={props} expandSection={false} />
						))}
					</Box>
				) : (
					<Typography p='12px 7px 0'>No documents found</Typography>
				)}
			</Section>
		);
	}

	if (type === 'folder' || type === 'section') {
		const handleGoInside = () => dispatch(goInsideDocument(props));

		return (
			<Folder onClick={handleGoInside}>
				<Box display='flex' flexDirection='row' justifyContent='space-between' gap='5px'>
					<Title width='fit-content'>{name}</Title>
					<DocumentMenu {...props} />
				</Box>
				<Actions alignItems='flex-end !important'>
					<Box display='flex' alignItems='center' gap='5px' pb='8px'>
						<Iconify icon='material-symbols:folder' fontSize={21} color={palette.light.warning.light} />
						<Typography variant='caption' fontSize='11px' color='grey.600'>
							{filesCount ? `(${filesCount} files)` : '(empty)'}
						</Typography>
					</Box>
				</Actions>
				{isPending && <EmptyIndicator />}
			</Folder>
		);
	}

	const handleDelete = () => dispatch(openModal({ name: modals.deleteDocument, payload: props }));

	return (
		<File>
			<Title>{getFileNameWithoutExtension(name)}</Title>
			<Actions>
				<Iconify icon='mdi:file' fontSize={21} color={palette.light.primary.light} />
				<Box display='flex' flexDirection='row' gap='2px'>
					{parent?.settings?.delete && (
						<IconButton color='info' onClick={handleDelete}>
							<Iconify icon='material-symbols:delete' fontSize={24} color={palette.light.primary.main} />
						</IconButton>
					)}
					{parent?.settings?.download && <DownloadButton {...props} />}
				</Box>
			</Actions>
		</File>
	);
};

export default Document;
