import type { FC } from 'react';
// @mui
import { Box, Tooltip } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
// guards
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
//
import Iconify from '../../Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './styles';
import type { INavItem } from './types';

// ----------------------------------------------------------------------

const DotIcon: FC<{ active?: boolean }> = ({ active }) => (
	<ListItemIconStyle>
		<Box
			component='span'
			sx={{
				width: 4,
				height: 4,
				borderRadius: '50%',
				bgcolor: 'text.disabled',
				transition: (theme) =>
					theme.transitions.create('transform', {
						duration: theme.transitions.duration.shorter,
					}),
				...(active && {
					transform: 'scale(2)',
					bgcolor: 'primary.main',
				}),
			}}
		/>
	</ListItemIconStyle>
);

const NavItem: FC<INavItem> = ({ item, depth, active, open, isCollapse, onClick, toItemPage, ...other }) => {
	const { translate } = useLocales();

	const { title, icon, info, children, disabled, caption, roles, styles = {} } = item;

	return (
		<RoleBasedGuard roles={roles}>
			<ListItemStyle depth={depth} active={active} disabled={disabled} sx={styles} {...other}>
				{icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}

				{depth !== 1 && <DotIcon active={active && depth !== 1} />}

				<ListItemTextStyle
					onClick={toItemPage}
					isCollapse={isCollapse}
					primary={translate(title, {})}
					secondary={
						caption && (
							<Tooltip title={translate(caption, {})} placement='top-start'>
								<span>{translate(caption, {})}</span>
							</Tooltip>
						)
					}
					primaryTypographyProps={{
						noWrap: true,
						fontWeight: 400,
						variant: active ? 'subtitle2' : 'body2',
					}}
					secondaryTypographyProps={{
						noWrap: true,
						variant: 'caption',
					}}
				/>

				{!isCollapse && (
					<>
						{info && (
							<Box component='span' sx={{ lineHeight: 0 }}>
								{info}
							</Box>
						)}

						{!!children && (
							<Iconify
								onClick={onClick}
								icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
								sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
							/>
						)}
					</>
				)}
			</ListItemStyle>
		</RoleBasedGuard>
	);
};

export default NavItem;
