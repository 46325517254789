import { IQuestionnaireProperty, IQuestionnaireTransaction } from 'src/types';
import { questionnaireFormattedPrefillValues } from './constants';
import {
	QuestionnaireCheckboxElement,
	QuestionnairePage,
	QuestionnairePageElement,
	QuestionnaireRadiogroupElement,
} from './types';

function checkSentenceIncludesExpression(sentence: string, expression: string): boolean {
	const sentenceWords = sentence.toLowerCase().split(' ');
	const expressionWords = (expression || '').toLowerCase().split('_');

	// multiple words matching
	if (expressionWords.length > 1) {
		return expressionWords.every((expressionWord) => sentenceWords.includes(expressionWord));
	}

	return sentenceWords.some((sentenceWord) => expressionWords.includes(sentenceWord));
}

function getQuestionnaireCheckboxChoices(
	page: QuestionnairePage,
	element: QuestionnaireCheckboxElement,
	choices: Array<{ value: string; text: string }> | undefined,
	isSubmitted: boolean,
) {
	const pageSelectedValues = page[element.name] ?? [];
	const elementDefaultValue = isSubmitted ? [] : element.defaultValue ?? [];

	const formattedSelectedValues =
		choices?.reduce((defaultValues, selectedOption) => {
			const optionValue = selectedOption.value;
			const commonValues = page.common;

			const matchingChoice = page[element.name]?.find((item) => [item.text, item.value, item].includes(optionValue));

			if (matchingChoice) defaultValues.push(matchingChoice?.value ?? matchingChoice);
			else if (commonValues) {
				const rawValue: unknown = commonValues[element.name];

				if (commonValues[optionValue]) defaultValues.push(optionValue);

				if (Array.isArray(rawValue)) {
					const formattedValues = rawValue.reduce((acc: string[], item: string) => {
						const formattedValue = questionnaireFormattedPrefillValues[item];

						if (formattedValue) acc.push(formattedValue);

						return acc;
					}, []);

					defaultValues.push(...formattedValues);
				}

				if (typeof rawValue === 'string') {
					const isMatching = checkSentenceIncludesExpression(rawValue, optionValue);

					if (isMatching) defaultValues.push(optionValue);
				}
			}

			return Array.from(new Set(defaultValues));
		}, [] as string[]) ?? [];

	return Array.from(new Set([...pageSelectedValues, ...formattedSelectedValues, ...elementDefaultValue]));
}

export const getRadioGroupWithDefaultValue = (element: QuestionnaireRadiogroupElement, page: QuestionnairePage) => {
	const radiogroupDefaultValue = page[element.name];
	const similiarValue = element.choices.find((item) => {
		if (typeof item === 'string') {
			return item.toLowerCase().startsWith(radiogroupDefaultValue?.toLowerCase());
		}

		return false;
	});

	if (radiogroupDefaultValue) {
		return {
			...element,
			defaultValue: [
				similiarValue ?? questionnaireFormattedPrefillValues[radiogroupDefaultValue] ?? radiogroupDefaultValue,
			],
		};
	}

	return element;
};

export const fillQuestionnaireElementsWithDefaultValues = (
	elements: Array<QuestionnairePageElement> | undefined,
	page: QuestionnairePage,
	isSubmitted: boolean,
) =>
	elements?.map((element) => {
		if (element.skipPrefill) return element;

		switch (element.type) {
			case 'panel':
				return {
					...element,
					elements: fillQuestionnaireElementsWithDefaultValues(element.elements, page, isSubmitted),
				};
			case 'multipletext':
				return {
					...element,
					defaultValue: element.items.reduce((acc, item) => {
						const pageElementDetails = page[element.name];

						if (pageElementDetails) acc[item.name] = pageElementDetails[item.name];

						return acc;
					}, {}),
				};
			case 'matrix':
				if (element.name === 'heating_system') {
					const centralHeatingType = page.common?.central_heating_type as unknown[] | undefined;
					const directHeatingType = page.common?.direct_heating_type as unknown[] | undefined;

					return {
						...element,
						defaultValue: page.heating_system ?? {
							central: centralHeatingType?.length && 'yes',
							direct: directHeatingType?.length && 'yes',
						},
					};
				}

				return {
					...element,
					defaultValue: Object.assign(element.defaultValue ?? {}, page[element.name]),
				};
			case 'tagbox':
				return {
					...element,
					...([...(page[element.name] || element.defaultValue || [])]
						.flat()
						.filter((value) => value !== null && value !== undefined).length !== 0 && {
						defaultValue: Array.from(new Set([...(page[element.name] || element.defaultValue || [])])),
					}),
				};
			case 'checkbox':
				return {
					...element,
					defaultValue: getQuestionnaireCheckboxChoices(page, element, element.choices, isSubmitted),
				};
			case 'radiogroup':
				return getRadioGroupWithDefaultValue(element, page);
			default:
				// eslint-disable-next-line
				const defaultValue: any[] = [];
				defaultValue.push(page[element.name], ...(element.defaultValue ?? []));

				return {
					...element,
					defaultValue: defaultValue.flat().filter((value) => value !== null && value !== undefined),
				};
		}
	});

export const fillQuestionnaireJsonWithCompletedData = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	json: Record<string, any>,
	questionnaire?:
		| IQuestionnaireProperty[keyof IQuestionnaireProperty]
		| IQuestionnaireTransaction[keyof IQuestionnaireTransaction],
) => {
	if (questionnaire) {
		return {
			...json,
			pages:
				json.pages?.map((page: QuestionnairePage) => {
					const completedPage = questionnaire[page.name] || questionnaire;

					return {
						...page,
						elements: fillQuestionnaireElementsWithDefaultValues(
							page?.elements,
							completedPage,
							completedPage.isSubmitted,
						),
					};
				}) ?? [],
		};
	}

	return json;
};
