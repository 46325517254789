import { Alert } from '@mui/material';
import { useState } from 'react';

export default function useError() {
	const [error, setError] = useState<string | null>(null);

	return {
		error: error ? (
			<Alert
				variant='filled'
				severity='error'
				sx={{ fontSize: '13px', borderRadius: '2px', padding: '3px 7px', width: '100%', alignItems: 'center' }}
			>
				{error}
			</Alert>
		) : null,
		setError,
	};
}
