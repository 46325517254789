import { DocumentLocation } from 'src/redux/types/documents';
import { call } from '../@';

export type Payload = {
	file: File;
	folderId: string;
	location: DocumentLocation;
	type: string;
	name: string;
	propertyId: string;
};

export default async function uploadDocument({ file, location, folderId, propertyId }: Omit<Payload, 'type' | 'name'>) {
	return await call('documents-upload', {
		file,
		type: file.type,
		name: file.name,
		location,
		folderId,
		propertyId,
	});
}
