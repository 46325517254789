import { allowedDocumentsFilesExtensions, documentsFilesSettings } from 'src/constants/documents';
import { ProviderContext } from 'notistack';
import { getFileNameExtension } from './strings';

export const validateUploadingFiles = (files: File[], alert: ProviderContext['enqueueSnackbar']) => {
	let isValid = true;

	files.forEach((file) => {
		const extension = getFileNameExtension(file.name);
		const settings = documentsFilesSettings[extension];

		if (!settings) {
			isValid = false;

			alert(<>Unsupported file type. Please use {allowedDocumentsFilesExtensions.join(', ')}.</>, { variant: 'error' });

			return;
		}

		const isValidSize = file.size <= settings.size;

		if (!isValidSize) {
			isValid = false;

			alert(
				<>
					<b>'{file.name}'</b> exceeds the {settings.sizeLabel} size limit for {settings.label} files. Please reduce its
					size or upload a different file.
				</>,
				{ variant: 'error' },
			);
		}
	});

	return isValid;
};
