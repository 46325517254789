import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTracker } from 'src/redux/slices/tracker';
import { useMemo } from 'react';
import { TrackerWrapper } from './styles';
import { ITrackerStep } from '../TrackerStep/types';
import TrackerStep from '../TrackerStep';
import { TrackerStepSeparator } from '../TrackerStep/styles';

const Tracker = () => {
	const tracker = useSelector(selectTracker);

	const steps: Array<Omit<ITrackerStep, 'order'> & { key: string }> = useMemo(() => {
		if (!tracker.data) return [];

		return [
			{
				...tracker.data.sellerDueDiligence,
				key: 'sellerDueDiligence',
				title: (
					<>
						Seller <br /> due diligence
					</>
				),
			},
			{ ...tracker.data.listing, key: 'listing', title: 'Listing' },
			{ ...tracker.data.saleAgreed, key: 'saleAgreed', title: 'Sale agreed' },
			{
				...tracker.data.buyerDueDiligence,
				key: 'buyerDueDiligence',
				title: (
					<>
						Buyer <br /> due diligence
					</>
				),
			},
			{ ...tracker.data.exchange, key: 'exchange', title: 'Exchange' },
			{ ...tracker.data.completion, key: 'completion', title: 'Completion' },
		].sort((a, b) => {
			if (a.key === 'completion') return 1;
			if (b.key === 'completion') return -1;

			if (a.status === 'completed' && !a.date) return b.status === 'completed' && !b.date ? 0 : -1;
			if (b.status === 'completed' && !b.date) return 1;

			if (!a.date) return 1;
			if (!b.date) return -1;

			return a.date.toMillis() - b.date.toMillis();
		});
	}, [tracker.data]);

	if (!tracker.data) return null;

	return (
		<Stack flexDirection='row' m='40px 0 70px 20px'>
			<TrackerWrapper>
				{steps.map((step, index) => (
					<>
						<TrackerStep {...step} order={index + 1} />
						{index + 1 !== steps.length && <TrackerStepSeparator />}
					</>
				))}
			</TrackerWrapper>
		</Stack>
	);
};

export default Tracker;
