import {
	TransactionSummary,
	TransactionSummaryBuySideChecklist,
	TransactionSummaryBuySideChecklistFieldName,
	TransactionSummaryChecklistField,
	TransactionSummarySellSideChecklist,
	TransactionSummarySellSideChecklistFieldName,
} from 'src/types';
import { BuySideChecklistField, Checklist, SellSideChecklistField } from './types';

export const sellSideChecklistFields: TransactionSummarySellSideChecklistFieldName[] = [
	'clientCareLetterSigned',
	'clientsInvited',
	'contractApproved',
	'emailForSearches',
	'enquiriesAnswered',
	'enquiriesReceived',
	'exchangeReady',
	'managementPackAdviceGiven',
	'quoteAccepted',
	'searchesPayment',
	'solicitorAssigned',
	'solicitorClientCareLetter',
	'solicitorConfirmedNoEnquiriesOutstanding',
	'tr13Provided',
	'tr1Received',
];
export const buySideChecklistFields: TransactionSummaryBuySideChecklistFieldName[] = [
	'clientCareLetterSigned',
	'clientsInvited',
	'contractApproved',
	'emailForSearches',
	'enquiriesSent',
	'exchangeReady',
	'quoteAccepted',
	'quoteProvided',
	'searchesPayment',
	'solicitorAssigned',
	'solicitorClientCareLetter',
	'solicitorManagementPack',
	'solicitorReceivedAnswers',
	'solicitorSalePack',
	'solicitorsContact',
	'solicitorSearches',
	'tr1Sent',
];

export const sellSideChecklist: Checklist<SellSideChecklistField> = {
	sections: [
		{
			label: 'Onboarding',
			fields: [
				{ key: 'quoteAccepted', label: 'Quote accepted' },
				{ key: 'clientsInvited', label: 'All clients invited' },
				{ key: 'emailForSearches', label: 'Email requesting payment for searches' },
				{ key: 'searchesPayment', label: 'Searches paid' },
				{ key: 'managementPackAdviceGiven', label: 'Management pack advice given' },
				{ key: 'solicitorAssigned', label: 'Solicitor assigned' },
				{ key: 'solicitorClientCareLetter', label: 'Solicitor sent Client Care Letter' },
				{ key: 'clientCareLetterSigned', label: 'Client Care Letter signed and uploaded' },
			],
		},
		{
			label: 'From the buyside',
			fields: [
				{ key: 'tr1Received', label: 'TR1 received' },
				{ key: 'enquiriesReceived', label: 'Enquiries received' },
				{ key: 'contractApproved', label: 'Contract approved' },
			],
		},
		{
			label: 'To the buyside',
			fields: [
				{ key: 'tr13Provided', label: 'TR13 provided' },
				{ key: 'enquiriesAnswered', label: 'Enquiries answered' },
			],
		},
		{
			label: 'Exchange',
			fields: [
				{ key: 'solicitorConfirmedNoEnquiriesOutstanding', label: 'Solicitor confirmed no enquiries outstanding' },
				{ key: 'exchangeReady', label: 'Exchange ready' },
			],
		},
	],
};

export const buySideChecklist: Checklist<BuySideChecklistField> = {
	sections: [
		{
			label: 'Onboarding',
			fields: [
				{ key: 'quoteProvided', label: 'Quote provided' },
				{ key: 'quoteAccepted', label: 'Quote accepted' },
				{ key: 'clientsInvited', label: 'All clients invited' },
				{ key: 'emailForSearches', label: 'Email requesting payment for searches' },
				{ key: 'searchesPayment', label: 'Searches paid' },
				{ key: 'solicitorAssigned', label: 'Solicitor assigned' },
				{ key: 'solicitorClientCareLetter', label: 'Solicitor sent Client Care Letter' },
				{ key: 'clientCareLetterSigned', label: 'Client Care Letter signed and uploaded' },
			],
		},
		{
			label: 'From the sellside',
			fields: [
				{ key: 'solicitorsContact', label: "Solicitor made contact with sellers' solicitor" },
				{ key: 'solicitorSalePack', label: 'Solicitor received Sale pack' },
				{ key: 'solicitorManagementPack', label: 'Solicitor received Management pack' },
				{ key: 'solicitorSearches', label: 'Solicitor received Searches' },
			],
		},
		{
			label: 'To the sellside',
			fields: [
				{ key: 'tr1Sent', label: 'TR1 sent' },
				{ key: 'enquiriesSent', label: 'Enquiries sent' },
				{ key: 'contractApproved', label: 'Contract approved' },
			],
		},
		{
			label: 'Exchange',
			fields: [
				{ key: 'solicitorReceivedAnswers', label: 'Solicitor received answers to enquiries' },
				{ key: 'exchangeReady', label: 'Exchange ready' },
			],
		},
	],
};

export const emptyTransactionSummaryChecklistField: TransactionSummaryChecklistField = {
	checked: false,
	timestamp: null,
};

export const emptyTransactionSummary: TransactionSummary = {
	buySide: buySideChecklistFields.reduce((acc, key) => {
		acc[key] = emptyTransactionSummaryChecklistField;

		return acc;
	}, {} as TransactionSummaryBuySideChecklist),
	sellSide: sellSideChecklistFields.reduce((acc, key) => {
		acc[key] = emptyTransactionSummaryChecklistField;

		return acc;
	}, {} as TransactionSummarySellSideChecklist),
};
