import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { selectTransactionParticipants } from 'src/redux/slices/transaction';
import { proprietorFinance } from 'src/constants/surveyJson/proprietorFinance';
import { useQuestionnaire } from '../useQuestionnaire';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { DescriptionBlock } from '../styles';

const ProprietorFinanceCard = () => {
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const participants = useSelector(selectTransactionParticipants);
	const { onSubmit, onComplete, onPageChanged } = useQuestionnaire({
		type: QuestionnaireTransactionEnum.proprietor_finance,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	const completedTransactionProprietorFinance = fillQuestionnaireJsonWithCompletedData(
		proprietorFinance(participants.participants),
		fullQuestionnaire?.proprietor_finance,
	);

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				initialCurrentPage={fullQuestionnaire?.proprietor_finance?.currentPage ?? 0}
				questionnaireKey={QuestionnaireTransactionEnum.proprietor_finance}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				surveyJson={completedTransactionProprietorFinance}
				status={fullQuestionnaire?.proprietor_finance?.status ?? QuestionnaireStatus.NOT_STARTED}
				title='FINANCE'
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title='Finance'
				type={QuestionnaireTransactionEnum.proprietor_finance}
			>
				{({ details }) => {
					const bankAccountsAmount = details?.banking?.bank_accounts?.length;

					if (!bankAccountsAmount) return null;

					return (
						<DescriptionBlock>
							{bankAccountsAmount} bank account{bankAccountsAmount >= 2 && 's'} provided
						</DescriptionBlock>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default ProprietorFinanceCard;
