export interface IRatingCard {
	rateRanges: {
		name: string;
		color: string;
		range: [number, number];
	}[];
	title: string;
	maxValueTitle: string;
	minValueTitle: string;
	current?: number | null;
	potential?: number | null;
	status: RatingInspection;
}

export interface RateRange {
	name: string;
	color: string;
	range: [number, number];
}

export interface IRatingLabel {
	color: string;
	value: number;
}

export enum RatingInspection {
	SUCCESS = 'success',
	EXPIRED_SOON = 'expired soon',
	EXPIRED = 'expired',
}
