import type { FC, PropsWithChildren } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import type { ColorType, ILabel, VariantType } from './types';

// ----------------------------------------------------------------------

const RootStyle = styled('span')<{ ownerState: { color: 'default' | ColorType; variant: VariantType } }>(
	({ theme, ownerState }) => {
		const isLight = theme.palette.mode === 'light';
		const { color, variant } = ownerState;

		const styleFilled = (paletteColor: ColorType) => ({
			color: theme.palette[paletteColor].contrastText,
			backgroundColor: theme.palette[paletteColor].main,
		});

		const styleOutlined = (paletteColor: ColorType) => ({
			color: theme.palette[paletteColor].main,
			backgroundColor: 'transparent',
			border: `1px solid ${theme.palette[paletteColor].main}`,
		});

		const styleGhost = (paletteColor: ColorType) => ({
			color: theme.palette[paletteColor][isLight ? 'dark' : 'light'],
			backgroundColor: alpha(theme.palette[paletteColor].main, 0.16),
		});

		return {
			height: 22,
			minWidth: 22,
			borderRadius: 6,
			cursor: 'default',
			alignItems: 'center',
			whiteSpace: 'nowrap',
			display: 'inline-flex',
			justifyContent: 'center',
			padding: theme.spacing(0, 1),
			color: theme.palette.grey[800],
			fontSize: theme.typography.pxToRem(12),
			fontFamily: theme.typography.fontFamily,
			backgroundColor: theme.palette.grey[300],
			fontWeight: theme.typography.fontWeightBold,

			...(color !== 'default'
				? {
						...(variant === 'filled' && { ...styleFilled(color) }),
						...(variant === 'outlined' && { ...styleOutlined(color) }),
						...(variant === 'ghost' && { ...styleGhost(color) }),
				  }
				: {
						...(variant === 'outlined' && {
							backgroundColor: 'transparent',
							color: theme.palette.text.primary,
							border: `1px solid ${theme.palette.grey[500_32]}`,
						}),
						...(variant === 'ghost' && {
							color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
							backgroundColor: theme.palette.grey[500_16],
						}),
				  }),
		};
	},
);

// ----------------------------------------------------------------------

const Label: FC<PropsWithChildren<ILabel>> = ({
	children,
	color = 'default',
	variant = 'ghost',
	startIcon,
	endIcon,
	sx,
	className,
}) => {
	const style = {
		width: 16,
		height: 16,
		'& svg, img': { width: 1, height: 1, objectFit: 'cover' },
	};

	return (
		<RootStyle
			className={className}
			ownerState={{ color, variant }}
			sx={{
				...(startIcon && { pl: 0.75 }),
				...(endIcon && { pr: 0.75 }),
				...sx,
			}}
		>
			{startIcon && <Box sx={{ mr: 0.75, ...style }}>{startIcon}</Box>}

			{children}

			{endIcon && <Box sx={{ ml: 0.75, ...style }}>{endIcon}</Box>}
		</RootStyle>
	);
};

export default Label;
