import { ROOTS_DASHBOARD } from 'src/routes/paths';
import Iconify from 'src/components/Iconify';
import { UserData } from 'src/api/auth/@user-data';

export const getNavConfig = (user: UserData) => {
	if (user.isPrincipal) {
		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: ROOTS_DASHBOARD, icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'MY PROPERTIES',
				items: [],
			},
			{
				subheader: 'MY SALES',
				items: [],
			},
			{
				subheader: 'MY PURCHASES',
				items: [],
			},
		];
	}

	if (user.isEstateAgent) {
		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: ROOTS_DASHBOARD, icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'MY DEALS',
				items: [],
			},
		];
	}

	if (user.isSolicitorConveyancer) {
		return [
			{
				subheader: 'NAVIGATION',
				items: [{ title: 'Dashboard', path: ROOTS_DASHBOARD, icon: <Iconify icon='ph:house' /> }],
			},
			{
				subheader: 'MY CASES',
				items: [],
			},
		];
	}

	return [];
};
