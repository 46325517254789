const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.substring(1);

const transformName = (name: string) => {
	let transformed = name.toLowerCase();
	const separators = ['-', ' ', 'Mc', "'"];

	for (const separator of separators) {
		transformed = transformed.split(separator).map(capitalize).join(separator);
	}

	return transformed;
};

export const formatUserVerificationNames = (givenNames: string, lastNames: string) => ({
	givenNames: transformName(givenNames),
	lastNames: transformName(lastNames),
});
