import { formatBidValue } from './formatBid';

export const validateEmail = (email: string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const parseStringToJson = (content: string) => {
	try {
		return JSON.parse(content);
	} catch {
		return content;
	}
};

export const convertConstructionAgeToYears = (age: string) => age.replace(/England and Wales:/g, '').trim();
export const convertPriceToPricePerPeriod = (price: number, period = 'year') =>
	`£${formatBidValue(price)} per ${period}`;

export const getFileNameWithoutExtension = (name: string) =>
	name.includes('.') ? name.substring(0, name.lastIndexOf('.')) : name;

export const getFileNameExtension = (name: string) => {
	const index = name.lastIndexOf('.');

	return index !== -1 ? name.slice(index) : '';
};
