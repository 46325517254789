/* eslint-disable @typescript-eslint/no-explicit-any */
import { SxProps } from '@mui/system';
import { DocumentReference, GeoPoint, Timestamp } from 'firebase/firestore';
import { AlignType } from 'mdast';
import { ProviderContext } from 'notistack';
import { ReactNode } from 'react';
import { IFindAddressByPostcodeResponse } from 'src/api/epc/epc-types';
import {
	propertyDocumentAvailabilityCodes,
	propertyTitleDocumentsCategories,
} from 'src/components/TransactionSummary/constants';
import { TransactionSummariesFilters } from 'src/pages/dashboard/components/summaries/Toolbar/components/Filters/types';
import { TransactionSummaryColumn } from 'src/pages/dashboard/types/summary';
import { PropertyOnboardingSide } from 'src/pages/properties/types';
import {
	TransactionSide,
	TransactionRole,
	TransactionRelationship,
	TransactionOnboardingType,
	TransactionDocument,
} from 'src/pages/transactions/types';
import { QuestionnaireType } from 'src/redux/types/questionnaire';

export type NotistackAlert = ProviderContext['enqueueSnackbar'];

// HELPERS

export type ObjectWithoutNull<T extends Record<PropertyKey, any>> = {
	[P in keyof T]: null extends T[P] ? Exclude<T[P], null> : T[P];
};

export type SpecificTypeKeys<O extends Record<PropertyKey, unknown>, T> = {
	[K in keyof O]: O[K] extends T ? K : never;
}[keyof O];

// ENUMS

export enum TransactionOfferStatusEnum {
	ACTIVE = 'ACTIVE',
	ACCEPTED = 'ACCEPTED',
	AGREED = 'AGREED',
	REJECTED = 'REJECTED',
	FROZEN = 'FROZEN',
}

export enum LogStatusEnum {
	PENDING = 'PENDING',
	SUCCEED = 'SUCCEED',
	FAILED = 'FAILED',
	SENT = 'SENT',
	COMPLETE = 'COMPLETE',
}

export enum LogTypeEnum {
	EMAIL = 'EMAIL',
	JOB = 'JOB',
	TASK = 'TASK',
}

export enum OnboardingStepStatusEnum {
	PENDING = 'PENDING',
	PASSED = 'PASSED',
}
export enum UserChangeEmailRequestStatusEnum {
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
}

export enum PaymentStatusEnum {
	PENDING = 'PENDING',
	PAID = 'PAID',
	FAILED = 'FAILED',
}

export enum QuestionnaireTypeEnum {
	TRANSACTION = 'transaction',
	PROPERTY = 'property',
}

export enum QuestionnaireStatus {
	DONE = 'Done',
	IN_PROGRESS = 'In progress',
	NOT_STARTED = 'Not Started',
}

export enum UserStatus {
	ACTIVE = 'ACTIVE',
	ONBOARDING = 'ONBOARDING',
	PENDING = 'PENDING',
}

export enum UserOnboardingStatus {
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
}

export enum InvitationStatus {
	ACCEPTED = 'ACCEPTED',
	PENDING = 'PENDING',
	REQUEST = 'REQUEST',
	REJECTED = 'REJECTED',
	OFFER_SENT = 'OFFER_SENT',
}

export enum VerificationSessionStatus {
	PENDING = 'PENDING',
	IN_PROGRESS = 'IN_PROGRESS',
	FAILED = 'FAILED',
	VERIFIED = 'VERIFIED',
	EXPIRED = 'EXPIRED',
	NAME_MISMATCH = 'NAME_MISMATCH',
	ADDRESS_MISMATCH = 'ADDRESS_MISMATCH',
	NAME_ADDRESS_MISMATCH = 'NAME_ADDRESS_MISMATCH',
}

export enum FRoles {
	ESTATE_AGENT = 'Estate Agent',
	BUYER = 'Buyer',
	SELLER = 'Seller',
	BUY_SIDE_SOLICITOR = 'Buy-Side Solicitor',
	SELL_SIDE_SOLICITOR = 'Sell-Side Solicitor',
	ADMIN = 'Admin',
}

export enum FParties {
	BUYER = 'buyer',
	ESTATE_AGENT = 'estate_agent',
	SELLER = 'seller',
	BUY_SIDE_SOLICITOR = 'solicitor_buyside',
	SELL_SIDE_SOLICITOR = 'solicitor_sellside',
}

export enum OwnershipStatus {
	PENDING = 'PENDING',
	VERIFIED = 'VERIFIED',
	FAILED = 'FAILED',
}

export enum EpcLoadingStatus {
	PENDING = 'pending',
	COMPLETED = 'completed',
}

export type FullQuestionnaireByStringType<T extends QuestionnaireType> = T extends 'property'
	? Omit<IExtendedQuestionnaireProperty, 'id'>
	: Omit<IExtendedQuestionnaireTransaction, 'id'>;

export type MaybePromise<T> = T | Promise<T>;

export type TrackerStepStatus = 'notStarted' | 'pending' | 'inProgress' | 'completed' | 'error';
export type TrackerStepName =
	| 'sellerDueDiligence'
	| 'listing'
	| 'saleAgreed'
	| 'buyerDueDiligence'
	| 'exchange'
	| 'completion'
	| 'empty';

export type TrackerStep = {
	status: TrackerStepStatus;
	date: Timestamp | null;
};

// Interfaces

export interface IQuestionnaire {
	status: QuestionnaireStatus;
	currentPage: number;
	isSubmitted?: boolean;
}

export interface IPropertyDetails extends IQuestionnaire {
	built_form?: string;
	rooms?: any;
	conservation_area?: string;
	listed_building?: string;
	dwelling: {
		share_of_freehold_yn?: boolean;
		room_count: number;
	};
	common: {
		construction_age: string | null;
		tenure: string;
		floor_height: string | null;
	};
	property_details: {
		built_form: string;
		tenure?: string;
		property_rooms: IPropertyRoom[];
		property_type: string;
	};
	property_type?: string;
	protected_trees?: string;
	total_floor_area?: number;
}

export interface IPropertyRoom {
	room_qty: number;
	room_type: string;
}

export interface IPropertyEnvironment extends IQuestionnaire {
	flooding: {
		flooded: boolean | null;
		floods_history: string | null;
		flood_type: {
			ground: string | null;
			sewer: string | null;
			surface: string | null;
			coastal: string | null;
			river: string | null;
			other: string | null;
		};
		flood_type_other_desc: string | null;
		flood_report: string | null;
	};

	radon: {
		radon_test: string | null;
		radon_test_yes: { radon_test_safe: boolean | null; radon_test_remedies: boolean | null };
		radon_remedies: string | null;
	};

	japanese_knotweed: { knotweed: string | null; knotweed_plan: string | null; knotweed_plan_note: string | null };
}

export interface IPropertyOwnership extends IQuestionnaire {
	leasehold_page: {
		leasehld: {
			rent_yn: string | null;
			rent_amount: string | null;
			rent_frequency: string | null;
			buildings_insurance_responsibility: string | null;
			building_maintenance_seller: string | null;
			other_charges_yn: boolean | null;
			other_charges_desc: string | null;
		};
	};
	management: any;
	management_page: {
		building_management: {
			landlord_managing_agent_yn: string | null;
			tenants_company_dissolved: string | null;
			managing_agent_daytoday_yn: string | null;
			decoration_facade_year: string | null;
			decoration_communal_year: string | null;
			leasehold_contact_details: {
				landlords_name: string | null;
				landlords_address: string | null;
				landlords_telephone: string | null;
				landlords_email: string | null;
			};
			landlords_managing_agent_details: {
				landlords_agent_name: string | null;
				landlords_agent_address: string | null;
				landlords_agent_telephone: string | null;
				landlords_agent_email: string | null;
			};
			tenants_managing_agent_details: {
				tenants_agent_name: string | null;
				tenants_agent_address: string | null;
				tenants_agent_telephone: string | null;
				tenants_agent_email: string | null;
			};
		};
	};
	charge?: { building_maintenance_arrangements: 'regular_charge' | 'informal_arrangement' };
	ownership_page: {
		tenure_type: string | null;
		freehold_type: string | null;
		leasehold_type: string | null;
		share_of_freehold_yn?: boolean;
	};
}

export interface IPropertyBuildingWorks extends IQuestionnaire {
	green_deal_page: {
		green_deal_yn: null | boolean;
		green_deal_desc: null | string;
		question1: null | string;
	};
	alterations_works: {
		alterations: string | null;
		alterations_use_desc: string | null;
		alterations_use_completed_yn: string | null;
		alterations_use_completed_date: string | null;
		alterations_use_incomplete_desc: string | null;
		alterations_use_consent_yn: string | null;
		alterations_use_consent_note: string;
		alterations_use_consent_desc: string | null;
		alterations_use_landlords_consent: string | null;
		alterations_windows_desc: string | null;
		alterations_windows_completed_yn: string | null;
		alterations_windows_completed_date: string | null;
		alterations_windows_incomplete_desc: string | null;
		alterations_windows_consent_yn: string | null;
		alterations_windows_consent_note: string;
		alterations_windows_consent_desc: string | null;
		alterations_windows_landlords_consent: string | null;
		alterations_extension_desc: string | null;
		alterations_extension_completed_yn: string | null;
		alterations_extension_completed_date: string | null;
		alterations_extension_incomplete_desc: string | null;
		alterations_extension_consent_yn: string | null;
		alterations_extension_consent_note: string;
		alterations_extension_consent_desc: string | null;
		alterations_extension_landlords_consent: string | null;
		alterations_conservatory_desc: string | null;
		alterations_conservatory_completed_yn: string | null;
		alterations_conservatory_completed_date: string | null;
		alterations_conservatory_incomplete_desc: string | null;
		alterations_conservatory_consent_yn: string | null;
		alterations_conservatory_consent_note: string;
		alterations_conservatory_consent_desc: string | null;
		alterations_conservatory_landlords_consent: string | null;
		alterations_loft_desc: string | null;
		alterations_loft_completed_yn: string | null;
		alterations_loft_completed_date: string | null;
		alterations_loft_incomplete_desc: string | null;
		alterations_loft_consent_yn: string | null;
		alterations_loft_consent_note: string;
		alterations_loft_consent_desc: string | null;
		alterations_loft_landlords_consent: string | null;
		alterations_garage_desc: string | null;
		alterations_garage_completed_yn: string | null;
		alterations_garage_completed_date: string | null;
		alterations_garage_incomplete_desc: string | null;
		alterations_garage_consent_yn: string | null;
		alterations_garage_consent_note: string;
		alterations_garage_consent_desc: string | null;
		alterations_garage_landlords_consent: string | null;
		alterations_walls_desc: string | null;
		alterations_walls_completed_yn: string | null;
		alterations_walls_date: string | null;
		alterations_walls_incomplete_desc: string | null;
		alterations_walls_consent_yn: string | null;
		alterations_walls_consent_note: string;
		alterations_other_walls_desc: string | null;
		alterations_walls_landlords_consent: string | null;
		alterations_other_desc: string | null;
		alterations_other_completed_yn: string | null;
		alterations_other_completed_date: string | null;
		alterations_other_incomplete_desc: string | null;
		alterations_other_consent_yn: string | null;
		alterations_other_consent_note: string;
		alterations_other_consent_desc: string | null;
		alterations_other_landlords_consent: string | null;
	};
}

export interface IPropertyServices extends IQuestionnaire {
	services_page: {
		services: string | null;
		electricity_certificate_yn: string | null;
		electricity_certificate_yes_note: string;
		electricity_certificate_no_note: string;
		electricity_provider: string | null;
		electricity_meter_location: string | null;
		gas_certificate_yn: string | null;
		gas_certificate_yes_note: string;
		gas_certificate_no_note: string;
		gas_provider: string | null;
		gas_meter_location: string | null;
		water_provider: string | null;
		water_stopcock_location: string | null;
		water_meter_location: string | null;

		landline_provider: string | null;

		broadband_provider: string | null;

		cable_provider: string | null;
	};
	parking_control?: string;
	parking?: {
		parking_arrangements: string[] | null;
	};
	heating_page: {
		heating_system: {
			central: string | null;
			direct: string | null;
		};
		central_heating_type: string | null;
		furnace_install_date: string | null;
		furnace_working: string | null;
		furnace_service_date: string | null;
		furnace_fuel: string | null;
		boiler_install_date: string | null;
		boiler_working: string | null;
		boiler_service_date: string | null;
		boiler_fuel: string | null;
		heatpump_install_date: string | null;
		heatpump_working: string | null;
		heatpump_service_date: string | null;
		heatpump_fuel: string | null;
		othercentralheating_install_date: string | null;
		othercentralheating_working: string | null;
		othercentralheating_service_date: string | null;
		othercentralheating_fuel: string | null;
		central_heating_means: string | null;
		direct_heating_type: string | null;
	};
	drainage_page: {
		drainage: {
			foul_water: string | null;
			surface_water: string | null;
		};
	};
	sewerage_page: {
		sewerage_type: {
			septic_tank: string | null;
			sewage_plant: string | null;
			cesspool: string | null;
		};
		septic_tank_date: string | null;
		sewerage_shared: string | null;
		sewerage_shared_count: string | null;
		sewerage_emptied_date: string | null;
		sewage_plant_servicing_date: string | null;
		sewerage_install_date: string | null;
		sewerage_outside: string | null;
		sewerage_outside_note: string | null;
	};
	solarpanels: {
		solar_panels_yn: string | null;
		solar_panels_year: string | null;
		solar_panels_owned: string | null;
		solar_panels_lease: string | null;
		solar_panels_lease_note: string;
	};
}
export interface ITransactionMovingOut extends IQuestionnaire {
	status: QuestionnaireStatus;
	moving_out: {
		moving_out_keys: string | null;
		moving_out_care: string | null;
		moving_out_clean: boolean | null;
		moving_out_lights: boolean | null;
	};
	timing_financing: {
		moving_requirements_desc: string | null;
		moving_requirements: boolean | null;
		mortage_sufficient: boolean | null;
		seller_chainboard: boolean | null;
		seller_chainboard_date: string | null;
		seller_chainboard_desc: string | null;
	};
	occupiers: {
		occupiers_tenants_yn: boolean | null;
		occupiers_others_yn: boolean | null;
		occupiers_seller_yn: boolean | null;
		occupiers_others_names: {
			last_names: string | null;
			given_names: string | null;
		}[];
		vacant_posession: boolean | null;
		vacant_posession_occupiers: {
			sign: YesOrNoType | null;
			leave: YesOrNoType | null;
		};
		vacant_posession_occupiers_note: string[];
	};
}

export interface IPropertyGuarantees extends IQuestionnaire {
	guarantees: {
		guarantees_warranties_property: string[] | null;
		guarantees_warranties_note: string | null;
		guarantees_warranties_claims: boolean | null;
		guarantees_warranties_claims_desc: string | null;
	};

	insurance_page: {
		home_insurance: boolean | null;
		home_insurance_desc: string | null;
		buildings_insurance: boolean | null;
		buildings_insurance_issues: {
			abnormal_premium_rise: string | null;
			high_excesses: string | null;
			unusual_conditions: string | null;
			insurance_refused: string | null;
		};
		buildings_insurance_issues_desc: string | null;
		buildings_insurance_claims: boolean | null;
		buildings_insurance_claims_desc: string | null;
	};
}

export interface ITransactionBuyer extends IQuestionnaire {
	ownership: {
		residence_country: string | null;
		other_property_past_yn: boolean | null;
		other_property_future_yn: boolean | null;
		partership: {
			partnered_yn: boolean | null;
			partner_other_property_yn: boolean | null;
		};
		main_dwelling: {
			main_dwelling_sale_yn: boolean | null;
			main_dwelling_sale_details: {
				main_dwelling_solicitor: string | null;
				main_dwelling_address: string | null;
			};
			main_dwelling_sale_past_yn: boolean | null;
			main_dwelling_new_yn: CommonRadioBoolean | null;
		};
	};
	financing: {
		attributes: {
			first_time_buyer: string | null;
			htb_isa: string | null;
			htb_loan: string | null;
			lifetime_isa: string | null;
		};
	};
	property_survey: {
		property_survey: string | null;
		buyer_signature: string | null;
	};
}

type YesOrNoType = 'yes' | 'no';

type CommonRadioBoolean = YesOrNoType | 'not_known';

export interface ITransactionLiabilities extends IQuestionnaire {
	status: QuestionnaireStatus;
	disputes: {
		disputes_complaints_desc: string | null;
		disputes_complaints_future_desc: string | null;
		disputes_complaints_future_yn: boolean | null;
		disputes_complaints_yn: boolean | null;
	};
	notices_proposals: {
		notices_proposals_desc: string | null;
		notices_proposals_yn: boolean | null;
		planned_development_desc: string | null;
		planned_development_yn: boolean | null;
		party_wall_notice_yn: boolean | null;
		party_wall_notice_desc: string | null;
		alterations_use_consent_note: string | null;
	};
	potential_liabilities: {
		consent_breaches_date: string | null;
		consent_breaches_desc: string | null;
		liabilities: {
			consent_breaches: YesOrNoType | null;
			no_consent_work: YesOrNoType | null;
			planning_breaches: YesOrNoType | null;
			planning_issues: YesOrNoType | null;
			unfinished_work: YesOrNoType | null;
		};
		no_consent_work_date: string | null;
		no_consent_work_desc: string | null;
		planning_breaches_date: string | null;
		planning_breaches_desc: string | null;
		planning_issues_date: string | null;
		planning_issues_desc: string | null;
		unfinished_work_date: string | null;
		unfinished_work_desc: string | null;
	};
	enfranchisement_page: {
		enfranchisement: {
			enfranchisement_landlord_notice_note: string | null;
			enfranchisement_landlord_notice_yn: string | null;
			enfranchisement_response_note: string | null;
			enfranchisement_response_yn: string | null;
			enfranchisement_tenants_notice_note: string | null;
			enfranchisement_tenants_notice_yn: string | null;
			enfranchisement_two_years_yn: string | null;
		};
	};
	service_charge_page: {
		service_charge_extra: string | null;
		service_charge_extra_desc: string | null;
		service_charge_issues: string | null;
		service_charge_issues_desc: string | null;
		service_charge_challenge: string | null;
		service_charge_challenge_desc: string | null;
		service_charge_collectionissues: string | null;
		service_charge_collectionissues_desc: string | null;
		service_charge_owed: string | null;
		service_charge_owed_desc: string | null;
		notice_sale_yn: string | null;
		notice_sale_note: string;
		notice_building_yn: string | null;
		notice_building_note: string;
		consent_lease_yn: string | null;
		consent_written_yn: string | null;
		consent_lease_desc: string | null;
		consent_lease_note: string;
		complaint_received_yn: string | null;
		complaint_received_desc: string | null;
		complaint_made_yn: string | null;
		complaint_made_desc: string | null;
	};
}

export interface IPropertyRightsArrangements extends IQuestionnaire {
	drains_and_pipes: {
		shared_infra_active: CommonRadioBoolean | null;
		shared_infra_agreement: CommonRadioBoolean | null;
		shared_infra_agreement_desc: string | null;
		shared_infra_passive: CommonRadioBoolean | null;
	};
	rights_arrangements: {
		access_right: boolean | null;
		access_right_challenged: boolean | null;
		access_right_challenged_desc: string | null;
		access_right_desc: string | null;
		arrangements: {
			chancel: CommonRadioBoolean | null;
			mining: CommonRadioBoolean | null;
			things: CommonRadioBoolean | null;
		};
		arrangements_desc: string | null;
		joint_services: boolean | null;
		joint_services_desc: string | null;
		other_rights: boolean | null;
		other_rights_desc: string | null;
		rights: {
			customary: CommonRadioBoolean | null;
			light: CommonRadioBoolean | null;
			support: CommonRadioBoolean | null;
		};
		rights_nearby: boolean | null;
		rights_nearby_desc: string | null;
	};
}

export interface IQuestionnaireProperty {
	property_details: IPropertyDetails;
	environment: IPropertyEnvironment;
	ownership: IPropertyOwnership;
	building_works: IPropertyBuildingWorks;
	rights_arrangements: IPropertyRightsArrangements;
	services: IPropertyServices;
	guarantees_insurance: IPropertyGuarantees;
	buyer_questions: IPropertyGuarantees;
}

export interface IExtendedQuestionnaireProperty extends IQuestionnaireProperty {
	id: string;
	property: DocumentReference<Property>;
}

export interface ITransactionSellContent extends IQuestionnaire {
	contents_selling: {
		items_for_sale: {
			room_type: null | string;
			price: null | number;
		}[];
	};
}

export interface ITransactionFinance extends IQuestionnaire {
	banking: {
		isSubmitted: boolean;
		banks: Array<{
			account: {
				account_holder: string;
				account_number: number;
				bank: string;
				sort_code: string;
			};
		}>;
	};
	ownership?: {
		ownership_type: string;
	};
}

export interface IPersonalQuestionnaire extends IQuestionnaire {
	correspondence: any;
	contact: any;
	tax: any;
}

export interface IQuestionnaireTransaction {
	liabilities: ITransactionLiabilities;
	moving_out: ITransactionMovingOut;
	moving_in?: any;
	buyer_questions: any;
	fitting_contents: any;
	personal_details?: Record<string, { personal: IPersonalQuestionnaire }>;
	sell_content?: ITransactionSellContent;
	seller_finance: ITransactionFinance;
	proprietor_finance: any;
}

export interface IExtendedQuestionnaireTransaction extends IQuestionnaireTransaction {
	id: string;
	transaction: DocumentReference<Transaction>;
}

export interface VerificationSessionDocument {
	id: string;
	given_names?: string;
	family_name?: string;
	full_name?: string;
	structured_postal_address?: {
		postal_code?: string;
		country?: string;
		state?: string;
		town_city?: string;
		street?: string;
		building_number?: string;
		address_line1?: string;
		address_line2?: string;
	};
}

export interface VerificationSession extends RVerificationSession {
	userRef: DocumentReference<User>;
	retries: number;
	manualGivenNames?: string;
	manualFamilyNames?: string;
	requestedBuildingNumber?: string;
	requestedCity?: string;
	requestedCountry?: string;
	requestedPostcode?: string;
	requestedState?: string;
	requestedStreet?: string;
	documents?: {
		passports?: VerificationSessionDocument[];
		drivingLicences?: VerificationSessionDocument[];
		other?: VerificationSessionDocument[];
	};
}

export interface Payment {
	status: PaymentStatusEnum;
	userRef?: DocumentReference<User>;
	propertyRef?: DocumentReference<Property>;
	transactionRef?: DocumentReference<Property>;
	paymentUrl: string;
}

export interface StateRegions extends RStateRegions {
	country: DocumentReference<RCountry>;
}

export interface TownCities extends RTownCities {
	stateRegion: DocumentReference<StateRegions>;
}

export interface Branch extends RBranch {
	countyProvince: string;
	townCity: DocumentReference<TownCities>;
	country: DocumentReference<RCountry>;
	stateRegion: DocumentReference<StateRegions>;
}

export interface Business extends RBusiness {
	branches: DocumentReference<Branch>[];
	hasSalesBranches: boolean;
	authorisationType: string;
}

export interface PropertyAddress
	extends Omit<
		IFindAddressByPostcodeResponse,
		'uprn' | 'source' | 'date' | 'latitude' | 'longitude' | 'town_city' | 'display_address'
	> {
	coordinates: GeoPoint;
	townCity: string;
	displayAddress: string;
}

export interface Property extends RProperty {
	proprietors: DocumentReference<User>[];
	viewers: DocumentReference<User>[];
	epcReference: DocumentReference<Epc>;
	transaction?: DocumentReference<Transaction>;
	questionnaireProperty: DocumentReference;
	documentsRef?: DocumentReference;
	address: PropertyAddress;
}

export type PropertyTitleTenure =
	| 'leasehold'
	| 'freehold'
	| 'other'
	| 'commonhold'
	| 'feuhold'
	| 'mixed'
	| 'unknown'
	| 'unavailable'
	| 'cautionAgainstFirstRegistration'
	| 'rentCharge'
	| 'franchise'
	| 'manor'
	| 'profitAPrendreInGross';

export interface PropertyTitle {
	number: string;
	tenure: PropertyTitleTenure;
	address: string;
}

export type PropertyDocumentAvailabilityCode =
	(typeof propertyDocumentAvailabilityCodes)[keyof typeof propertyDocumentAvailabilityCodes];

export interface PropertyDocument {
	type: string;
	type_code: string;
	availability: string;
	availability_code: PropertyDocumentAvailabilityCode;
	backdated: boolean;
	date?: string;
	entry_numbers?: string[];
}

export type PropertyTitleCategory =
	(typeof propertyTitleDocumentsCategories)[keyof typeof propertyTitleDocumentsCategories];

export interface PropertyTitleDocuments extends Record<PropertyTitleCategory, PropertyDocument[]> {
	title: string;
}

export interface PropertyTitlesDocuments {
	titles: PropertyTitleDocuments[];
}

export type PropertyListing = RPropertyListing & Record<string, { addedOn: Timestamp }>;

export type PropertyAskPrice = RPropertyAskPrice & {
	timestamp: Timestamp;
};
export type PropertyPrice = RPropertyPrice & Record<string, { date: Timestamp }>;

export type UserOnboardingStep = keyof RUserOnboarding;
export type UserOnboarding = RUserOnboarding & {
	status: UserOnboardingStatus;
};

export interface PropertyOnboarding {
	userRef: DocumentReference<User>;
	propertyRef: DocumentReference<Property>;
	transactionRef?: DocumentReference<Transaction>;
	selectedTitle?: PropertyTitle;
	activeSide?: PropertyOnboardingSide;
	ownership: OnboardingStepStatusEnum;
	payment: OnboardingStepStatusEnum;
	verificationIntroduction: OnboardingStepStatusEnum;
	verificationRequest: OnboardingStepStatusEnum;
	verification: OnboardingStepStatusEnum;
	verificationInProgress: OnboardingStepStatusEnum;
	verificationFail: OnboardingStepStatusEnum;
	nameConfirmation: OnboardingStepStatusEnum;
	nameIssue: OnboardingStepStatusEnum;
	addressConfirmation: OnboardingStepStatusEnum;
	addressIssue: OnboardingStepStatusEnum;
	verificationIssue: OnboardingStepStatusEnum;
	titleIntroduction: OnboardingStepStatusEnum;
	titleSelection: OnboardingStepStatusEnum;
	titleConfirmation: OnboardingStepStatusEnum;
	titleHelp: OnboardingStepStatusEnum;
	proprietorshipMatch: OnboardingStepStatusEnum;
	proprietorshipMatchIssue: OnboardingStepStatusEnum;
	relationship: OnboardingStepStatusEnum;
	completion: OnboardingStepStatusEnum;
	introduction: OnboardingStepStatusEnum;
}

export interface ITransactionPartie {
	addedBy: DocumentReference<User>;
	approved: boolean;
	relationship?: TransactionRelationship;
}

export interface IUserTransactionPartie extends ITransactionPartie {
	user: DocumentReference<User>;
	role: TransactionRole;
}

export type TransactionParties = {
	[userId: string]: Record<TransactionSide, Record<TransactionRole, ITransactionPartie>>;
};

export interface Transaction extends RTransaction {
	property: DocumentReference<Property>;
	parties: TransactionParties;
	askOffers: DocumentReference<TransactionOffer>[];
	offers: DocumentReference<TransactionOffer>[];
	agreedOffer?: DocumentReference<TransactionOffer>;
	questionnaireTransactionRef: DocumentReference;
	tracker?: DocumentReference<Tracker>;
}

export interface TransactionOnboarding {
	type?: TransactionOnboardingType;
	userRef: DocumentReference<User>;
	transactionRef: DocumentReference<Property>;
	selectedTitle?: PropertyTitle;
	titlesExtractionError?: string;
	verificationIntroduction?: OnboardingStepStatusEnum;
	verification?: OnboardingStepStatusEnum;
	verificationInProgress?: OnboardingStepStatusEnum;
	verificationFail?: OnboardingStepStatusEnum;
	nameConfirmation: OnboardingStepStatusEnum;
	nameIssue: OnboardingStepStatusEnum;
	addressConfirmation: OnboardingStepStatusEnum;
	addressIssue: OnboardingStepStatusEnum;
	successfulVerification: OnboardingStepStatusEnum;
	title?: OnboardingStepStatusEnum;
	titleConfirmation?: OnboardingStepStatusEnum;
	titleHelp?: OnboardingStepStatusEnum;
	titleError?: OnboardingStepStatusEnum;
	completion?: OnboardingStepStatusEnum;
	verificationIssue?: OnboardingStepStatusEnum;
}

export interface UserChangeEmailRequest {
	userRef: DocumentReference<User>;
	email: string;
	id: string;
	status: UserChangeEmailRequestStatusEnum;
}

export type RegisterProprietors = { individual: Array<{ givenNames: string; lastNames: string }> };

export type Register = Record<
	string,
	{
		title: string;
		tenure: string;
		proprietors: RegisterProprietors;
	}
> & {
	title: string;
	tenure: PropertyTitleTenure;
};

export type TransactionSummaryChecklistField = { checked: boolean; timestamp: Timestamp | null };

export type TransactionSummarySellSideChecklistFieldName =
	| 'quoteAccepted'
	| 'clientsInvited'
	| 'emailForSearches'
	| 'searchesPayment'
	| 'managementPackAdviceGiven'
	| 'solicitorAssigned'
	| 'solicitorClientCareLetter'
	| 'clientCareLetterSigned'
	| 'tr1Received'
	| 'enquiriesReceived'
	| 'contractApproved'
	| 'tr13Provided'
	| 'enquiriesAnswered'
	| 'solicitorConfirmedNoEnquiriesOutstanding'
	| 'exchangeReady';
export type TransactionSummaryBuySideChecklistFieldName =
	| 'quoteProvided'
	| 'quoteAccepted'
	| 'clientsInvited'
	| 'emailForSearches'
	| 'searchesPayment'
	| 'solicitorAssigned'
	| 'solicitorClientCareLetter'
	| 'clientCareLetterSigned'
	| 'solicitorsContact'
	| 'solicitorSalePack'
	| 'solicitorManagementPack'
	| 'solicitorSearches'
	| 'tr1Sent'
	| 'enquiriesSent'
	| 'contractApproved'
	| 'solicitorReceivedAnswers'
	| 'exchangeReady';

export type TransactionSummaryChecklistFieldName =
	| TransactionSummaryBuySideChecklistFieldName
	| TransactionSummarySellSideChecklistFieldName;

export type TransactionSummarySellSideChecklist = Record<
	TransactionSummarySellSideChecklistFieldName,
	TransactionSummaryChecklistField
>;
export type TransactionSummaryBuySideChecklist = Record<
	TransactionSummaryBuySideChecklistFieldName,
	TransactionSummaryChecklistField
>;

export type TransactionSummary = {
	sellSide: TransactionSummarySellSideChecklist;
	buySide: TransactionSummaryBuySideChecklist;
};

export type LogEvent =
	| 'INITIAL_INVITATION'
	| 'RESEND_INVITATION'
	| 'PENDING_INVITATION'
	| 'REGISTRATION'
	| 'RESET_PASSWORD'
	| 'TITLE_EXTRACTION'
	| 'TITLE_SELECTION'
	| 'NEW_PROPERTY'
	| 'NEW_TRANSACTION'
	| 'INVITATION'
	| 'PRINCIPAL_PATH'
	| 'CHANGE_EMAIL_REQUEST'
	| 'NEW_USER'
	| 'VERIFICATION'
	| 'PENDING_VERIFICATION'
	| 'SELL_SIDE_PARTICIPANTS_ONBOARDED'
	| 'SELL_PACK_HANDOVER'
	| 'SELL_SIDE_COMPLETE'
	| 'BUY_SIDE_COMPLETE'
	| 'BUY_SIDE_PARTICIPANTS_ONBOARDED'
	| 'PENDING_SELLER_QUESTIONS'
	| 'PENDING_BUYER_QUESTIONS'
	| 'AUTOMATION'
	| 'MANUAL_TRIGGER'
	| 'GET_VERIFIED'
	| 'CONFIRM_VERIFICATION_RESULTS'
	| 'ENTER_PERSONAL_DETAILS'
	| 'COMPLETE_SELL_QUESTIONS'
	| 'COMPLETE_BUY_QUESTIONS'
	| 'UPLOAD_FLOOR_PLAN'
	| 'UPLOAD_PROTECTED_BUILDING'
	| 'UPLOAD_PROTECTED_TREES'
	| 'UPLOAD_ALTERATIONS_USE'
	| 'UPLOAD_ALTERATIONS_WINDOWS'
	| 'UPLOAD_ALTERATIONS_EXTENSION'
	| 'UPLOAD_ALTERATIONS_CONSERVATORY'
	| 'UPLOAD_LOFT_CONVERSION'
	| 'UPLOAD_GARAGE_CONVERSION'
	| 'UPLOAD_INTERNAL_WALLS'
	| 'UPLOAD_OTHER_WORKS'
	| 'UPLOAD_HOME_WARRANTY'
	| 'UPLOAD_UNDERPINNING_WARRANTY'
	| 'UPLOAD_CENTRAL_HEATING_WARRANTY'
	| 'UPLOAD_ROOFING_WARRANTY'
	| 'UPLOAD_ELECTRICAL_WORK_WARRANTY'
	| 'UPLOAD_WINDOWS_WARRANTY'
	| 'UPLOAD_TIMBER_TREATMENT_WARRANTY'
	| 'UPLOAD_DAMP_ROOFING_WARRANTY'
	| 'UPLOAD_OTHER_WARRANTIES'
	| 'UPLOAD_BUILDING_INSURANCE'
	| 'UPLOAD_HOME_INSURANCE'
	| 'UPLOAD_JAPANESE_KNOTWEED'
	| 'UPLOAD_SELL_BANK_STATEMENTS'
	| 'UPLOAD_SELL_MORTGAGE_STATEMENT'
	| 'ORDER_MANAGEMENT_PACK'
	| 'UPLOAD_GROUND_RENT'
	| 'UPLOAD_SERVICE_CHARGE'
	| 'UPLOAD_FREEHOLD_MANAGEMENT_COMPANY'
	| 'UPLOAD_SEWERAGE'
	| 'UPLOAD_PARTY_WALL'
	| 'UPLOAD_EICR'
	| 'UPLOAD_GAS_SAFE_REGISTER'
	| 'UPLOAD_NOTICE_OF_SALE'
	| 'UPLOAD_NOTICE_ABOUT_BUILDING'
	| 'UPLOAD_LEASE_TERM_CHANGES'
	| 'UPLOAD_ENFRANCHISEMENT_NOTICE'
	| 'UPLOAD_COLLECTIVE_PURCHASE_NOTICE'
	| 'UPLOAD_LANDLORD_RESPONSE_TO_NOTICE'
	| 'UPLOAD_BUY_MORTGAGE'
	| 'UPLOAD_BUY_REMORTGAGE'
	| 'UPLOAD_BUY_EQUITY_RELEASE'
	| 'UPLOAD_BUY_SAVINGS'
	| 'UPLOAD_BUY_GIFT'
	| 'UPLOAD_BUY_INHERITANCE'
	| 'UPLOAD_BUY_PROPERTY_SALE'
	| 'UPLOAD_BUY_PENSION'
	| 'UPLOAD_BUY_GAMBLING'
	| 'UPLOAD_BUY_COMPENSATION'
	| 'UPLOAD_BUY_BANK_STATEMENTS'
	| 'KICK_OFF'
	| 'SELLER_CLIENT_CARE'
	| 'BUYER_CLIENT_CARE'
	| 'PENDING_GIFTOR_QUESTIONS'
	| TransactionDocument;

export type JobLogItem = {
	message: string;
	severity: 'DEFAULT' | 'ERROR';
	timestamp: string;
};

export type EmailLogMetadata = { subject: string; to: string | string[]; cc?: string[]; bcc?: string[] };
export type JobLogMetadata = { forms: string[]; task?: string; logs?: JobLogItem[]; documents?: TransactionDocument[] };
export type TaskLogMetadata = { transactionRef: DocumentReference };

export type LogMetadata = EmailLogMetadata | JobLogMetadata;

export type LogMetadataByType = {
	[LogTypeEnum.EMAIL]: EmailLogMetadata;
	[LogTypeEnum.JOB]: JobLogMetadata;
	[LogTypeEnum.TASK]: TaskLogMetadata;
};

export type Log<T extends LogTypeEnum = LogTypeEnum> = {
	id: string;
	status: LogStatusEnum;
	type: T extends LogTypeEnum ? T : LogTypeEnum;
	event: LogEvent;
	createdAt: string;
	finishedAt?: string;
	metadata: T extends LogTypeEnum ? LogMetadataByType[T] : LogMetadata;
};

export interface TransactionInvitation extends RTransactionInvitation {
	user: DocumentReference<User>;
	invitingUser: DocumentReference<User>;
	transaction: DocumentReference<Transaction>;
}

export interface Invitation extends RInvitation {
	user: DocumentReference<User>;
	invitingUser: DocumentReference<User>;
	transaction: DocumentReference<Transaction>;
}

export interface TransactionOffer extends RTransactionOffer {
	transaction: DocumentReference<Transaction>;
	status: TransactionOfferStatusEnum;
	toUser?: DocumentReference<User>;
	fromUser: DocumentReference<User>;
	agreedBy: DocumentReference<User>[];
	viewedBy: DocumentReference<User>[];
	toOffer?: DocumentReference<TransactionOffer>;
	timestamp: Timestamp;
}

export type Tracker = {
	[key in TrackerStepName]: TrackerStep;
} & {
	transaction: DocumentReference<Transaction>;
	nextAction: string;
	notes?: string;
};

export interface UserSettings {
	summariesFilters: TransactionSummariesFilters;
	summariesColumns: TransactionSummaryColumn[];
	pinnedTransactions: DocumentReference<Transaction>[];
	pinnedProperties: DocumentReference<Property>[];
}

export interface UserAddress {
	country: DocumentReference<RCountry>;
	county?: string;
	street?: string;
	state?: string;
	postcode?: string;
	city?: string;
	formattedAddress?: string;
}

export interface User<T = RBusiness> extends RFullUserWithoutReferences {
	role: DocumentReference<RRole> | null;
	professions: DocumentReference[];
	verificationStatus?: VerificationSessionStatus;
	country: DocumentReference<RCountry>;
	branch: DocumentReference<Branch>;
	business: DocumentReference<T>;
	tracker: DocumentReference<Tracker>;
	settings?: UserSettings;
	isAddressLocked?: boolean;
	isNameLocked?: boolean;
}

export type EpcPropertyType = 'Flat' | 'Maisonette';

export interface Epc {
	current_energy_efficiency: number;
	potential_energy_efficiency: number;
	environment_impact_current: number;
	environment_impact_potential: number;
	inspection_date: Timestamp;
	property_type: EpcPropertyType;
	built_form?: string;
	construction_age_band?: string;
	floor_level?: string;
	total_floor_area?: number;
	floor_height?: number;
	number_habitable_rooms?: number;
	extension_count?: number;
	flat_top_storey?: 'N' | 'Y';
	flat_storey_count?: number;
	number_open_fireplaces?: number;
	floor_description?: string;
	walls_description?: string;
	roof_description?: string;
	windows_description?: string;
	mainheat_description?: string;
	mainheatcont_description?: string;
	lighting_description?: string;
	hotwater_description?: string;
	mechanical_ventilation?: string;
	hot_water_cost_current?: number;
	heating_cost_current?: number;
	lighting_cost_current?: number;
}

export interface ITableLabel {
	id: string;
	label?: string;
	minWidth?: string;
	width?: string;
	align?: AlignType | 'inherit' | 'justify';
}

export interface TableColumn<T = any> {
	key: string;
	label: string;
	width?: number;
	sortable?: boolean;
	sx?: SxProps;
	getContent: (row: T) => ReactNode;
}
