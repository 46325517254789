import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CircularLoader } from 'src/components/CircularLoader';
import QuestionnaireBuildingWorksCard from 'src/components/cards/QuestionnaireBuildingWorksCard/QuestionnaireBuildingWorksCard';
import QuestionnaireEnvironmentCard from 'src/components/cards/QuestionnaireEnvironmentCard/QuestionnaireEnvironmentCard';
import QuestionnaireGuaranteeCard from 'src/components/cards/QuestionnaireGuaranteeCard/QuestionnaireGuaranteeCard';
import QuestionnaireOwnershipCard from 'src/components/cards/QuestionnaireOwnershipCard/QuestionnaireOwnershipCard';
import QuestionnairePropertyCard from 'src/components/cards/QuestionnairePropertyDetailsCard/QuestionnairePropertyCard';
import QuestionnaireRightsCard from 'src/components/cards/QuestionnaireRightsCard/QuestionnaireRightsCard';
import QuestionnaireServicesCard from 'src/components/cards/QuestionnaireServicesCard/QuestionnaireServicesCard';
import { getPropertyQuestionnaire, selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import { dispatch } from 'src/redux/store';
import { selectPermissions } from 'src/redux/slices/permissions';
import { PropertyQuestionnariesWrapper } from './styled';
import { IPropertyQuestionnaires } from './types';

const PropertyQuestionnaires: React.FC<IPropertyQuestionnaires> = ({ propertyId }) => {
	const { loading, fullQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const { questionnaire } = useSelector(selectPermissions);
	const { id = '' } = useParams();

	useEffect(() => {
		dispatch(getPropertyQuestionnaire({ id: propertyId ?? id }));
	}, [id, propertyId]);

	return (
		<PropertyQuestionnariesWrapper>
			{loading || !fullQuestionnaire || !questionnaire.isInitialized ? (
				<CircularLoader />
			) : (
				<>
					<QuestionnairePropertyCard />
					<QuestionnaireOwnershipCard />
					<QuestionnaireServicesCard />
					<QuestionnaireEnvironmentCard />
					<QuestionnaireBuildingWorksCard />
					<QuestionnaireRightsCard />
					<QuestionnaireGuaranteeCard />
				</>
			)}
		</PropertyQuestionnariesWrapper>
	);
};

export default PropertyQuestionnaires;
