import { addDoc, updateDoc } from 'firebase/firestore';

import { transactionSummariesCollection } from 'src/constants/firestore';
import { TransactionSummary } from 'src/types';
import { getTransactionRef, getTransactionSummaryRef } from 'src/utils/firebase';

export type UpdateTransactionSummaryParams = {
	transactionId: string;
	summaryId?: string;
	data: TransactionSummary;
};

export const updateTransactionSummary = async ({ data, transactionId, summaryId }: UpdateTransactionSummaryParams) => {
	if (summaryId) {
		await updateDoc(getTransactionSummaryRef(summaryId), data);

		return;
	}

	const transactionRef = getTransactionRef(transactionId);
	const ref = await addDoc(transactionSummariesCollection, data);

	await updateDoc<unknown>(transactionRef, { summary: ref });
};
