import moment from 'moment-timezone';
import { Log, LogTypeEnum, TableColumn } from 'src/types';
import { checkIsLogSpecificType } from 'src/utils/log';
import { labelByLogEvent, labelByLogStatus, labelByLogType } from '../TransactionSummary/constants';

export const columns: TableColumn<Log>[] = [
	{
		key: 'timestamp',
		label: 'Timestamp',
		sortable: true,
		width: 175,
		getContent: (log) => moment(log.createdAt).utc().format('YYYY-MM-DD hh:mmA z'),
	},
	{
		key: 'type',
		label: 'Event Type',
		width: 125,
		sortable: true,
		getContent: (log) => labelByLogType[log.type],
	},
	{
		key: 'event',
		label: 'Event Name',
		width: 200,
		sortable: true,
		getContent: (log) => labelByLogEvent[log.event] ?? log.event,
	},
	{
		key: 'status',
		label: 'Status',
		width: 100,
		sortable: true,
		getContent: (log) => labelByLogStatus[log.status],
	},
	{
		key: 'subject',
		width: 275,
		sortable: true,
		label: 'Subject',
		getContent: (l) => (checkIsLogSpecificType(l, LogTypeEnum.EMAIL) ? l.metadata.subject : ''),
	},
	{
		key: 'to',
		width: 150,
		sortable: true,
		label: 'To',
		getContent: (l) =>
			checkIsLogSpecificType(l, LogTypeEnum.EMAIL)
				? typeof l.metadata.to === 'string'
					? l.metadata.to
					: l.metadata.to.join(', ')
				: '',
		sx: { wordBreak: 'break-all' },
	},
	{
		key: 'cc',
		width: 150,
		label: 'CC',
		getContent: (l) => (checkIsLogSpecificType(l, LogTypeEnum.EMAIL) ? l.metadata.cc?.join(', ') : ''),
		sx: { wordBreak: 'break-all' },
	},
];
