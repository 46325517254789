import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getDoc, onSnapshot } from 'firebase/firestore';
import { RatingCard } from 'src/components/RatingCard';
import { getProperty, getPropertyByTransactionId, selectPropertySlice } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import { Epc } from 'src/types';
import { Box } from '@mui/material';
import { getPropertyRef } from 'src/utils/firebase';
import { noop } from 'lodash';
import { GeneralAddress } from './GeneralAddress';
import { rangeEnergy, rangeEnvironment } from './localConstants';
import { CardsWrapper } from './styled';
import { IGeneralTab } from './types';
import Summary from './Summary';
import { RatingInspection } from '../RatingCard/types';
import { FloorPlansSection, PhotosSection, PricesSection, TasksSection, TrackerSection } from './components';
import { PhotosCarouselModal } from '../modals';
import Progress from '../Progress';
import BuiltFormSection from './components/BuiltFormSection';
import MaterialsSection from './components/MaterialsSection';
import UtilitiesSection from './components/UtilitiesSection';
import LocalAuthoritySection from './components/LocalAuthoritySection';

const GeneralTab: FC<IGeneralTab> = ({ isProperty }) => {
	const { id: propOrTrsnId = '' } = useParams();
	const [epc, setEpc] = useState<Epc | undefined>();
	const [epcStatus, setEpcStatus] = useState<RatingInspection>(RatingInspection.SUCCESS);
	const { property, isLoading } = useSelector(selectPropertySlice);

	useEffect(() => {
		if (!isProperty) dispatch(getPropertyByTransactionId({ id: propOrTrsnId, withLoading: isLoading }));
	}, [propOrTrsnId]);

	useEffect(() => {
		const setup = async () => {
			if (!property?.epcReference) return;

			const epcData = (await getDoc(property.epcReference))?.data();

			if (epcData) {
				setEpc(epcData);
				const now = Date.now();
				const inspectionDate = epcData.inspection_date.toDate();
				inspectionDate.setFullYear(inspectionDate.getFullYear() + 10);
				const inspectionDateTime = inspectionDate.getTime();

				if (inspectionDateTime - now < 0) {
					setEpcStatus(RatingInspection.EXPIRED);
					return;
				}

				inspectionDate.setMonth(inspectionDate.getMonth() - 3);
				if (inspectionDateTime - now < 0) setEpcStatus(RatingInspection.EXPIRED_SOON);
			}
		};

		setup();
	}, [property?.epcReference]);
	useEffect(() => {
		if (!property?.id) return noop;

		const unsubscribe = onSnapshot(getPropertyRef(property.id), (snapshot) => {
			dispatch(getProperty({ id: snapshot.id }));
		});

		return () => unsubscribe();
	}, [property?.id]);

	if (isLoading || !property) {
		return (
			<Box height='100%' width='100%'>
				<Progress />
			</Box>
		);
	}

	return (
		<Box pt='30px'>
			{!isProperty && <TrackerSection />}
			<CardsWrapper>
				<GeneralAddress />
				<Summary />
				{!isProperty && <TasksSection />}
				{property.dataExtraction?.stages?.epc && (
					<>
						<BuiltFormSection />
						<MaterialsSection />
						<UtilitiesSection />
						<LocalAuthoritySection />
					</>
				)}
				{isProperty && <PhotosSection />}
				{isProperty && <FloorPlansSection />}
				<RatingCard
					title='Energy Efficiency Rating'
					maxValueTitle='Very energy efficient - lower running costs'
					minValueTitle='Not energy efficient - higher running costs'
					rateRanges={rangeEnergy}
					current={epc?.current_energy_efficiency}
					potential={epc?.potential_energy_efficiency}
					status={epcStatus}
				/>
				<RatingCard
					title='Environmental Impact (CO₂) Rating'
					maxValueTitle='Very environmentally friendly - lower CO₂ emissions'
					minValueTitle='Not environmentally friendly - higher CO₂ emissions'
					rateRanges={rangeEnvironment}
					current={epc?.environment_impact_current}
					potential={epc?.environment_impact_potential}
					status={epcStatus}
				/>
				{isProperty && <PricesSection />}
			</CardsWrapper>

			<PhotosCarouselModal />
		</Box>
	);
};

export default GeneralTab;
