export const rights = {
	logoWidth: '100px',
	logoHeight: '100px',
	logoPosition: 'right',
	completedHtml: "<h3>That's it! Thank you for completing the Rights and Arrangements card! 🎉</h3>",
	completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
	loadingHtml: '<h3>Loading questions...</h3>',
	pages: [
		{
			name: 'rights_arrangements',
			elements: [
				{
					type: 'boolean',
					name: 'joint_services',
					title:
						'Does ownership of the property carry a responsibility to contribute towards the costs of any jointly used services? *',
					description: 'e.g. maintenance of a private road, a shared driveway, a boundary, a drain, etc.',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'comment',
					name: 'joint_services_desc',
					visibleIf: '{joint_services} = true',
					title: 'Please give details *',
					hideNumber: true,
					requiredIf: '{joint_services} = true',
				},
				{
					type: 'boolean',
					name: 'rights_nearby',
					title: 'Does the property benefit from any rights over any neighbouring property? *',
					description: 'e.g. rights of way',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'comment',
					name: 'rights_nearby_desc',
					visibleIf: '{rights_nearby} = true',
					title: 'Please give details *',
					hideNumber: true,
					requiredIf: '{rights_nearby} = true',
				},
				{
					type: 'boolean',
					name: 'access_right_challenged',
					title:
						'Has anyone taken steps to prevent access to the property, or to complain or demand payment for access to the property? *',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'comment',
					name: 'access_right_challenged_desc',
					visibleIf: '{access_right_challenged} = true',
					title: 'Please give details *',
					hideNumber: true,
					requiredIf: '{access_right_challenged} = true',
				},
				{
					type: 'matrix',
					name: 'rights',
					title: 'Do you know if any of the following rights benefit the property? *',
					hideNumber: true,
					isRequired: true,
					columns: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
					],
					rows: [
						{
							value: 'light',
							text: 'Rights of light',
						},
						{
							value: 'support',
							text: 'Rights of support from adjoining properties',
						},
						{
							value: 'customary',
							text: 'Customary rights (e.g. rights deriving from local traditions)',
						},
					],
				},
				{
					type: 'matrix',
					name: 'arrangements',
					title: 'Do you know if any of the following arrangements affect the property? *',
					hideNumber: true,
					isRequired: true,
					columns: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
					],
					rows: [
						{
							value: 'mining',
							text: "Other people's rights to mines and minerals under the land",
						},
						{
							value: 'chancel',
							text: 'Chancel repair liability',
						},
						{
							value: 'things',
							text: "Other people's rights to take things from the land (such as timber, hay or fish)",
						},
					],
				},
				{
					type: 'comment',
					name: 'arrangements_desc',
					visibleIf:
						"(({arrangements.chancel} = 'yes') or ({arrangements.mining} = 'yes')) or ({arrangements.things} = 'yes')",
					title: 'Please give details about the arrangements above *',
					hideNumber: true,
					requiredIf:
						"(({arrangements.chancel} = 'yes') or ({arrangements.mining} = 'yes')) or ({arrangements.things} = 'yes')",
				},
				{
					type: 'boolean',
					name: 'access_right',
					title: 'Do any neighbours or members of the public have the right to enter your property? *',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'comment',
					name: 'access_right_desc',
					visibleIf: '{access_right} = true',
					title: 'Tell us more.',
					hideNumber: true,
					requiredIf: '{access_right} = true',
				},
				{
					type: 'boolean',
					name: 'other_rights',
					title: 'Do you know of any other rights or arrangements affecting the property? *',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'comment',
					name: 'other_rights_desc',
					visibleIf: '{other_rights} = true',
					title: 'Tell us more.',
					hideNumber: true,
					requiredIf: '{other_rights} = true',
				},
				{
					type: 'boolean',
					name: 'rights_exercise_yn',
					title:
						'Please confirm you have been able to exercise all rights granted in favour of the property without issue during your ownership. *',
					hideNumber: true,
					defaultValue: 'true',
					isRequired: true,
					labelTrue: 'Confirmed',
					labelFalse: 'Not confirmed',
				},
			],
			title: 'Rights and informal arrangements',
		},
		{
			name: 'boundaries',
			elements: [
				{
					type: 'panel',
					name: 'responsibility',
					elements: [
						{
							type: 'radiogroup',
							name: 'left',
							title: 'On the left',
							hideNumber: true,
							isRequired: true,
							choices: [
								{
									value: 'sellers',
									text: 'Sellers',
								},
								{
									value: 'shared',
									text: 'Shared',
								},
								{
									value: 'neighbour',
									text: 'Neighbour',
								},
								{
									value: 'not_known',
									text: 'Not known',
								},
							],
							colCount: 4,
						},
						{
							type: 'radiogroup',
							name: 'right',
							title: 'On the right',
							hideNumber: true,
							isRequired: true,
							choices: [
								{
									value: 'sellers',
									text: 'Sellers',
								},
								{
									value: 'shared',
									text: 'Shared',
								},
								{
									value: 'neighbour',
									text: 'Neighbour',
								},
								{
									value: 'not_known',
									text: 'Not known',
								},
							],
							colCount: 4,
						},
						{
							type: 'radiogroup',
							name: 'rear',
							title: 'At the rear',
							hideNumber: true,
							isRequired: true,
							choices: [
								{
									value: 'sellers',
									text: 'Sellers',
								},
								{
									value: 'shared',
									text: 'Shared',
								},
								{
									value: 'neighbour',
									text: 'Neighbour',
								},
								{
									value: 'not_known',
									text: 'Not known',
								},
							],
							colCount: 4,
						},
						{
							type: 'radiogroup',
							name: 'front',
							title: 'At the front',
							hideNumber: true,
							isRequired: true,
							choices: [
								{
									value: 'sellers',
									text: 'Sellers',
								},
								{
									value: 'shared',
									text: 'Shared',
								},
								{
									value: 'neighbour',
									text: 'Neighbour',
								},
								{
									value: 'not_known',
									text: 'Not known',
								},
							],
							colCount: 4,
						},
					],
					title:
						'Looking towards the property from the road, who owns or accepts responsibility to maintain or repair the boundary features? *',
				},
				{
					type: 'comment',
					name: 'irregular',
					title:
						'If the boundaries are irregular please indicate ownership by written description or by reference to a plan:',
					hideNumber: true,
				},
				{
					type: 'boolean',
					name: 'moved',
					title:
						'Are you aware of any boundary feature having been\r\nmoved in the last 10 years (or during your period of ownership if longer)? *',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'comment',
					name: 'moved_desc',
					visibleIf: '{moved} = true',
					title: 'Please give details *',
					hideNumber: true,
					requiredIf: '{moved} = true',
				},
				{
					type: 'boolean',
					name: 'extra',
					title: 'During your ownership, did you purchase any adjacent land\r\nor property? *',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'comment',
					name: 'extra_desc',
					visibleIf: '{extra} = true',
					title: 'Please give details *',
					hideNumber: true,
					requiredIf: '{extra} = true',
				},
				{
					type: 'boolean',
					name: 'overhang',
					title:
						'Does any part of the property or any building on the property overhang, or project under, the boundary of the neighbouring property or road? *',
					description: 'For example, cellars under the pavement, overhanging eaves, covered walkways, etc.',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'comment',
					name: 'overhang_desc',
					visibleIf: '{overhang} = true',
					title: 'Please give details *',
					hideNumber: true,
					requiredIf: '{overhang} = true',
				},
				{
					type: 'boolean',
					name: 'notice',
					title:
						'Has any notice been received under the Party Wall Act 1996 in respect of any shared/party boundaries? *',
					description: 'For example, cellars under the pavement, overhanging eaves, covered walkways, etc.',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'comment',
					name: 'notice_desc',
					visibleIf: '{notice} = true',
					title: 'Please supply a copy, and give details of any works carried out or agreed. *',
					hideNumber: true,
					requiredIf: '{notice} = true',
				},
			],
			title: 'Boundaries',
		},
		{
			name: 'drains_and_pipes',
			elements: [
				{
					type: 'panel',
					name: 'panel4',
					elements: [
						{
							type: 'radiogroup',
							name: 'shared_infra_active',
							title: 'Do any drains, pipes or wires TO the property cross any neighbouring properties? *',
							hideNumber: true,
							isRequired: true,
							choices: [
								{
									value: 'yes',
									text: 'Yes',
								},
								{
									value: 'no',
									text: 'No',
								},
								{
									value: 'not_known',
									text: 'Not known',
								},
							],
							colCount: 3,
						},
						{
							type: 'radiogroup',
							name: 'shared_infra_passive',
							title: 'Do any drains, pipes or wires FROM neighbouring properties cross the property? *',
							hideNumber: true,
							isRequired: true,
							choices: [
								{
									value: 'yes',
									text: 'Yes',
								},
								{
									value: 'no',
									text: 'No',
								},
								{
									value: 'not_known',
									text: 'Not known',
								},
							],
							colCount: 3,
						},
						{
							type: 'radiogroup',
							name: 'shared_infra_agreement',
							title: 'Is there any agreement or arrangement about drains, pipes or wires? *',
							hideNumber: true,
							isRequired: true,
							choices: [
								{
									value: 'yes',
									text: 'Yes',
								},
								{
									value: 'no',
									text: 'No',
								},
								{
									value: 'not_known',
									text: 'Not known',
								},
							],
							colCount: 3,
						},
						{
							type: 'comment',
							name: 'shared_infra_agreement_desc',
							visibleIf: "{shared_infra_agreement} = 'yes'",
							title: 'Please give details',
							hideNumber: true,
							requiredIf: "{shared_infra_agreement} = 'yes'",
						},
					],
				},
			],
			title: 'Drains, pipes, and wires',
		},
		{
			name: 'protectedbuildings',
			elements: [
				{
					type: 'radiogroup',
					name: 'listed_building',
					title: 'Is the property (or any part of it) a listed building? *',
					hideNumber: true,
					isRequired: true,
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_known',
							text: 'Not known',
						},
					],
					colCount: 3,
				},
				{
					type: 'html',
					name: 'listed_building_note',
					visibleIf: "{listed_building} = 'yes'",
					html: "We'll ask you to upload copies of the relevant documents (e.g. notice of listing, letter from local authority confirming listing, etc.) in the <b>Documents</b> tab.",
				},
				{
					type: 'radiogroup',
					name: 'conservation_area',
					title: 'Is the property (or any part of it) in a conservation area? *',
					hideNumber: true,
					isRequired: true,
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_known',
							text: 'Not known',
						},
					],
					colCount: 3,
				},
				{
					type: 'radiogroup',
					name: 'protected_trees',
					title: 'Are any trees on the property subject to a Tree Preservation Order? *',
					hideNumber: true,
					isRequired: true,
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_known',
							text: 'Not known',
						},
					],
					colCount: 3,
				},
				{
					type: 'html',
					name: 'protected_trees_note',
					visibleIf: "{protected_trees} = 'yes'",
					html: "\nWe'll ask you to upload a copy of the <b>Tree Preservation Order</b>  in the <b>Documents</b> tab.",
				},
				{
					type: 'radiogroup',
					name: 'protected_trees_complied',
					visibleIf: "{protected_trees} = 'yes'",
					title: 'Have the terms of the order been complied with?',
					hideNumber: true,
					requiredIf: "{protected_trees} = 'yes'",
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_known',
							text: 'Not known',
						},
					],
					colCount: 3,
				},
			],
			title: 'Protected attributes',
		},
	],
	showCompletedPage: false,
	showProgressBar: 'top',
	completeText: 'Submit',
	widthMode: 'static',
};
