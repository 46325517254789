import Tracker from 'src/sections/@dashboard/greetings/Tracker';
import { useSelector } from 'react-redux';
import { getTracker, selectTracker } from 'src/redux/slices/tracker';
import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { TrackerSectionWrapper, TrackerWrapper } from './styled';

const TrackerSection = () => {
	const tracker = useSelector(selectTracker);
	const transaction = useSelector(selectTransactionOverview);

	useEffect(() => {
		dispatch(getTracker());
	}, []);

	return (
		<TrackerSectionWrapper withTracker={!transaction.isInstrument}>
			{!transaction.isInstrument && !!tracker.data && (
				<TrackerWrapper>
					<Tracker />
				</TrackerWrapper>
			)}
		</TrackerSectionWrapper>
	);
};

export default TrackerSection;
