/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IFile extends File {
	path: string;
	preview: any;
}

export type QuestionnairePageElementType =
	| 'checkbox'
	| 'panel'
	| 'multipletext'
	| 'matrix'
	| 'radiogroup'
	| 'other'
	| 'tagbox';

export type QuestionnaireBasePageElement<T extends QuestionnairePageElementType> = {
	type: T;
	name: string;
	title: string;
	skipPrefill?: boolean;
	defaultValue?: any[];
};

export type QuestionnaireCheckboxElement = {
	choices: Array<{ value: string; text: string }>;
	defaultValue: Array<string>;
} & QuestionnaireBasePageElement<'checkbox'>;

export type QuestionnairePanelElement = {
	elements: Array<QuestionnairePageElement>;
} & QuestionnaireBasePageElement<'panel'>;

export type QuestionnaireMultipletextElement = {
	items: Array<{ name: string }>;
} & QuestionnaireBasePageElement<'multipletext'>;

export type QuestionnaireRadiogroupElement = {
	choices: Array<string | { value: string; text: string }>;
} & QuestionnaireBasePageElement<'radiogroup'>;

export type QuestionnaireMatrixElement = {
	defaultValue?: Record<string, unknown>;
} & QuestionnaireBasePageElement<'matrix'>;

export type QuestionnaireTagboxElement = {
	defaultValue?: Array<QuestionnairePageElement>;
} & QuestionnaireBasePageElement<'tagbox'>;

export type QuestionnairePageElement =
	| QuestionnaireCheckboxElement
	| QuestionnairePanelElement
	| QuestionnaireMultipletextElement
	| QuestionnaireRadiogroupElement
	| QuestionnaireMatrixElement
	| QuestionnaireTagboxElement
	| QuestionnaireBasePageElement<'other'>;

export type QuestionnairePage = {
	elements: QuestionnairePageElement[];
	common?: Record<string, string | number | null | unknown[]> | null;
} & Record<string, any>;

export enum QuestionnairePropertyEnum {
	environment = 'environment',
	property_details = 'property_details',
	building_works = 'building_works',
	rights_arrangements = 'rights_arrangements',
	guarantees_insurance = 'guarantees_insurance',
	services = 'services',
	ownership = 'ownership',
}

export enum QuestionnaireTransactionEnum {
	buyer_questions = 'buyer_questions',
	moving_out = 'moving_out',
	moving_in = 'moving_in',
	fitting_contents = 'fitting_contents',
	liabilities = 'liabilities',
	sell_content = 'sell_content',
	seller_finance = 'seller_finance',
	proprietor_finance = 'proprietor_finance',
	personal_details = 'personal_details',
}

export type QuestionnaireTransactionType = QuestionnaireTransactionEnum | `giftor_finance_${string}`;
