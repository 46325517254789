import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Box } from '@mui/material';
import { useSnackbar } from 'notistack';

import { styledMainTitle } from 'src/components/InviteModal/styles';
import Modal from 'src/components/customModal';
import { RHFAutocomplete, RHFInvitationRoleAutocomplete, RHFTextField } from 'src/components/hook-form';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { addTransactionPlaceholder, transactionInvitation } from 'src/api';
import { dispatch } from 'src/redux/store';
import { parseStringToJson } from 'src/utils/strings';
import Progress from 'src/components/Progress';
import { RESPONSE_CODES } from 'src/constants';
import modals from 'src/constants/modals';
import { selectUser } from 'src/redux/slices/auth';
import PriceField from 'src/components/PriceField';
import { extractBidValue } from 'src/utils/formatBid';
import { VerificationSessionStatus } from 'src/types';
import useError from 'src/hooks/useError';
import {
	getTransactionOverviewThunk,
	getTransactionParticipantsThunk,
	selectTransactionOverview,
	selectTransactionParticipants,
} from 'src/redux/slices/transaction';
import GoogleAddressAutocomplete from 'src/components/GoogleAddressAutoComplete';
import { getUserBusiness } from 'src/components/InviteCard/utils';
import updateTransactionPlaceholder from 'src/api/transaction/update-placeholder';
import useTransactionInvitation from '../../hooks/useTransactionInvitation';
import { TransactionRole, TransactionSide } from '../../types';
import { IInvitationForm } from '../types';
import { invitationSchema } from '../validationSchemas';
import ExistingUserError from './components/ExistingUserError';
import { transactionQuoteRolesKeys } from '../../constants';
import { convertTransactionSidesToOptions } from './utils';
import { ActionButton } from './styled';

const Content = () => {
	const user = useSelector(selectUser);
	const participants = useSelector(selectTransactionParticipants);
	const transaction = useSelector(selectTransactionOverview);
	const form = useForm<IInvitationForm>({
		resolver: yupResolver(invitationSchema),
		defaultValues: { isExistingUser: false },
	});
	const { error, setError } = useError();
	const [role, side, isExistingUser, innerRole] = form.watch(['role', 'side', 'isExistingUser', 'innerRole']);
	const { payload } = useSelector(
		selectModal<{ side?: TransactionSide; role?: TransactionRole; placeholderId?: string; isEdit?: boolean }>,
	);
	const { enqueueSnackbar } = useSnackbar();
	const { transactionId, users, fetchUsers, areUsersLoading, metadata, isMetadataInitialized, sides } =
		useTransactionInvitation();

	const showQuoteField = useMemo(() => !!role && transactionQuoteRolesKeys.includes(role as never), [role]);
	const isAncillary = innerRole !== 'buyer' && innerRole !== 'seller';

	const handleSideChange = () => form.setValue('role', null as never);

	useEffect(() => {
		if (payload.placeholderId) {
			const placeholder = participants.placeholders.find(
				({ placeholderId }) => placeholderId === payload.placeholderId,
			);

			if (!placeholder) return;

			const { branch, business } = getUserBusiness(placeholder);

			form.setValue('side', placeholder.side);
			form.setValue('role', placeholder.role);
			form.setValue('innerRole', placeholder.role);
			form.setValue('relationship', placeholder.relationship);
			form.setValue('givenNames', placeholder.givenNames);
			form.setValue('lastNames', placeholder.lastNames);
			form.setValue('email', placeholder.email ?? '');
			form.setValue('phone', placeholder.phone);
			form.setValue('business', business.name);
			form.setValue('branch', branch.name);
			form.setValue('quote', placeholder.quote ?? '');
			form.setValue('address.formattedAddress', (branch.address || placeholder.address) ?? '');

			return;
		}
		if (payload.side) form.setValue('side', payload.side);
		if (transaction.isInstrument) form.setValue('side', 'sell');
	}, [payload, transaction.isInstrument]);

	const handleAdd = async ({ address, ...data }: IInvitationForm) => {
		try {
			setError(null);

			await addTransactionPlaceholder({
				...data,
				role: innerRole,
				transactionId,
				quote: data.quote ? extractBidValue(data.quote) : null,
				...(address?.formattedAddress && { address: address.formattedAddress }),
			});

			handleCloseModal();
			enqueueSnackbar(`${data.givenNames} ${data.lastNames} successfully added!`);
			dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));
			dispatch(getTransactionOverviewThunk({ id: transactionId, withLoading: false }));
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		}
	};

	const handleUpdate = async ({ address, ...data }: IInvitationForm) => {
		try {
			setError(null);

			await updateTransactionPlaceholder({
				...data,
				transactionId,
				id: payload.placeholderId as string,
				quote: data.quote ? extractBidValue(data.quote) : null,
				...(address?.formattedAddress && { address: address.formattedAddress }),
			});

			handleCloseModal();
			enqueueSnackbar(`${data.givenNames} ${data.lastNames} successfully updated!`);
			dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));
			dispatch(getTransactionOverviewThunk({ id: transactionId, withLoading: false }));
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		}
	};

	const handleAddAndInvite = async (data: IInvitationForm) => {
		try {
			if (!data.email) {
				form.setError('email', { message: 'This field is required' });

				return;
			}

			setError(null);

			await transactionInvitation({
				...data,
				quote: data.quote ? extractBidValue(data.quote) : null,
				transactionId,
				role: innerRole,
				placeholderId: payload.placeholderId,
			});

			handleCloseModal();
			enqueueSnackbar(`${data.givenNames} ${data.lastNames} successfully invited!`);
			dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));
			dispatch(getTransactionOverviewThunk({ id: transactionId, withLoading: false }));
		} catch (e) {
			if (e instanceof Error) {
				const response = parseStringToJson(e.message);

				if (typeof response === 'string') {
					setError(e.message);
				} else if (response?.code) {
					switch (response?.code) {
						case RESPONSE_CODES.INVITATION.USER_EXISTS_UNDER_OTHER_ROLE:
							enqueueSnackbar(<ExistingUserError />, { variant: 'error' });
							break;
						default:
							break;
					}
				}
			}
		}
	};

	const handleEmailChange = (email: string) => {
		const existingUser = users.find((u) => u.email.toLowerCase() === email.toLowerCase());
		const hasConflictingActiveUser = !!existingUser && !!payload.isEdit;

		form.setValue('hasConflictingActiveUser', hasConflictingActiveUser);
		form.setValue('email', email, { shouldValidate: true });

		if (hasConflictingActiveUser) return;

		if (!existingUser) {
			form.setValue('isExistingUser', false);

			if (payload.placeholderId) {
				const placeholder = participants.placeholders.find(
					({ placeholderId }) => placeholderId === payload.placeholderId,
				);

				if (!placeholder) return;

				form.setValue('givenNames', placeholder.givenNames);
				form.setValue('lastNames', placeholder.lastNames);
			}

			return;
		}

		form.setValue('isExistingUser', true);
		form.setValue('givenNames', existingUser.givenNames);
		form.setValue('lastNames', existingUser.lastNames);
	};

	useEffect(() => {
		if (side && role) fetchUsers(role, side);
	}, [side, role]);

	useEffect(() => {
		const email = form.getValues('email');

		if (!email) return;

		handleEmailChange(email);
	}, [users]);

	if (user.verificationStatus !== VerificationSessionStatus.VERIFIED && !user.isAdmin && !transaction.isBuyerViewer) {
		dispatch(openModal({ name: modals.verificationRequired }));

		return null;
	}

	const handleInnerRoleChange = (r: TransactionRole) => form.setValue('innerRole', r);

	return (
		<FormProvider {...form}>
			<Box display={'flex'} flexDirection={'column'} gap='15px'>
				<Typography sx={{ ...styledMainTitle, pb: 0 }}>{payload.isEdit ? 'UPDATE' : 'ADD'} PARTICIPANT</Typography>
				{error}
				{!isMetadataInitialized ? (
					<Box height='85px' display='flex' alignItems='center' justifyContent='center'>
						<Progress zoom={0.5} />
					</Box>
				) : (
					<>
						<RHFAutocomplete
							name='side'
							label='Select a side'
							options={convertTransactionSidesToOptions(sides)}
							valueAttribute='key'
							disabled={!!(payload.side || payload.placeholderId || transaction.isInstrument)}
							handleChange={handleSideChange}
						/>
						{!!side && (
							<RHFInvitationRoleAutocomplete
								name='role'
								label='Select a role'
								options={metadata.roles}
								setInnerRole={handleInnerRoleChange}
								valueAttribute='key'
								disabled={!!(payload.role || payload.placeholderId)}
							/>
						)}
						{!!role && (
							<>
								{areUsersLoading ? (
									<Box height='215px' display='flex' alignItems='center' justifyContent='center'>
										<Progress zoom={0.8} />
									</Box>
								) : (
									<>
										<RHFTextField
											name='givenNames'
											label='Given name(s)'
											disabled={(isExistingUser || !!payload.placeholderId) && !payload.isEdit}
										/>
										<RHFTextField
											name='lastNames'
											label='Last name(s)'
											disabled={(isExistingUser || !!payload.placeholderId) && !payload.isEdit}
										/>
										<RHFTextField
											name='email'
											label={`Email${!payload.placeholderId || payload.isEdit ? ' (optional)' : ' address'}`}
											onChange={(e) => handleEmailChange(e.target.value)}
										/>
										{!isExistingUser && (
											<>
												<RHFTextField
													name='phone'
													label='Telephone (optional)'
													disabled={!!payload.placeholderId && !payload.isEdit}
												/>
												<GoogleAddressAutocomplete
													name='address.formattedAddress'
													label='Address (optional)'
													setValue={form.setValue}
													clearErrors={form.clearErrors}
													disabled={!!payload.placeholderId && !payload.isEdit}
												/>
											</>
										)}
										{isAncillary && !isExistingUser && (
											<>
												<RHFTextField
													name='business'
													label='Company (optional)'
													disabled={!!payload.placeholderId && !payload.isEdit}
												/>
												<RHFTextField
													name='branch'
													label='Branch (optional)'
													disabled={!!payload.placeholderId && !payload.isEdit}
												/>
											</>
										)}
										{user.isAdmin && showQuoteField && (
											<PriceField
												name='quote'
												placeholder='Enter a quote (optional)'
												disabled={!!payload.placeholderId && !payload.isEdit}
												InputProps={{
													endAdornment: (
														<Typography ml='5px' variant='subtitle2'>
															+VAT
														</Typography>
													),
												}}
											/>
										)}
									</>
								)}
							</>
						)}
					</>
				)}
				<Box display='flex' justifyContent='flex-end' gap='15px'>
					{!payload.isEdit ? (
						<>
							{!isExistingUser && !payload.placeholderId && (
								<ActionButton
									loading={form.formState.isSubmitting}
									disabled={!role || !side || areUsersLoading}
									onClick={form.handleSubmit(handleAdd)}
								>
									Add
								</ActionButton>
							)}
							<ActionButton
								variant='contained'
								loading={form.formState.isSubmitting}
								disabled={!role || !side || areUsersLoading}
								onClick={form.handleSubmit(handleAddAndInvite)}
							>
								{payload.placeholderId ? 'Invite' : 'Add & Invite'}
							</ActionButton>
						</>
					) : (
						<ActionButton
							variant='contained'
							loading={form.formState.isSubmitting}
							disabled={!role || !side || areUsersLoading}
							onClick={form.handleSubmit(handleUpdate)}
						>
							Update
						</ActionButton>
					)}
				</Box>
			</Box>
		</FormProvider>
	);
};

const InvitationModal = () => (
	<Modal name={modals.invitation} cardSx={{ maxWidth: '500px', padding: '32px 27px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default InvitationModal;
