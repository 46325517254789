import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import Router from './routes';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import FullVersionModal from './components/FullVersionModal';
import {
	TermsAndConditionsModal,
	VerificationRequiredModal,
	PrivacyPolicyModal,
	AcceptableUsePolicyModal,
} from './components/modals';
import { endSession, logout as logoutApi, onUserDataChange, resumeSession } from './api';
import { dispatch } from './redux/store';
import { setIsInitialized, login, logout, selectIsAuthenticated } from './redux/slices/auth';
import { getNav } from './utils/propertyHelpers';

export default function App() {
	const isAuthenticated = useSelector(selectIsAuthenticated);

	useEffect(() => {
		onUserDataChange(async (data) => {
			if (data) {
				dispatch(login(data));
				getNav();
			} else {
				dispatch(logout());
				logoutApi();
			}

			dispatch(setIsInitialized(true));
		});
	}, []);

	useEffect(() => {
		const setup = async () => {
			const currentSessionId = sessionStorage.getItem('sessionId');

			if (!currentSessionId) return;

			await resumeSession(currentSessionId);
		};

		if (isAuthenticated) setup();

		const handleUnload = async () => {
			const currentSessionId = sessionStorage.getItem('sessionId');

			if (!currentSessionId) return;

			await endSession(currentSessionId);
		};

		window.addEventListener('unload', handleUnload);

		return () => {
			window.removeEventListener('unload', handleUnload);
		};
	}, [isAuthenticated]);

	return (
		<>
			<ProgressBarStyle />
			<ScrollToTop />
			<FullVersionModal />
			<VerificationRequiredModal />
			<PrivacyPolicyModal />
			<TermsAndConditionsModal />
			<AcceptableUsePolicyModal />
			<Router />
		</>
	);
}
