import { useSelector } from 'react-redux';
import { isBoolean } from 'lodash';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { guarantees } from 'src/constants/surveyJson/guarantees';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnairePropertyEnum } from 'src/utils/types';
import { selectTransactionOverviewField } from 'src/redux/slices/transaction';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { DescriptionBlock, SecondTitle } from '../styles';
import { useQuestionnaire } from '../useQuestionnaire';
import { GuaranteesWarrantiesEnum } from './types';

const QuestionnaireGuaranteeCard = () => {
	const { fullQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const { data: transaction } = useSelector(selectTransactionOverviewField);
	const { onPageChanged, onSubmit, onComplete, isHidden } = useQuestionnaire({
		type: QuestionnairePropertyEnum.guarantees_insurance,
		questionnaireType: QuestionnaireTypeEnum.PROPERTY,
	});

	if (isHidden) return null;

	const completedProperyGuarantees = fillQuestionnaireJsonWithCompletedData(
		guarantees(transaction?.tenure, fullQuestionnaire),
		fullQuestionnaire?.guarantees_insurance,
	);

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				initialCurrentPage={fullQuestionnaire?.guarantees_insurance.currentPage}
				questionnaireKey={QuestionnairePropertyEnum.guarantees_insurance}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				surveyJson={completedProperyGuarantees}
				status={fullQuestionnaire?.guarantees_insurance.status}
				title='COVERAGE'
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				title='Coverage'
				type={QuestionnairePropertyEnum.guarantees_insurance}
			>
				{({ details }) => {
					const propertyWarranties = details.guarantees.guarantees_warranties_property
						?.map((val) => GuaranteesWarrantiesEnum[val] || val)
						.join(', ');

					return (
						<>
							{details?.guarantees?.guarantees_warranties_property ? (
								<DescriptionBlock>{propertyWarranties}</DescriptionBlock>
							) : null}
							{isBoolean(details?.insurance_page?.home_insurance) && (
								<>
									<SecondTitle>Insurance</SecondTitle>
									<DescriptionBlock>
										Property: <b>{details.insurance_page.home_insurance ? 'Insured' : 'None'}</b>
									</DescriptionBlock>
								</>
							)}
						</>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnaireGuaranteeCard;
