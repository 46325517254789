import { useSelector } from 'react-redux';
import {
	clearDonePropertyQuestionnaire,
	refreshPropertyQuestionnaire,
	selectPropertyQuestionnaireSlice,
	updatePropertyQuestionnaire,
} from 'src/redux/slices/propertyQuestionnaire';
import {
	clearDoneTransactionQuestionnaire,
	refreshTransactionQuestionnaire,
	selectTransactionQuestionnaireSlice,
	updateTransactionQuestionnaire,
} from 'src/redux/slices/transactionQuestionnaire';
import { dispatch } from 'src/redux/store';
import { IQuestionnaire, QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { getPropertyQuestionnaire, getTransactionQuestionnaire } from 'src/utils/firebase';
import { get } from 'lodash';
import { useMemo } from 'react';
import { selectTransactionOverviewField } from 'src/redux/slices/transaction';
import { useLocation } from 'react-router';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { selectUser } from 'src/redux/slices/auth';
import { mergeQuestionnaireUpdateData } from './utils';
import { UpdateQuestionnaireData, UseQuestionnaireParams } from './types';

export const useQuestionnaire = ({ type, questionnaireType, suffix = '' }: UseQuestionnaireParams) => {
	const { pathname } = useLocation();
	const user = useSelector(selectUser);
	const { fullQuestionnaire: propertyQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const { fullQuestionnaire: transactionQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const { data: transaction } = useSelector(selectTransactionOverviewField);

	const key = `${type}${suffix}`;
	const isProperty = questionnaireType === QuestionnaireTypeEnum.PROPERTY;
	const isTransaction = questionnaireType === QuestionnaireTypeEnum.TRANSACTION;
	const currentQuestionnaire = get(isTransaction ? transactionQuestionnaire : propertyQuestionnaire, key);
	const isTransactionPage = pathname.startsWith('/transactions');
	const isStarted = currentQuestionnaire?.status !== QuestionnaireStatus.NOT_STARTED;

	const isHidden = useMemo(() => {
		if (!isTransactionPage) return false;

		if (transaction?.isBuyerGiftor) return !key.startsWith(`giftor_finance_${user.uid}`);

		if (transaction?.isBuyer) {
			const isBuySide =
				key === QuestionnaireTransactionEnum.buyer_questions || key === QuestionnaireTransactionEnum.moving_in;

			return !isBuySide && !isStarted;
		}

		return false;
	}, [key, transaction, isTransactionPage]);

	const updateData = async (data: UpdateQuestionnaireData) => {
		if (isProperty && propertyQuestionnaire) {
			const { data: newestData } = await getPropertyQuestionnaire(propertyQuestionnaire.id);

			const questionnaireData = get(newestData, key) as undefined | IQuestionnaire;

			await dispatch(
				updatePropertyQuestionnaire({
					type: key,
					data: {
						...mergeQuestionnaireUpdateData(questionnaireData, data),
						status: data.status ?? questionnaireData?.status,
					},
				}),
			);
			dispatch(refreshPropertyQuestionnaire());
		}
		if (isTransaction && transactionQuestionnaire) {
			const { data: newestData } = await getTransactionQuestionnaire(transactionQuestionnaire.id);

			const questionnaireData = get(newestData, key) as undefined | IQuestionnaire;

			await dispatch(
				updateTransactionQuestionnaire({
					type: key,
					data: {
						...mergeQuestionnaireUpdateData(questionnaireData, data),
						status: data.status ?? questionnaireData?.status,
					},
				}),
			);
			dispatch(refreshTransactionQuestionnaire());
		}
	};

	const fromArrToObj = (acc, { name, value, comment, jsonObj, isOtherSelected }) => {
		if (jsonObj.type === 'radiogroup') {
			return { ...acc, [name]: isOtherSelected ? comment : value };
		}

		if (jsonObj.type === 'tagbox' || jsonObj.type === 'dropdown' || jsonObj.type === 'checkbox') {
			return {
				...acc,
				[name]: Array.isArray(value)
					? value.map((v) => {
							if (v === 'other' && comment && isOtherSelected) return comment;

							return v;
					  }) ?? []
					: value,
			};
		}

		return typeof value === 'undefined' ? acc : { ...(acc || {}), [name]: value, isSubmitted: true };
	};

	const onPageChanged =
		(currentPage, isLocked) =>
		async (_, { oldCurrentPage: { name, questions }, isNextPage }) => {
			const data = get(isTransaction ? transactionQuestionnaire : propertyQuestionnaire, key);
			const newStatus =
				data?.status === QuestionnaireStatus.DONE ? QuestionnaireStatus.DONE : QuestionnaireStatus.IN_PROGRESS;

			if (isLocked) return;

			if (currentPage === null) {
				if (questionnaireType === QuestionnaireTypeEnum.PROPERTY) await dispatch(clearDonePropertyQuestionnaire(type));
				if (questionnaireType === QuestionnaireTypeEnum.TRANSACTION)
					await dispatch(clearDoneTransactionQuestionnaire(type));
			}
			const pageAnswers = questions.reduce(fromArrToObj, null);
			const newPage = isNextPage ? currentPage + 1 : currentPage - 1;

			if (pageAnswers) {
				await updateData({ [name]: pageAnswers, currentPage: newPage, status: newStatus });

				return;
			}

			await updateData({ currentPage: newPage, status: newStatus });
		};

	const onSubmit = async ({ propertyHash, pages }) => {
		const lastPage = pages.find((item) => item.name === propertyHash.activePage?.name);
		const pageAnswers = lastPage?.questions.reduce(fromArrToObj, null) ?? {};

		await updateData({
			[lastPage.name]: pageAnswers,
			status:
				currentQuestionnaire?.status === QuestionnaireStatus.DONE
					? QuestionnaireStatus.DONE
					: QuestionnaireStatus.IN_PROGRESS,
		});
	};

	const onComplete = async () => {
		await updateData({ status: QuestionnaireStatus.DONE, isSubmitted: true });
	};

	return {
		onPageChanged,
		onSubmit,
		onComplete,
		updateData,
		isHidden,
	};
};
