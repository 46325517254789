export const getPersonalQuestionnaire = (isExtended: boolean, isBuyer: boolean, isGiftor: boolean) => ({
	title: 'Personal details',
	logoPosition: 'right',
	pages: [
		{
			name: 'contact',
			title: 'Contact methods',
			elements: [
				{
					type: 'multipletext',
					name: 'telephone',
					title: 'Contact numbers',
					description: 'Please add or confirm your contact numbers on your account.',
					hideNumber: true,
					items: [
						{
							name: 'mobile',
							isRequired: true,
							inputType: 'tel',
							title: 'Mobile *',
						},
						{
							name: 'landline',
							inputType: 'tel',
							title: 'Landline',
						},
					],
				},
				{
					type: 'panel',
					name: 'contact_methods',
					title: 'Preferred contact methods',
					description: 'Please choose at least one contact method for each category below.',
					elements: [
						{
							type: 'checkbox',
							name: 'voice',
							minWidth: '100px',
							maxWidth: '33%',
							startWithNewLine: false,
							title: 'Voice *',
							hideNumber: true,
							isRequired: true,
							choices: [
								{
									value: 'mobile',
									text: 'Mobile',
								},
								{
									value: 'landline',
									text: 'Landline',
								},
							],
							minSelectedChoices: 1,
						},
						{
							type: 'checkbox',
							name: 'message',
							minWidth: '100px',
							maxWidth: '33%',
							startWithNewLine: false,
							title: 'Message *',
							hideNumber: true,
							isRequired: true,
							choices: [
								{
									value: 'whatsapp',
									text: 'WhatsApp',
								},
								{
									value: 'text',
									text: 'Text',
								},
							],
							minSelectedChoices: 1,
						},
						{
							type: 'checkbox',
							name: 'mail',
							minWidth: '100px',
							maxWidth: '33%',
							startWithNewLine: false,
							title: 'Mail *',
							hideNumber: true,
							isRequired: true,
							readOnly: true,
							defaultValue: ['email', 'post'],
							choices: [
								{
									value: 'email',
									text: 'Email',
								},
								{
									value: 'post',
									text: 'Post',
								},
							],
							minSelectedChoices: 1,
						},
					],
				},
			],
		},
		...(isExtended
			? [
					{
						name: 'tax',
						title: 'Tax',
						elements: [
							{
								type: 'multipletext',
								name: 'employment',
								title: 'Employment',
								hideNumber: true,
								items: [
									{
										name: 'nino',
										isRequired: true,
										inputType: 'text',
										title: 'National Insurance Number (NINo) *',
									},
									{
										name: 'occupation',
										isRequired: true,
										inputType: 'text',
										title: 'Occupation *',
									},
								],
							},
							{
								type: 'boolean',
								name: 'is_selfemployed',
								title: 'Are you self-employed? *',
								titleLocation: 'left',
								description:
									'Self-employed individuals are responsible for the success or failure of their own business, while an employee is someone who works for someone else and does not have the same risks.',
								hideNumber: true,
								isRequired: true,
								labelTrue: 'Yes',
								labelFalse: 'No',
							},
							...(isGiftor
								? []
								: [
										{
											type: 'boolean',
											name: 'is_legal_person',
											title: 'Are you transacting as an individual or as a company? *',
											titleLocation: 'left',
											hideNumber: true,
											isRequired: true,
											labelTrue: 'Individual',
											labelFalse: 'Company',
										},
										...(isBuyer
											? [
													{
														type: 'panel',
														name: 'first_time_buyer',
														visibleIf: '{is_legal_person} = true',
														title: 'First-time buyer',
														elements: [
															{
																type: 'html',
																name: 'first_time_buyer_note',
																html: 'A <b>first-time buyer</b> is a person who has not previously, either\nalone or with others, owned an interest in a dwelling situated anywhere\nin the world. This includes previous acquisitions by inheritance or gift.',
															},
															{
																type: 'boolean',
																name: 'first_time_buyer_yn',
																title: 'Do you regard yourself as a first-time buyer? *',
																titleLocation: 'left',
																hideNumber: true,
																labelTrue: 'Yes',
																labelFalse: 'No',
															},
														],
													},
													{
														type: 'panel',
														name: 'multiple_dwellings',
														visibleIf: '{is_legal_person} = true',
														title: 'Multiple interests',
														elements: [
															{
																type: 'html',
																name: 'multiple_dwellings_note',
																html: '<b>“Owning an interest”</b> in a property can mean owning it outright, own it jointly with\nsomeone else, or owning just a share (including as a beneficiary under a\ntrust).\nOwning may be because you previously bought the interest, were given\nit, or inherited it.\nAlso, it applies to dwellings owned anywhere in the world (e.g. a buy-to-\nlet flat in the UK, or a holiday flat in Spain).',
															},
															{
																type: 'boolean',
																name: 'multiple_dwellings_yn',
																title:
																	'Will completion of this purchase result in you owning an interest in more than one dwelling? *\r\n',
																titleLocation: 'left',
																hideNumber: true,
																isRequired: true,
																labelTrue: 'Yes',
																labelFalse: 'No',
															},
															{
																type: 'boolean',
																name: 'multiple_dwellings_spouse',
																title:
																	'If you are buying alone, do you have a spouse or civil partner\r\nwho owns an interest in another dwelling? *',
																titleLocation: 'left',
																description: 'This is only relevant if you are living with your spouse/civil partner.',
																hideNumber: true,
																isRequired: true,
																labelTrue: 'Yes',
																labelFalse: 'No',
															},
															{
																type: 'boolean',
																name: 'multiple_dwellings_child',
																title: 'Do you have a child (under 18) who owns an interest in a dwelling? *\r\n',
																titleLocation: 'left',
																hideNumber: true,
																isRequired: true,
																labelTrue: 'Yes',
																labelFalse: 'No',
															},
															{
																type: 'text',
																name: 'multiple_dwellings_worth',
																visible: false,
																visibleIf:
																	'{multiple_dwellings_yn} = true or {multiple_dwellings_spouse} = true or {multiple_dwellings_child} = true',
																title: 'How much is the interest in the other dwelling(s) worth approximately? *',
																titleLocation: 'top',
																description:
																	'In Pounds. This also includes the child of your spouse or civil partner, if you\nare living with your spouse or civil partner.',
																hideNumber: true,
																isRequired: true,
																inputType: 'number',
																min: 1,
															},
															{
																type: 'boolean',
																name: 'multiple_dwellings_business',
																title:
																	'Are you a partner in a partnership business that owns an\r\ninterest in a dwelling? *',
																titleLocation: 'left',
																hideNumber: true,
																isRequired: true,
																labelTrue: 'Yes',
																labelFalse: 'No',
															},
														],
													},
													{
														type: 'panel',
														name: 'residence',
														visibleIf: '{is_legal_person} = true',
														title: 'Main residence',
														elements: [
															{
																type: 'html',
																name: 'residence_note',
																html: 'A <b>“main residence”</b> is the property where an individual or their family primarily lives. Factors include where they spend most time, their intention to make it their home, and where they are registered to vote or for healthcare. Evidence can include official correspondence and bills. Married couples and civil partners are treated as one unit, with only one main residence allowed between them. If a new property is intended to replace the current main residence, SDLT reliefs may apply, provided the old residence is sold within a specified period.',
															},
															{
																type: 'dropdown',
																name: 'residence_country',
																visibleIf: '{is_legal_person} = true',
																title: 'What is your country of residence? *',
																titleLocation: 'left',
																hideNumber: true,
																isRequired: true,
																requiredIf: '{is_legal_person} = true',
																choices: [
																	'Afghanistan',
																	'Albania',
																	'Algeria',
																	'American Samoa',
																	'Andorra',
																	'Angola',
																	'Anguilla',
																	'Antigua and Barbuda',
																	'Argentina',
																	'Armenia',
																	'Aruba',
																	'Australia',
																	'Austria',
																	'Azerbaijan',
																	'Bahamas',
																	'Bahrain',
																	'Bangladesh',
																	'Barbados',
																	'Belarus',
																	'Belgium',
																	'Belize',
																	'Benin',
																	'Bermuda',
																	'Bhutan',
																	'Bolivia',
																	'Bosnia and Herzegovina',
																	'Botswana',
																	'Brazil',
																	'British Virgin Islands',
																	'Brunei',
																	'Bulgaria',
																	'Burkina Faso',
																	'Burundi',
																	'Cabo Verde',
																	'Cambodia',
																	'Cameroon',
																	'Canada',
																	'Caribbean Netherlands',
																	'Cayman Islands',
																	'Central African Republic',
																	'Chad',
																	'Channel Islands',
																	'Chile',
																	'China',
																	'Colombia',
																	'Comoros',
																	'Congo',
																	'Cook Islands',
																	'Costa Rica',
																	'Croatia',
																	'Cuba',
																	'Curaçao',
																	'Cyprus',
																	'Czech Republic (Czechia)',
																	"Côte d'Ivoire",
																	'Denmark',
																	'Djibouti',
																	'Dominica',
																	'Dominican Republic',
																	'DR Congo',
																	'Ecuador',
																	'Egypt',
																	'El Salvador',
																	'Equatorial Guinea',
																	'Eritrea',
																	'Estonia',
																	'Eswatini',
																	'Ethiopia',
																	'Faeroe Islands',
																	'Falkland Islands',
																	'Fiji',
																	'Finland',
																	'France',
																	'French Guiana',
																	'French Polynesia',
																	'Gabon',
																	'Gambia',
																	'Georgia',
																	'Germany',
																	'Ghana',
																	'Gibraltar',
																	'Greece',
																	'Greenland',
																	'Grenada',
																	'Guadeloupe',
																	'Guam',
																	'Guatemala',
																	'Guinea',
																	'Guinea-Bissau',
																	'Guyana',
																	'Haiti',
																	'Holy See',
																	'Honduras',
																	'Hong Kong',
																	'Hungary',
																	'Iceland',
																	'India',
																	'Indonesia',
																	'Iran',
																	'Iraq',
																	'Ireland',
																	'Isle of Man',
																	'Israel',
																	'Italy',
																	'Jamaica',
																	'Japan',
																	'Jordan',
																	'Kazakhstan',
																	'Kenya',
																	'Kiribati',
																	'Kuwait',
																	'Kyrgyzstan',
																	'Laos',
																	'Latvia',
																	'Lebanon',
																	'Lesotho',
																	'Liberia',
																	'Libya',
																	'Liechtenstein',
																	'Lithuania',
																	'Luxembourg',
																	'Macao',
																	'Madagascar',
																	'Malawi',
																	'Malaysia',
																	'Maldives',
																	'Mali',
																	'Malta',
																	'Marshall Islands',
																	'Martinique',
																	'Mauritania',
																	'Mauritius',
																	'Mayotte',
																	'Mexico',
																	'Micronesia',
																	'Moldova',
																	'Monaco',
																	'Mongolia',
																	'Montenegro',
																	'Montserrat',
																	'Morocco',
																	'Mozambique',
																	'Myanmar',
																	'Namibia',
																	'Nauru',
																	'Nepal',
																	'Netherlands',
																	'New Caledonia',
																	'New Zealand',
																	'Nicaragua',
																	'Niger',
																	'Nigeria',
																	'Niue',
																	'North Korea',
																	'North Macedonia',
																	'Northern Mariana Islands',
																	'Norway',
																	'Oman',
																	'Pakistan',
																	'Palau',
																	'Panama',
																	'Papua New Guinea',
																	'Paraguay',
																	'Peru',
																	'Philippines',
																	'Poland',
																	'Portugal',
																	'Puerto Rico',
																	'Qatar',
																	'Romania',
																	'Russia',
																	'Rwanda',
																	'Réunion',
																	'Saint Barthelemy',
																	'Saint Helena',
																	'Saint Kitts & Nevis',
																	'Saint Lucia',
																	'Saint Martin',
																	'Saint Pierre & Miquelon',
																	'Samoa',
																	'San Marino',
																	'Sao Tome & Principe',
																	'Saudi Arabia',
																	'Senegal',
																	'Serbia',
																	'Seychelles',
																	'Sierra Leone',
																	'Singapore',
																	'Sint Maarten',
																	'Slovakia',
																	'Slovenia',
																	'Solomon Islands',
																	'Somalia',
																	'South Africa',
																	'South Korea',
																	'South Sudan',
																	'Spain',
																	'Sri Lanka',
																	'St. Vincent & Grenadines',
																	'State of Palestine',
																	'Sudan',
																	'Suriname',
																	'Sweden',
																	'Switzerland',
																	'Syria',
																	'Taiwan',
																	'Tajikistan',
																	'Tanzania',
																	'Thailand',
																	'Timor-Leste',
																	'Togo',
																	'Tokelau',
																	'Tonga',
																	'Trinidad and Tobago',
																	'Tunisia',
																	'Turkey',
																	'Turkmenistan',
																	'Turks and Caicos',
																	'Tuvalu',
																	'U.S. Virgin Islands',
																	'Uganda',
																	'Ukraine',
																	'United Arab Emirates',
																	'United Kingdom',
																	'United States',
																	'Uruguay',
																	'Uzbekistan',
																	'Vanuatu',
																	'Venezuela',
																	'Vietnam',
																	'Wallis & Futuna',
																	'Western Sahara',
																	'Yemen',
																	'Zambia',
																	'Zimbabwe',
																],
															},
															{
																type: 'boolean',
																name: 'main_residence_replacement_yn',
																title:
																	'Is the purpose of this purchase to replace a property that has been sold, but which you previously used as your only or main residence? *\n',
																hideNumber: true,
																isRequired: true,
																labelTrue: 'Yes',
																labelFalse: 'No',
															},
															{
																type: 'text',
																name: 'main_residence_replacement_date',
																visible: false,
																visibleIf: '{main_residence_replacement_yn} = true',
																title: 'What was the date of sale?',
																titleLocation: 'left',
																hideNumber: true,
																isRequired: true,
																inputType: 'date',
															},
															{
																type: 'radiogroup',
																name: 'property_use',
																visibleIf: '{is_legal_person} = true',
																title: 'How do you intend to use the property you are purchasing? *',
																hideNumber: true,
																isRequired: true,
																requiredIf: '{is_legal_person} = true',
																choices: [
																	{
																		value: 'main_residence',
																		text: 'Main residence',
																	},
																	{
																		value: 'rental_property',
																		text: 'Rental property',
																	},
																],
																showOtherItem: true,
																colCount: 3,
															},
														],
													},
											  ]
											: []),
								  ]),
							{
								type: 'multipletext',
								name: 'company_details',
								visibleIf: '{is_legal_person} = false',
								title: 'Company details',
								hideNumber: true,
								requiredIf: '{is_legal_person} = false',
								items: [
									{
										name: 'name',
										isRequired: true,
										inputType: 'tel',
										title: 'Company name *',
									},
									{
										name: 'number',
										isRequired: true,
										inputType: 'tel',
										title: 'Company number *',
									},
									{
										name: 'vat',
										title: 'VAT',
									},
								],
							},
						],
					},
					{
						name: 'correspondence',
						title: 'Correspondence',
						elements: [
							{
								type: 'multipletext',
								name: 'address_pre_completion',
								title: `Correspondence address ${isGiftor ? '' : '(Pre-completion) '}*`,
								hideNumber: true,
								isRequired: true,
								items: [
									{
										name: 'street_address',
										isRequired: true,
										placeholder: 'Flat 12, Churchill Lodge',
										title: 'Street address *',
									},
									{
										name: 'postcode',
										isRequired: true,
										placeholder: 'HP5 1AQ',
										title: 'Postcode *',
									},
									{
										name: 'town_city',
										isRequired: true,
										placeholder: 'Chesham',
										title: 'Town/City *',
									},
									{
										name: 'county_province',
										isRequired: true,
										placeholder: 'Buckinghamshire',
										title: 'County/Province *',
									},
									{
										name: 'state_region',
										isRequired: true,
										placeholder: 'England',
										title: 'State/Region *',
									},
									{
										name: 'country',
										isRequired: true,
										placeholder: 'United Kingdom',
										title: 'Country *',
									},
								],
							},
							{
								type: 'boolean',
								name: 'address_history_yn',
								title: 'Have you lived at the above address for 3 years or more? *',
								hideNumber: true,
								isRequired: true,
							},
							{
								type: 'paneldynamic',
								name: 'address_history',
								visibleIf: '{address_history_yn} = false',
								title: 'Please provide all addresses for the last 3 years\n',
								hideNumber: true,
								requiredIf: '{address_history_yn} = false',
								templateElements: [
									{
										type: 'multipletext',
										name: 'address_previous',
										title: 'Previous address',
										requiredIf: '{address_history_yn} = false',
										items: [
											{
												name: 'street_address',
												isRequired: true,
												placeholder: 'Flat 12, Churchill Lodge',
												title: 'Street address *',
											},
											{
												name: 'postcode',
												isRequired: true,
												placeholder: 'HP5 1AQ',
												title: 'Postcode *',
											},
											{
												name: 'town_city',
												isRequired: true,
												placeholder: 'Chesham',
												title: 'Town/City *',
											},
											{
												name: 'county_province',
												isRequired: true,
												placeholder: 'Buckinghamshire',
												title: 'County/Province *',
											},
											{
												name: 'state_region',
												isRequired: true,
												placeholder: 'England',
												title: 'State/Region *',
											},
											{
												name: 'country',
												isRequired: true,
												placeholder: 'United Kingdom',
												title: 'Country *',
											},
										],
									},
								],
								noEntriesText: ' ',
								panelAddText: 'Add address',
							},
							...(isGiftor
								? []
								: [
										{
											type: 'boolean',
											name: 'address_unchanged_yn',
											title:
												'After you complete this transaction, will your correspondence address stay the same as the above? *',
											hideNumber: true,
											isRequired: true,
										},
										{
											type: 'multipletext',
											name: 'address_post_completion',
											visibleIf: '{address_unchanged_yn} = false',
											title: 'Correspondence address (Post-completion) *',
											hideNumber: true,
											isRequired: true,
											requiredIf: '{address_unchanged_yn} = false',
											items: [
												{
													name: 'street_address',
													isRequired: true,
													placeholder: 'Flat 12, Churchill Lodge',
													title: 'Street address *',
												},
												{
													name: 'postcode',
													isRequired: true,
													placeholder: 'HP5 1AQ',
													title: 'Postcode *',
												},
												{
													name: 'town_city',
													isRequired: true,
													placeholder: 'Chesham',
													title: 'Town/City *',
												},
												{
													name: 'county_province',
													isRequired: true,
													placeholder: 'Buckinghamshire',
													title: 'County/Province *',
												},
												{
													name: 'state_region',
													isRequired: true,
													placeholder: 'England',
													title: 'State/Region *',
												},
												{
													name: 'country',
													isRequired: true,
													placeholder: 'United Kingdom',
													title: 'Country *',
												},
											],
										},
								  ]),
						],
					},
			  ]
			: []),
	],
	showTitle: false,
	showCompletedPage: false,
	completeText: 'Submit',
});
