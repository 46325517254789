import type { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import type { IRHFDatePicker } from './types';

// ----------------------------------------------------------------------

const RHFDatePicker: FC<IRHFDatePicker> = ({ name, pickerProps, ...other }) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<DatePicker
					{...field}
					inputFormat='dd/MM/yyyy'
					renderInput={(props) => (
						<TextField
							fullWidth
							value={field.value}
							{...props}
							{...other}
							helperText={error?.message}
							error={!!error}
						/>
					)}
					{...pickerProps}
				/>
			)}
		/>
	);
};

export default RHFDatePicker;
