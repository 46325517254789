import moment from 'moment';
import { Link } from 'react-router-dom';
import { TransactionSummaryByColumns } from 'src/pages/dashboard/types/summary';
import { ITransactionSummary } from 'src/redux/types';
import { PATH_TRANSACTIONS } from 'src/routes/paths';
import { convertTrackerStepNameToLabel } from 'src/utils/tracker';
import { formatDate, formatDaysDuration, formatPercent, formatPrice, formatUsers } from 'src/utils/transaction/summary';

export const convertSummaryToObjectByColumns = (summary: ITransactionSummary): TransactionSummaryByColumns => {
	const { general, sellSide, buySide, timeFromInstructionToSaleAgreed, timeFromSaleAgreedToExchange, duration } =
		summary;

	const now = moment();
	const latestActionDateDaysDiff = general.latestActionDate ? moment(now).diff(general.latestActionDate, 'days') : null;

	const transactionStatus =
		latestActionDateDaysDiff === null
			? '⚪️'
			: latestActionDateDaysDiff > 20
			? '🔴'
			: latestActionDateDaysDiff <= 10
			? '🟢'
			: '🟡';

	return {
		isPinned: summary.isPinned,
		transactionId: summary.transactionId,
		name: {
			value: general.displayName,
			label: (
				<span>
					{transactionStatus} <Link to={PATH_TRANSACTIONS.view(summary.transactionId)}>{general.displayName}</Link>
				</span>
			),
		},
		postcode: {
			value: general.postcode,
			label: general.postcode,
		},
		uprn: {
			value: general.uprn,
			label: general.uprn,
		},
		trackerStatus: {
			value: general.trackerStep,
			label: convertTrackerStepNameToLabel(general.trackerStep),
		},
		timeFromInstructionToSaleAgreed: {
			value: timeFromInstructionToSaleAgreed,
			label: formatDaysDuration(timeFromInstructionToSaleAgreed),
		},
		timeFromSaleAgreedToExchange: {
			value: timeFromSaleAgreedToExchange,
			label: formatDaysDuration(timeFromSaleAgreedToExchange),
		},
		duration: {
			value: duration,
			label: formatDaysDuration(duration),
		},
		instructionDate: {
			value: general.instructionDate,
			label: formatDate(general.instructionDate),
		},
		saleAgreedDate: {
			value: general.offerAcceptedDate ?? general.saleAgreedDate,
			label: formatDate(general.offerAcceptedDate ?? general.saleAgreedDate),
		},
		buyerDueDiligenceDate: {
			value: general.buyerDueDiligenceDate,
			label: formatDate(general.buyerDueDiligenceDate),
		},
		exchangeDate: {
			value: general.exchangeDate,
			label: formatDate(general.exchangeDate),
		},
		completionDate: {
			value: general.completionDate,
			label: formatDate(general.completionDate),
		},
		askPrice: {
			value: general.askOffer?.price,
			label: formatPrice(general.askOffer?.price),
		},
		ourMargin: {
			value: general.margin,
			label: formatPrice(general.margin),
		},
		sellers: {
			value: sellSide.sellers,
			label: formatUsers(sellSide.sellers),
		},
		sellerQuote: {
			value: sellSide.quote?.withVat,
			label: formatPrice(sellSide.quote?.withVat),
		},
		sellSideAgents: {
			value: sellSide.agents,
			label: formatUsers(sellSide.agents),
		},
		sellSideAgencyRate: {
			value: sellSide.agencyRate?.withVat,
			label: formatPercent(sellSide.agencyRate?.withVat),
		},
		sellSideAgencyFee: {
			value: sellSide.agencyFee?.withVat,
			label: formatPrice(sellSide.agencyFee?.withVat),
		},
		sellSideReferralFee: {
			value: sellSide.referralFee?.withVat,
			label: formatPrice(sellSide.referralFee?.withVat),
		},
		sellSideSolicitors: {
			value: sellSide.solicitors,
			label: formatUsers(sellSide.solicitors),
		},
		sellSideLegalFee: {
			value: sellSide.legalFee?.withVat,
			label: formatPrice(sellSide.legalFee?.withVat),
		},
		buyers: {
			value: buySide.buyers,
			label: formatUsers(buySide.buyers),
		},
		buyerQuote: {
			value: buySide.quote?.withVat,
			label: formatPrice(buySide.quote?.withVat),
		},
		buySideAgents: {
			value: buySide.agents,
			label: formatUsers(buySide.agents),
		},
		buySideAgencyRate: {
			value: buySide.agencyRate?.withVat,
			label: formatPercent(buySide.agencyRate?.withVat),
		},
		buySideAgencyFee: {
			value: buySide.agencyFee?.withVat,
			label: formatPrice(buySide.agencyFee?.withVat),
		},
		buySideReferralFee: {
			value: buySide.referralFee?.withVat,
			label: formatPrice(buySide.referralFee?.withVat),
		},
		buySideSolicitors: {
			value: buySide.solicitors,
			label: formatUsers(buySide.solicitors),
		},
		buySideLegalFee: {
			value: buySide.legalFee?.withVat,
			label: formatPrice(buySide.legalFee?.withVat),
		},
		latestActionDate: {
			value: general.latestActionDate,
			label: formatDate(general.latestActionDate, 'YYYY-MM-DD hh:mm A'),
		},
	};
};
