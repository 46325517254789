import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { SummaryFieldProps } from './types';
import { SummaryFieldWrapper } from './styled';

const SummaryField: FC<SummaryFieldProps> = ({ name, children, withBorder = true, titleSx, ...other }) => (
	<SummaryFieldWrapper display='flex' p='10px' alignItems='center' withBorder={withBorder} {...other}>
		<Typography flex={0.3} variant='h6' sx={titleSx}>
			{name}
		</Typography>
		<Box flex={0.7} fontWeight={'500'} color='grey.800'>
			{children}
		</Box>
	</SummaryFieldWrapper>
);

export default SummaryField;
